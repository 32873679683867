<template>
  <div v-if="applicant">
    <DocumentsUpload
      :current-id="
        !isSplitDocument
          ? applicant.id
          : `${applicant.members[selectedPossessionIndex].id}`
      "
      :disabled="
        applicationIsSent || applicationIsArchived || isLoadingDocument
      "
      :documents="documents"
      :downloading-id="downloadingId"
      :info-title="
        applicationIsSent
          ? 'Under granskning, det går inte att ladda upp nya dokument för tillfället'
          : ''
      "
      doc-identifier="uuid"
      :invalid-documents="invalidDocuments"
      :upload-error="uploadError"
      @addDocument="handleAddDocument"
      @removeDocument="handleRemoveDocument"
      @openFile="handleOpenFile"
      @uploadErrorModalClose="uploadError = false"
    />
    <br />
    <BaseButton
      v-if="showNextBtn"
      :is-loading="false"
      :is-rounded="true"
      type="button"
      :is-disabled="isLoadingDocument"
      :is-fullwidth="isMobile"
      :is-small="isMobile"
      @click="goToNext"
      ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
        Gå vidare
      </BaseIconText>
    </BaseButton>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { deletePrivateFile, storePrivateFile } from '@/services/legacyMedia';
import baFileOpener from '@/utils/baFileOpener';
import svgs from '@/utils/icon-constants';

import DocumentsUpload from '@/components/DocumentsUpload.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';

export default {
  name: 'SwapDocumentUpload',
  components: { DocumentsUpload, BaseIconText, BaseButton },
  props: {
    type: {
      type: String,
      required: true
    },
    invalidDocuments: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      downloadingId: null,
      uploadError: false,
      svgs
    };
  },
  computed: {
    ...mapState({
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex,
      application: state => state.application.application,
      isLoadingDocument: state => state.application.isLoadingDocument
    }),
    ...mapGetters({
      applicant: 'application/applicant',
      applicationIsSent: 'application/applicationIsSent',
      applicationIsArchived: 'application/applicationIsArchived',
      nextValidApplicantIndex: 'application/nextValidApplicantIndex',
      useSplitDocumentUpload: 'application/useSplitDocumentUpload',
      isMobile: 'app/isMobile'
    }),
    isSplitDocument() {
      return this.useSplitDocumentUpload(this.type);
    },
    documents() {
      if (!this.isSplitDocument) {
        // Old document upload
        return this.applicant?.documentGroups[0][this.type]?.documents || [];
      }
      // Show new split document upload
      return (
        this.applicant?.documentGroups[this.selectedPossessionIndex][this.type]
          ?.documents || []
      );
    },
    showNextBtn() {
      if (this.type !== 'OTHER') {
        return !this.applicationIsSent;
      }
      if (
        this.isSplitDocument &&
        !this.applicationIsSent &&
        this.nextValidApplicantIndex === 0 &&
        this.applicant.members.length - 1 > this.selectedPossessionIndex
      ) {
        return true;
      }
      return !this.applicationIsSent && this.nextValidApplicantIndex > 0;
    }
  },
  methods: {
    ...mapActions({
      addDocument: 'application/addDocument',
      removeDocument: 'application/removeDocument',
      goToNextSection: 'application/goToNextSection'
    }),
    ...mapMutations({
      setLoadingDocument: 'application/setLoadingDocument'
    }),
    async goToNext() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      await this.goToNextSection({
        router: this.$router,
        listCheck: !this.isSplitDocument
          ? null
          : this.applicant.members.length - 1
      });
    },
    async handleAddDocument(file) {
      this.setLoadingDocument(true);
      const applicantId = this.applicant.id;
      const uploadIndex = !this.isSplitDocument
        ? null
        : this.selectedPossessionIndex;
      const addDocIndex = !this.isSplitDocument
        ? 0
        : this.selectedPossessionIndex;
      const newDocument = await storePrivateFile(
        applicantId,
        file,
        this.type,
        uploadIndex
      );
      if (newDocument) {
        await this.addDocument({
          ...newDocument,
          applicantId,
          memberIndex: addDocIndex
        });
      } else {
        this.uploadError = true;
      }
      this.setLoadingDocument(false);
    },
    async handleRemoveDocument(identifier) {
      this.setLoadingDocument(true);
      const applicantId = this.applicant.id;
      await deletePrivateFile(applicantId, identifier);
      await this.removeDocument({
        type: this.type,
        uuid: identifier,
        applicantId
      });
      this.setLoadingDocument(false);
    },
    async handleOpenFile(identifier) {
      const applicantId = this.applicant.id;
      const isMobile = this.isMobile;
      this.downloadingId = identifier;

      await baFileOpener(applicantId, identifier, isMobile);

      this.downloadingId = null;
    }
  }
};
</script>

<style></style>
