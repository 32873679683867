<template>
  <SwapDefaultPage
    :names="memberNames"
    :possessions="apartmentsList"
    v-on="$listeners"
    @possessionClick="setSelectedPossessionIndex"
  >
    <template slot="page-possession-addition">
      <InfoButtonModal v-if="apartmentsList.length > 1" title="Vad är detta?"
        ><p>
          Gäller det två lägenheter behöver du även ange information om den
          andra lägenheten.
        </p></InfoButtonModal
      ></template
    >
    <RejectionMessageCard category="APARTMENTS" />
    <CollapseCard is-primary initially-closed class="mb-4">
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <p>
          <strong>OBS:</strong> För att bytet ska kunna genomföras så smidigt
          som möjligt är det viktigt att uppgifterna om Hyresvärden stämmer. Det
          är ert ansvar att uppgifterna är korrekta för att ansökan ska nå fram
          ordentligt. Ta därför noggrant reda på vilken adress som ansökan ska
          skickas till, d.v.s Hyresvärdens adress.
        </p>
        <br />
        <p>Lika viktigt är att hyresvärdens e-postadress stämmer.</p>
        <h3>
          Observera att din ansökan skickas till denna epost. Är du osäker på om
          du har rätt adress, se till att kontakta din hyresvärd innan du fyller
          i detta fält.
        </h3>
      </template>
    </CollapseCard>
    <transition name="form" mode="out-in">
      <SwapForm
        :key="activeApartment.id"
        :is-loading="savingApplicationData"
        :form-has-changed="formHasChanged"
        :form-type="formType"
        v-on="$listeners"
      >
        <SwapInput
          v-model="activeApartment.address"
          :disabled="savingApplicationData"
          label="Adress"
          placeholder="Adress"
          description="Ange lägenhetens adress"
          autocomplete="street-address"
        />
        <Fieldset>
          <PostalCodeSearchBar
            v-model="activeApartment.postalCode"
            :class="{ autocomplete: postalCodeCompleted }"
            label="Postnummer"
            placeholder="Postnummer"
            description="Ange lägenhetens postnummer"
            :validation="validatePostalCode"
            autocomplete="postal-code"
            error-text="Postnummer måste anges med endast 5 siffror"
            :disabled="savingApplicationData"
            @input="postalCodeCompleted = false"
            @dropdownMatch="postalCodeDropdownMatch"
          />
          <SwapInput
            v-model="activeApartment.postalAddress"
            :disabled="savingApplicationData"
            :class="{ autocomplete: postalCodeCompleted }"
            label="Ort"
            placeholder="Ort"
            description="Ange vilken ort lägenheten ligger i"
            autocomplete="address-level2"
            @input="postalCodeCompleted = false"
          />
        </Fieldset>
        <Fieldset>
          <SwapInput
            v-model="activeApartment.objectNumber"
            :disabled="savingApplicationData"
            label="Objektnummer/kontraktsnummer"
            placeholder="Objektnummer/kontraktsnummer"
            description="Ange lägenhetens objektnummer/kontraktsnummer"
          >
            <InfoButtonModal title="Objektnummer/kontraktsnummer">
              <p>
                Objektsnumret är det nummer som din lägenhet har i hyresvärdens
                system. Det består ofta av åtta siffror xxxx-xxxx, där de första
                fyra siffrorna är relaterade till den fastighet du bor i och de
                fyra sista visar vilken lägenhet det är.
              </p>

              Objektsnumret hittar du oftast på:
              <ul>
                <li>Utsidan av din lägenhetsdörr</li>
                <li>Namntavlan i ditt trapphus</li>
                <li>Hyreskontraktet eller på hyresräkningen</li>
              </ul>
            </InfoButtonModal>
          </SwapInput>
          <SwapInput
            v-model="activeApartment.apartmentNumber"
            :disabled="savingApplicationData"
            label="Lägenhetsnummer"
            placeholder="Lägenhetsnummer"
            description="Ange lägenhetsnumret"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          >
            <InfoButtonModal title="Lägenhetsnummer"
              ><p>
                Lägenhetsnumret är en del av din adress och består av fyra
                siffror som visar på vilken våning och var på våningen
                lägenheten finns. Du kan hitta lägenhetsnumret i husets entré,
                på din ytterdörr, på ditt postfack eller i ditt kontrakt.
              </p></InfoButtonModal
            >
          </SwapInput>
          <SwapInput
            v-model="activeApartment.rooms"
            :disabled="savingApplicationData"
            label="Antal rum"
            placeholder="Antal rum"
            description="Ange antal rum lägenheten har"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          />
          <SwapInput
            v-model="activeApartment.sqm"
            :disabled="savingApplicationData"
            placeholder="Yta"
            label="Yta"
            description="Ange hur stor lägenheten är i kvadratmeter"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </Fieldset>
        <Fieldset>
          <SwapInput
            v-model="activeApartment.rent"
            :disabled="savingApplicationData"
            label="Hyra"
            placeholder="Hyra"
            description="Ange lägenhetens hyra"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          />
          <SwapInput
            v-model="contractStartDate"
            :disabled="savingApplicationData"
            label="Innehaft sedan"
            placeholder="Innehaft sedan"
            :description="`Ange datumet ${formName('du')} tog över lägenheten`"
            error-text="Om du bott i lägenheten i mindre än ett år finns risken att någon av hyresvärdarna nekar detta byte."
            type="date"
            :max="getCurrentDate()"
            :is-warning="true"
            :validation="validateContractStartDate"
          />
        </Fieldset>
        <Fieldset>
          <SwapInput
            v-model="activeApartment.adultCount"
            :disabled="savingApplicationData"
            label="Antal vuxna"
            placeholder="Antal vuxna"
            description="Ange hur många vuxna som bor i lägenheten"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          />
          <SwapInput
            v-model="activeApartment.childCount"
            :disabled="savingApplicationData"
            label="Antal barn"
            placeholder="Antal barn under 18 år"
            description="Ange hur många barn under 18 år som bor i lägenheten"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        </Fieldset>
        <LandlordSearchBar
          v-model="dynamicLandlordName"
          :disabled="addNewLandlord || savingApplicationData"
          class="search-landlord-input"
          :class="{ autocomplete: landlordAutoCompleted }"
          label="Nuvarande hyresvärd"
          placeholder="Sök bland hyresvärdar"
          :landlord="landlord"
          :description="landlordDescription"
          autocomplete="off"
          :show-green-checkmark="landlordAutoCompleted || !addNewLandlord"
          @dropdownMatch="landlordDropdownMatch"
          @dropdownNoMatch="clearLandlord"
        />
        <button
          class="add-landlord-btn"
          type="button"
          @click="addNewLandlordClick"
        >
          <div>
            <BaseIconText
              v-if="!addNewLandlord"
              :icon-url="svgs.ICONS.ADD_CIRCLE_BLUE"
            >
              Hittar du inte din hyresvärd? Klicka här för att lägga till
            </BaseIconText>
            <BaseIconText v-else :icon-url="svgs.ICONS.REMOVE_CIRCLE_BLUE">
              Stäng och gå till sök
            </BaseIconText>
          </div>
        </button>
        <Fieldset>
          <SwapInput
            v-if="showLandlordPhoneEmail"
            v-model="activeApartment.landlordPhone"
            :disabled="savingApplicationData"
            label="Hyresvärdens telefonnummer"
            placeholder="Hyresvärdens telefonnummer"
            error-text="Fältet får endast innehålla siffror"
            :validation="validateNumber"
            inputmode="numeric"
            pattern="[0-9]*"
            :class="{ autocomplete: landlordAutoCompleted }"
          />
          <SwapInput
            v-if="showLandlordPhoneEmail"
            v-model="activeApartment.landlordEmail"
            :disabled="savingApplicationData"
            label="Hyresvärdens e-postadress"
            placeholder="Hyresvärdens e-postadress"
            :validation="validEmail"
            error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
            :class="{ autocomplete: landlordAutoCompleted }"
          />
        </Fieldset>

        <transition-group v-if="addNewLandlord" appear name="fade">
          <hr v-if="addNewLandlord" key="hr" />

          <h3 v-if="addNewLandlord" key="title" class="add-landlord-title">
            Lägg till hyresvärd
          </h3>
          <SwapInput
            v-if="addNewLandlord"
            key="landlordNameInput"
            v-model="activeApartment.landlordName"
            :disabled="savingApplicationData"
            label="Nuvarande hyresvärd"
            placeholder="Hyresvärdens namn"
            description="Ange den hyresvärd som äger lägenheten du bor i idag."
            autocomplete="off"
            @blur="showLandlordInfoModal"
          />
          <Fieldset key="fieldset">
            <SwapInput
              v-if="addNewLandlord"
              v-model="activeApartment.landlordPhone"
              :disabled="savingApplicationData"
              label="Hyresvärdens telefonnummer"
              placeholder="Hyresvärdens telefonnummer"
              description="Ange hyresvärdens telefonnummer"
              error-text="Fältet får endast innehålla siffror"
              :validation="validateNumber"
              inputmode="numeric"
              pattern="[0-9]*"
            />
            <div ref="landlordEmailInputContainer">
              <SwapInput
                v-if="addNewLandlord"
                v-model="activeApartment.landlordEmail"
                :disabled="savingApplicationData"
                label="Hyresvärdens e-postadress"
                placeholder="Hyresvärdens e-postadress"
                description="Ange hyresvärdens e-post för bytesansökningar. Om du är osäker på rätt e-postadress, hör av dig till din hyresvärd."
                :validation="validEmail"
                error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              />
            </div>
          </Fieldset>
        </transition-group>
      </SwapForm>
    </transition>
    <InfoModal
      v-if="showSkandiaInfoModal"
      title="Ansökan om lägenhetsbyte (bostad)"
      :disable-outside-click="true"
      :show-close-btn="false"
      confirm-label="Jag förstår"
      :is-position-left="true"
      @click="showSkandiaInfoModal = false"
    >
      <div class="info-modal-content" v-html="skandiaInfoModalContent"></div>
    </InfoModal>
    <InfoModal
      v-if="showSendToLandlordInfoModal"
      :title="sendToLandlordModalTitle"
      :disable-outside-click="true"
      :show-close-btn="false"
      confirm-label="Jag förstår"
      @click="showSendToLandlordInfoModal = false"
    >
      <div class="info-modal-content" v-html="infoModalContent"></div>
    </InfoModal>
  </SwapDefaultPage>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import {
  validatePostalCode,
  validEmail,
  validateNumber,
  validateContractStartDate
} from '@/utils/input-validations';
import { alphabet, getCurrentDate } from '@/utils/common';
import { genitiveFromApplicant } from '@/utils/genitive';
import svgs from '@/utils/icon-constants';

import Fieldset from '@/components/Fieldset.vue';
import InfoButtonModal from '@/components/InfoButtonModal.vue';
import applicationService from '@/services/applicationService';
import CollapseCard from '@/components/CollapseCard.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import SwapForm from '@/components/SwapForm.vue';
import SwapInput from '@/components/SwapInput.vue';
import InfoModal from '@/components/InfoModal.vue';
import LandlordSearchBar from '@/components/LandlordSearchBar.vue';
import PostalCodeSearchBar from '@/components/PostalCodeSearchBar.vue';
import SwapDefaultPage from '@/components/SwapDefaultPage.vue';
import RejectionMessageCard from '@/components/RejectionMessageCard.vue';

export default {
  name: 'Apartments',
  components: {
    Fieldset,
    InfoButtonModal,
    CollapseCard,
    BaseIconText,
    SwapForm,
    SwapInput,
    InfoModal,
    LandlordSearchBar,
    PostalCodeSearchBar,
    SwapDefaultPage,
    RejectionMessageCard
  },
  props: {
    activeApplicant: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    },
    applicants: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      landlordAutoCompleted: false,
      postalCodeCompleted: false,
      isSearchingLandlord: false,
      skipLandLordSearch: false,
      showSendToLandlordInfoModal: false,
      showSkandiaInfoModal: false,
      addNewLandlord: false,
      svgs,
      validateContractStartDate,
      validateNumber,
      validEmail,
      validatePostalCode,
      getCurrentDate,
      formType: 'APARTMENTS'
    };
  },
  computed: {
    ...mapGetters({
      isLbMode: 'application/isLbMode',
      skandiaLandlordIds: 'application/skandiaLandlordIds'
    }),
    ...mapState({
      application: state => state.application.application,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex,
      landlord: state => state.app.landlord
    }),
    showLandlordPhoneEmail() {
      if (this.currentLandlordConnected) {
        return false;
      }
      return this.landlordAutoCompleted && !this.addNewLandlord;
    },
    dynamicLandlordName: {
      get() {
        if (this.addNewLandlord) {
          return '';
        }
        return this.activeApartment.landlordName;
      },
      set(value) {
        if (!this.addNewLandlord) {
          this.activeApartment.landlordName = value;
        }
      }
    },
    currentLandlordConnected() {
      return this.activeApartment.landlordId > 0;
    },
    memberNames() {
      if (!this.activeApplicant) {
        return '';
      }

      return this.activeApplicant.members
        .map((m, index) => m.name || `PERSON ${index + 1}`)
        .join(' & ');
    },
    activeApplicantApartments() {
      if (!this.activeApplicant) {
        return [];
      }
      return this.activeApplicant.apartments;
    },
    activeApartment() {
      if (!this.activeApplicant) {
        return null;
      }
      return this.activeApplicantApartments[this.selectedPossessionIndex];
    },
    apartmentsList() {
      if (!this.activeApplicantApartments) {
        return [];
      }

      return this.activeApplicantApartments.map((member, index) => {
        let numerator =
          this.activeApplicantApartments?.length < 2 ? '' : index + 1;

        return {
          title: `Lägenhet ${
            alphabet[this.selectedApplicantIndex]
          }${numerator}`,
          active: this.selectedPossessionIndex === index,
          tabLink: index
        };
      });
    },
    contractStartDate: {
      get() {
        return this.activeApartment.contractStartDate;
      },
      set(value) {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (value.match(regEx)) {
          this.activeApartment.contractStartDate = new Date(value)
            .toISOString()
            .split('T')[0];
        }
      }
    },
    landlordDescription() {
      return `Välj den hyresvärd som äger lägenheten du bor i idag. </br> <b>OBS!</b> När du väljer en hyresvärd godkänner du också att det är dit vi kommer att skicka in ansökan.`;
    },
    sendToLandlordModalTitle() {
      return `Denna ansökan kommer automatiskt att skickas in till ${this.activeApartment.landlordName}`;
    },
    infoModalContent() {
      if (this.selectedApplicantIndex === 0) {
        return 'Detta innebär att <b>du</b> själv inte behöver fylla i någon extra ansökan hos dem. <b>OBS!</b> När du väljer en hyresvärd godkänner du att det är dit vi kommer att skicka in ansökan och att den hyresvärd du valt kommer att kunna ta del av den information du angivit i ansökan.';
      } else {
        return `Detta innebär att <b>${this.memberNames}</b> själv inte behöver fylla i någon extra ansökan hos dem. <b>OBS!</b> När du väljer en hyresvärd godkänner du att det är dit vi kommer att skicka in ansökan och att den hyresvärd du valt kommer att kunna ta del av den information du angivit i ansökan.`;
      }
    },
    isSkandia() {
      if (!this.applicants.length) {
        return '';
      }
      return this.applicants.some(applicant =>
        applicant.apartments.some(apartment =>
          this.skandiaLandlordIds.includes(apartment.landlordId)
        )
      );
    },
    skandiaInfoModalContent() {
      return `
        <h3><i><u>Genom sin underskrift av denna ansökan bekräftar sökanden att man har tagit del av följande information:</i></u></h3>
        </br>
        <b>Vad säger hyreslagen om att byta bostad?</b>
        </br>
        En hyresgäst får enligt 12 kap 35 § Jordabalken (”hyreslagen”) överlåta sin hyresrätt för att genom byte
        erhålla annan stadigvarande bostad.
        </br>
        Lagtexten säger bland annat följande:
        </br>
        <i>Hyresgästen får överlåta hyresrätten till sin bostadslägenhet för att genom byte få en annan bostad, om hyresnämnden
        lämnar tillstånd till överlåtelsen. Tillstånd ska lämnas om hyresgästen har beaktansvärda skäl för bytet och att detta
        kan äga rum utan påtaglig olägenhet för hyresvärden, samt inte heller andra skäl talar emot bytet. Tillståndet kan
        förenas med villkor.</i>
        </br>
        </br>
        <b>Vi ställer samma krav på den du vill byta med som på alla andra som vill bli hyresgäst hos oss:</b>
        <ul>
          <li>Vi accepterar inga betalningsanmärkningar.</li>
          <li>Hushållets sammanlagda bruttoinkomst ska vara minst tre-fyra gånger hyresbeloppet beroende på fastighet / ort.</li>
          <li>Den sökande ska kunna visa upp goda referenser från tidigare hyresvärd.</li>
          <li>Den sökande får inte ha någon annan bostad, om sådan finns ska detta boende avslutas.</li>
          <li>Den sökande måste ha fyllt 18 år.</li>
        </ul>
        <b>Handläggningstid</b>
        </br>
        Handläggningstiden - efter fullständigt ifylld ansökan med samtliga handlingar och uppgifter som vi önskar
        för bytets genomförande - är minst åtta veckor. Vi påbörjar inte handläggningen innan er ansökan är
        komplett - var därför mycket noga med att få med alla de handlingar som ska biläggas din ansökan.
        </br>
        </br>
        <b>Besiktning</b>
        </br>
        Innan bytet kan genomföras ska din lägenhet besiktigas. Eventuella skador, installationer eller ändringar
        orsakade eller genomförda av dig, kan inte övertas av inflyttande hyresgäst. Vid skador debiterar vi dig för
        att återställa lägenheten. Återställer du lägenheten själv ska detta arbete utföras och sedan godkännas av
        oss innan bytet kan genomföras.
        </br>
        </br>
        <b>Vårt beslut</b>
        </br>
        När bytet är godkänt av samtliga hyresvärdar bokas tid för undertecknande av hyreskontrakt. Det krävs att
        samtliga parter i byteskedjan undertecknar skriftligt hyreskontrakt innan flyttning kan ske. Som avflyttande
        hyresgäst ska du underteckna en uppsägning av ditt hyresavtal i samband med att ny hyresgäst tecknar
        nytt hyresavtal för din gamla bolastad.
        Om vi avslår din ansökan får du ett skriftligt besked om detta. Om du tycker vårt beslut är felaktigt kan du
        vända dig till Hyresnämnden och låta dem pröva din ansökan. Hyresnämndens beslut går inte att
        överklaga.
        </br>
        </br>
        <b>Integritetspolicy</b>
        </br>
        <a href="https://www.skandiafastigheter.se/globalassets/om-oss/personuppgifter/behandling-av-personuppgifter-vid-bostadsuthyrning.docx.pdf" target="_blank">Länk till Skandia Fastigheters integritetspolicy</a>
        </br>
        </br>
        <b>Ansluten hyresvärd</b>
        </br>
        Denna ansökan kommer automatiskt att skickas in till ${this.activeApartment.landlordName}. ${this.infoModalContent}
        `;
    }
  },
  watch: {
    activeApartment: {
      async handler(nextApartment, previousApartment) {
        if (nextApartment !== previousApartment) {
          this.landlordAutoCompleted = false;
          this.postalCodeCompleted = false;
          this.showSendToLandlordInfoModal = false;

          await this.preFillLandlordInfo();

          if (
            !this.currentLandlordConnected &&
            this.activeApartment?.landlordName
          ) {
            this.addNewLandlord = true;
          } else {
            this.addNewLandlord = false;
          }
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions({
      fetchLandlord: 'app/fetchLandlord'
    }),
    ...mapMutations({
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    clearLandlord() {
      this.activeApartment.landlordName = '';
      this.activeApartment.landlordPhone = '';
      this.activeApartment.landlordEmail = '';
      this.activeApartment.landlordId = -1;
      this.landlordAutoCompleted = false;
      this.$emit('isEditing', this.formType);
    },
    landlordDropdownMatch(match) {
      this.$emit('isEditing', this.formType);
      this.activeApartment.landlordName = match.name;
      this.activeApartment.landlordEmail = match.email;
      this.activeApartment.landlordPhone = match.phone.replace(/-|\s/g, '');
      this.activeApartment.landlordId = match.landlordId;

      this.landlordAutoCompleted = true;
      this.addNewLandlord = false;
      this.showLandlordInfoModal();
    },
    formName(defaultWord) {
      return this.selectedApplicantIndex === 0
        ? defaultWord
        : genitiveFromApplicant(this.activeApplicant);
    },
    showLandlordInfoModal() {
      if (this.activeApartment.landlordName.length < 2) {
        return;
      }
      const RELEASE_INFO_MODAL_TIMESTAMP = new Date(1688562000000);

      const createdDate = new Date(this.application.createdAt);

      if (this.isLbMode || createdDate < RELEASE_INFO_MODAL_TIMESTAMP) {
        this.showSendToLandlordInfoModal = false;
        return;
      }

      if (this.isSkandia) {
        this.showSkandiaInfoModal = true;
      } else if (this.currentLandlordConnected) {
        this.showSendToLandlordInfoModal = true;
      }
    },
    postalCodeDropdownMatch(match) {
      this.$emit('isEditing', this.formType);
      this.activeApartment.postalCode = match.name;
      this.activeApartment.postalAddress = match.description;
      this.postalCodeCompleted = true;
    },
    async preFillLandlordInfo() {
      if (!this.activeApartment) {
        return;
      }
      const landlord = await this.fetchLandlord();
      const isFirstApplicant =
        this.selectedApplicantIndex === 0 && this.selectedPossessionIndex === 0;

      if (
        !isFirstApplicant ||
        this.activeApartment.landlordName ||
        landlord?.isParentLandlord ||
        !landlord
      ) {
        return;
      }

      try {
        const landlordResponse = await applicationService.getLandlordFill(
          landlord.landlordId
        );

        if (landlordResponse.error || !landlordResponse) {
          return;
        }

        this.activeApartment.landlordId = landlordResponse.landlordId;
        this.activeApartment.landlordName = landlordResponse.name;
        this.activeApartment.landlordEmail = landlordResponse.email;
        this.activeApartment.landlordPhone = landlordResponse.phone.replace(
          /-|\s/g,
          ''
        );
        this.landlordAutoCompleted = true;
      } catch (err) {
        console.error(err);
      }
    },
    addNewLandlordClick() {
      this.$emit('isEditing', this.formType);
      this.addNewLandlord = !this.addNewLandlord;

      this.activeApartment.landlordName = '';
      this.activeApartment.landlordPhone = '';
      this.activeApartment.landlordEmail = '';

      if (this.addNewLandlord) {
        this.landlordAutoCompleted = false;
        this.activeApartment.landlordId = -1;
        this.$nextTick(() => {
          const container = this.$refs.landlordEmailInputContainer;
          if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete :deep(input) {
  background-color: #e8f0fe;
}

.search-landlord-input {
  margin-bottom: 10px;
}
.add-landlord-btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--color-blue);
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  padding: 0;
  @include mobile {
    text-align: left;
  }
  :deep(img.icon) {
    margin-right: 5px;
  }
}
hr {
  width: 100%;
  height: 1px;
  background-color: var(--color-light-gray);
  border: none;
  margin: 16px 0 40px 0;
}
.add-landlord-title {
  margin-bottom: 40px;
}
</style>
