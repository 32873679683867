<template>
  <div>
    <div v-if="!emailModalVisible">
      <BaseBankid
        :collect-path="'/portal/cognito/bankid/login'"
        :auth-path="'/bankid/start'"
        :qr-path="'/bankid/qr'"
        :cancel-path="'/bankid/cancel'"
        :base-url="baseUrl"
        @success="login($event)"
      >
        <template slot="header"><slot name="header" /></template>
      </BaseBankid>
      <section class="login-lb-wrapper">
        <slot name="footer" />
      </section>
    </div>

    <DefaultModal v-if="emailModalVisible && !registeredEmail">
      <template slot="header">Registrera konto</template>
      <div slot="body" class="register-wrapper">
        <TermsModal v-if="termsModalVisible" @click="handleTermsModalClick" />
        <p>Hej och välkommen till Bytesansökan!</p>
        <p v-if="!inviteCode">
          För att komma igång, ange din e-postadress nedan samt läs igenom och
          godkänn våra villkor.
        </p>
        <p v-else>
          För att komma igång, läs igenom och godkänn våra villkor. Vi har redan
          fyllt i den e-postadress du blev inbjuden till.
        </p>
        <form class="form-wrapper" @submit.prevent="updateEmail()">
          <Input
            v-model="email"
            :disabled="updatingEmail || !!inviteCode"
            placeholder="E-post"
            type="email"
            autofocus
            :validation="validateEmail"
            error-text=""
            class="mb-0 email"
            @input="errorMessage = null"
          />
          <Input
            v-if="!inviteCode"
            v-model="emailConfirm"
            :disabled="updatingEmail"
            placeholder="Repetera e-post"
            type="email"
            :validation="validateEmailConfirm"
            error-text=""
            class="mb-0 email"
            @input="errorMessage = null"
          />
          <BaseCheckbox
            :checked="readTermsChecked"
            @change="readTermsChecked = !readTermsChecked"
            ><button
              type="button"
              class="accept-terms-btn"
              @click="termsModalVisible = true"
            >
              Jag accepterar Bytesansökans policy
            </button></BaseCheckbox
          >
          <p v-show="errorMessage" class="text-error">{{ errorMessage }}</p>

          <BaseButton
            :is-loading="updatingEmail"
            :is-disabled="registrationBtnEnabled"
            :is-fullwidth="true"
            :is-rounded="true"
            type="submit"
            class="save-button"
            >Registrera</BaseButton
          >
        </form>

        <section
          v-if="errorMessage && emailExists"
          class="login-switch-wrapper mt-20 mb-20"
        >
          <button
            class="simple-button login-switcher"
            @click="$emit('switchLogin')"
          >
            Logga in med lösenord
          </button>
        </section>
      </div>
    </DefaultModal>

    <DefaultModal v-if="registeredEmail && !emailVerified">
      <template slot="header"> Verifiera e-post </template>
      <div slot="body" class="register-wrapper">
        <p v-if="registeredEmail && email">
          Vi behöver verifiera din e-post, därför har vi skickat ett mail till
          {{ email }} med en kod som du kan fylla i nedan.
        </p>

        <p v-if="registeredEmail && !email">
          Vi vet att du redan har ett konto, men vi skulle behöva verifiera din
          e-post. Därför har vi skickat ett mail till dig med en kod som du kan
          fylla i nedan
        </p>

        <form class="form-wrapper">
          <div class="otp-container">
            <div class="otp-input-wrapper">
              <OTP
                class="mb-0 otp-input"
                :digit-count="4"
                :is-disabled="verifyingEmail"
                :is-loading="verifyingEmail"
                @keydown="errorMessage = null"
                @completed="setOtpDigits"
              />
              <p v-show="errorMessage" class="otp-error">{{ errorMessage }}</p>
            </div>
          </div>
        </form>

        <div v-if="showSendNewVerificationButton" class="verify-footer">
          <BaseButton
            :is-rounded="true"
            type="button"
            @click="resendEmailVerification()"
          >
            Skicka ny kod
          </BaseButton>
        </div>
      </div>
    </DefaultModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import auth from '@/services/auth';
import BaseBankid from '@/components/BaseBankid.vue';
import BaseButton from '@/components/BaseButton.vue';
import Input from '@/components/Input.vue';
import DefaultModal from '@/components/DefaultModal.vue';
import apiClient from '@/services/apiClient';
import cognito from '@/services/cognito';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import TermsModal from '@/components/TermsModal.vue';
import OTP from '@/components/OTP.vue';
import { validEmail } from '@/utils/input-validations';
import svgs from '@/utils/icon-constants';

export default {
  components: {
    BaseBankid,
    Input,
    BaseButton,
    DefaultModal,
    BaseCheckbox,
    TermsModal,
    OTP
  },
  props: {
    inviteCode: {
      type: [String, undefined],
      default: undefined
    }
  },
  data() {
    return {
      svgs,
      loggingIn: false,
      errorMessage: false,
      baseUrl: apiClient.apiEndpoint,
      emailModalVisible: false,
      updatingEmail: false,
      verifyingEmail: false,
      registeredEmail: false,
      emailVerified: false,
      email: '',
      emailConfirm: '',
      verificationOtpCode: '',
      responseData: {},
      userUuid: '',
      termsModalVisible: false,
      readTermsChecked: false,
      emailExists: false,
      showSendNewVerificationButton: false
    };
  },

  computed: {
    ...mapState({
      appReady: state => state.app.appReady,
      authenticated: state => state.app.authenticated
    }),
    ...mapGetters({
      isMobile: 'app/isMobile'
    }),
    registrationBtnEnabled() {
      if (
        this.readTermsChecked &&
        this.emailConfirm?.length > 0 &&
        this.email?.length > 0
      ) {
        return false;
      }
      if (this.updatingEmail) {
        return false;
      }
      return true;
    }
  },
  methods: {
    validateEmail(value) {
      return validEmail(value);
    },

    validateEmailConfirm(value) {
      return (
        validEmail(value) &&
        this.email?.toLowerCase().trim() ===
          this.emailConfirm?.toLowerCase().trim()
      );
    },

    async updateEmail() {
      this.updatingEmail = true;
      this.errorMessage = null;
      this.emailExists = false;

      // Fix casing issues on email
      this.email = this.email ? this.email.toLowerCase().trim() : '';
      this.emailConfirm = this.emailConfirm
        ? this.emailConfirm.toLowerCase().trim()
        : '';

      if (!this.email) {
        this.errorMessage = 'Vänligen fyll i e-postadressen';
        this.updatingEmail = false;
        return;
      }

      if (this.email != this.emailConfirm) {
        this.errorMessage = 'E-postadresserna är olika';
        this.updatingEmail = false;
        return;
      }

      try {
        this.responseData = await cognito.updateUserEmail(
          this.userUuid,
          this.email,
          this.inviteCode
        );

        this.registeredEmail = true;
        this.emailVerified = this.responseData.emailVerified;

        if (this.emailVerified) {
          await this.login();
        }
      } catch (error) {
        if (error.response?.status === 409) {
          this.errorMessage =
            'Det gick inte att skapa användaren, användaren finns redan. Prova logga in med lösenord, eller kontakta kundtjänst.';
          this.emailExists = true;
        } else if (error.response?.status === 400) {
          this.errorMessage = 'Adressen är inte giltig.';
        } else {
          this.errorMessage = 'Ett fel har inträffat, vänligen försök igen.';
        }

        this.updatingEmail = false;
      }
    },

    async verifyEmail() {
      this.verifyingEmail = true;

      if (!this.verificationOtpCode || this.verificationOtpCode.length !== 4) {
        this.errorMessage = 'Verifikationskod saknas eller är inte fullständig';
        this.verifyingEmail = false;
        return;
      }

      try {
        const result = await cognito.verifyEmail(
          this.userUuid,
          this.verificationOtpCode
        );

        if (result && this.email) {
          this.responseData.emailVerified = true;
          await this.login();
        } else {
          this.$emit('initiate');
        }
      } catch (error) {
        if (error.response?.status === 406) {
          this.errorMessage = 'Koden är inte giltig, den kan vara för gammal.';
          this.showSendNewVerificationButton = true;
        } else {
          this.errorMessage = 'Ett fel har inträffat, vänligen försök igen.';
        }
        this.verifyingEmail = false;
        this.updatingEmail = false;
      }
    },

    async resendEmailVerification() {
      await cognito.resendVerification(this.userUuid);
      this.errorMessage = null;
      this.showSendNewVerificationButton = false;
    },

    async login(responseData) {
      if (!responseData) {
        responseData = this.responseData;
      }
      this.loggingIn = true;

      if (responseData?.statusCode === 202) {
        this.userUuid = responseData.uuid;
        this.email = responseData.email;
        this.emailConfirm = responseData.email;
        this.emailModalVisible = true;
        this.loggingIn = false;
        this.errorMessage = null;
        return;
      }
      const result = await auth.loginUserBankid(responseData);

      this.registeredEmail = true;
      this.emailVerified = result.emailVerified;

      if (result.success && this.emailVerified) {
        await this.$emit('initiate');
      } else if (result.success) {
        this.resendEmailVerification();
      }

      this.errorMessage = null;
      this.loggingIn = false;
    },

    handleTermsModalClick(eventType) {
      if (eventType === 'APPROVE') {
        this.readTermsChecked = true;
      }
      this.termsModalVisible = false;
    },
    setOtpDigits(digitStr) {
      this.errorMessage = null;
      this.verificationOtpCode = digitStr;
      this.verifyEmail();
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  max-width: 500px;
}

.form-wrapper > *:not(:first-child) {
  margin-top: 10px;
}

.text-error {
  color: var(--color-error);
  margin-top: 10px;
}

.save-button {
  margin-top: 40px !important;
}

.login-switcher {
  font-size: 12px;
  display: flex;
  margin-top: 10px;
  color: var(--color-blue);
  align-items: center;
  gap: 0.5rem;
}

.login-switch-wrapper {
  margin-top: 15px;
  width: 100%;
}

.login-lb-wrapper {
  padding: 2rem 0;
}

.register-wrapper {
  max-width: 450px;

  p {
    margin-bottom: 20px;
  }

  .verify-footer {
    display: flex;
    justify-content: space-evenly;
  }
}

.email {
  :deep(input) {
    text-transform: lowercase;
  }

  :deep(::placeholder) {
    text-transform: none;
  }

  :deep(.valid:after) {
    background: none;
  }
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.accept-terms-btn {
  border: none;
  background: none;
  color: #000;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 0.5rem 0;
}

.otp-container {
  text-align: center;
}

.otp-input {
  justify-content: center;
}

.otp-input-wrapper {
  position: relative;
  padding-bottom: 40px;
}

.otp-error {
  margin: 0;
  position: absolute;
  color: var(--color-error);
  font-size: 1.6rem;
  margin: 3px 0 0;
  text-align: center;
  left: 0;
  right: 0;
}
</style>
