<template>
  <OvDocuments
    title="Ladda upp filer för övertagaren"
    :computed-documents-groups="computedDocumentsGroups"
    category-type="transferee"
  />
</template>

<script>
import { mapState } from 'vuex';
import OvDocuments from '@/components/OvDocuments.vue';

export default {
  components: {
    OvDocuments
  },
  computed: {
    ...mapState({
      application: state => state.ovApplication.application
    }),
    computedDocumentsGroups() {
      return this.application?.inviteeComputedDocumentsGroups || {};
    }
  }
};
</script>

<style></style>
