import Vue from 'vue';
import SwapApp from './SwapApp.vue';
import SubletApp from './SubletApp.vue';
import OvApp from './OvApp.vue';
import createSwapRouter from './router/swapRouter';
import createSubletRouter from './router/subletRouter';
import createOvRouter from './router/ovRouter';
import swapStore from './store/swap';
import subletStore from './store/sublet';
import ovStore from './store/overlatelser';
import * as Sentry from '@sentry/vue';
import '@/assets/styles/index.scss';
import apiClient from './services/apiClient';
import applicationService from './services/applicationService';
import APPLICATION_TYPE from '@/utils/applicationType';

Vue.config.productionTip = false;

Vue.prototype.$apiClient = apiClient;
Vue.prototype.$applicationService = applicationService;

let currentApp = '';

if (process.env.VUE_APP_SUBLET || location.host.includes('andrahand')) {
  currentApp = 'ah';
}

if (process.env.VUE_APP_OV || location.host.includes('overlatelse')) {
  currentApp = 'ov';
}

const checkSensitiveCustomer = async store => {
  try {
    await store.dispatch('app/fetchLandlord');

    const landlord = store.state.app.landlord;

    if (landlord?.hasAltEmailAddress) {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

function initSentry(isSensitiveCustomer, router) {
  if (window.location.hostname !== 'localhost' && !isSensitiveCustomer) {
    Sentry.init({
      Vue,
      dsn: 'https://3e079a61c1b846fa8591f7db278e94ad@o4504678253723648.ingest.sentry.io/4504995352018944',
      environment: process.env.NODE_ENV,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration({
          router
        }),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true
        })
      ],
      ignoreErrors: [
        'Network Error',
        'Request aborted',
        'Request failed with status code 401',
        'Request failed with status code 404',
        'Request failed with status code 403',
        'Request failed with status code 400',
        'Request failed with status code 500'
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production

      tracesSampleRate: 1.0
    });
  }
}

async function initSwapApp() {
  const swapRouter = createSwapRouter();
  const isSensitiveCustomer = await checkSensitiveCustomer(swapStore);
  initSentry(isSensitiveCustomer, swapRouter);

  new Vue({
    router: swapRouter,
    store: swapStore,
    render: h => h(SwapApp)
  }).$mount('#app');
  swapStore.commit('app/setSupportAddress', 'support@bytesansokan.se');
  swapStore.commit('app/setAltSupportAddress', 'kundservice@bytesansokan.se');
  swapStore.commit('tooltips/setTooltips', [
    {
      reference: 'PageHeader-Lägenhet',
      type: ['MOBILE', 'DESKTOP'],
      seen: false,
      conditions: [],
      content: [
        'Här visas vem av bytesparterna du håller på att fylla i uppgifter åt. När du är klar med en sida klickar du på knappen "Spara och gå vidare" som finns under formuläret',
        'Om du fyller i åt någon annan kan du enkelt växla bytespart genom att trycka på namnet.'
      ]
    },
    {
      reference: 'mobileMenu',
      type: ['MOBILE'],
      seen: false,
      conditions: [],
      content: [
        'Här navigerar du mellan de olika delarna i formulären.',
        'Du ser också vilken status varje del har. När cirkeln är grön har vi alla uppgifter vi behöver.'
      ]
    },
    {
      reference: 'APARTMENTS',
      type: ['DESKTOP'],
      seen: false,
      conditions: [],
      content: [
        'Här navigerar du mellan de olika delarna i formulären.',
        'Du ser också vilken status varje del har. När cirkeln är grön har vi alla uppgifter vi behöver.'
      ]
    },
    {
      reference: 'PageHeader-Boenden',
      type: ['DESKTOP', 'MOBILE'],
      seen: false,
      conditions: [
        { property: 'members', childProperty: 'ssn', isFalsy: false },
        {
          property: 'members',
          childProperty: 'phone',
          isFalsy: true
        }
      ],
      content: [
        'Vi har förifyllt vissa fält gällande din folkbokföringsadress, eftersom du loggade in med BankID.',
        'Om något inte skulle stämma är det bara att ändra.'
      ]
    }
  ]);
}

async function initSubletApp() {
  const subletRouter = createSubletRouter();
  const isSensitiveCustomer = await checkSensitiveCustomer(subletStore);
  initSentry(isSensitiveCustomer, subletRouter);

  new Vue({
    router: subletRouter,
    store: subletStore,
    render: h => h(SubletApp)
  }).$mount('#app');
  subletStore.commit('app/setSupportAddress', 'support@andrahand.se');
  subletStore.commit('app/setAltSupportAddress', 'kundservice@andrahand.se');
}

async function initOvApp() {
  const ovRouter = createOvRouter(ovStore);
  const isSensitiveCustomer = await checkSensitiveCustomer(ovStore);
  initSentry(isSensitiveCustomer, ovRouter);

  new Vue({
    router: ovRouter,
    store: ovStore,
    render: h => h(OvApp)
  }).$mount('#app');
  ovStore.commit('app/setSupportAddress', 'support@overlatelser.se');
  ovStore.commit('app/setAltSupportAddress', 'kundservice@overlatelser.se');
}

async function main() {
  switch (currentApp) {
    case 'ah':
      initSubletApp(APPLICATION_TYPE.ANDRAHAND);
      break;
    case 'ov':
      initOvApp(APPLICATION_TYPE.OVERLATELSE_UNSPECIFIED);
      break;
    default:
      initSwapApp();
  }
}

main();
