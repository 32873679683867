<template>
  <InfoModal
    title="Personuppgiftshantering - Bytesansökan"
    class="terms-modal"
    mobile-full-width
    @click="$emit('click')"
  >
    <div class="body">
      <h4><i>Rättslig grund och ändamålen med behandlingen</i></h4>
      <p>
        För att kunna fullgöra avtalet med dig vid användning av tjänsten
        <strong>Bytesansökan</strong> antingen direkt via Lägenhetsbyte.se eller
        indirekt via din hyresvärd behandlar
        <strong>Lägenhetsbyte Sverige AB</strong> dina personuppgifter. Det
        omfattar en behandling av uppgifter såsom namn, personnummer,
        kontaktuppgifter (t ex adress, e-postadress, telefonnummer),
        folkbokföringsadress, civilstånd och uppgifter om familjemedlemmar (t ex
        maka/make, partner eller sambo samt barn i hushållet), uppgifter om din
        inkomst och underlag härför (t ex utbetalning av lön, utbetalning av
        studiemedel eller ersättning från försäkringskassan), uppgifter om din
        arbetsgivare och din anställning, uppgifter om ditt hyresförhållande och
        din hyreshistorik, ditt bytesskäl och uppgifter om din ekonomiska
        situation i övrigt (t ex kreditupplysning) samt betalningsinformation
        och tekniska uppgifter om din utrustning (t ex ip-adress, Device-id,
        webbläsarinställningar, operativsystem, skärmupplösning och plattform).
      </p>
      <p>
        <strong>Lägenhetsbyte Sverige AB</strong> registrerar dig som användare
        av tjänsten <strong>Bytesansökan</strong>, säkerställer din identitet
        och din adress genom personbevis och Bank-id, upprätthåller korrekta och
        uppdaterade uppgifter, administrerar och hanterar din bytesansökan (t ex
        genom att inhämta uppgifter angående din ekonomiska situation från
        kreditupplysningsleverantör, din arbetsgivare och din hyresvärd,
        inhämtar uppgifter om ditt hyresförhållande och din hyreshistorik från
        din hyresvärd, granskar och kvalitetssäkrar de uppgifter du lämnat för
        din bytesansökan, sammanställer inhämtade uppgifter i din bytesansökan
        samt lämnar dem till de hyresvärdar bytesansökan avser), hanterar
        betalning i samband med att du använder tjänsten, hanterar och besvarar
        kommunikation från dig och, kommunicerar med övriga bytesparter.
        <strong>Lägenhetsbyte Sverige AB</strong> förhindrar och utreder även
        missbruk av tjänsten, förhindrar försök till olovlig inloggning eller
        andra åtgärder som är förbjudna i lag eller enligt våra allmänna villkor
        samt förbättrar vår IT-miljö mot angrepp och intrång.
      </p>
      <p>
        Ändamålen med behandlingen är att tillhandahålla och administrera
        tjänsten <strong>Bytesansökan</strong>, administrera och besvara
        kommunikation från dig samt förebygga, utreda och begränsa missbruk av
        <strong>Bytesansökan</strong>. Därutöver kan vi komma att behandla dina
        personuppgifter, såsom ålder, kön, bostadsort, korrespondens och
        feedback avseende tjänsten, köp- och användargenererad data, tekniska
        uppgifter om din utrustning (t ex ip-adress, Device-id,
        webbläsarinställningar, operativsystem, skärmupplösning och plattform)
        och information om hur du har interagerat med oss med stöd av en s.k.
        intresseavvägning.
      </p>
      <p>
        Det innebär att <strong>Lägenhetsbyte Sverige AB</strong> utreder hur
        det går att anpassa tjänsten för att bli mer användarvänlig samt tar
        fram underlag för att förbättra
        <strong>Lägenhetsbyte Sverige AB</strong>s verksamhet och analyserar de
        uppgifter vi samlar in för ändamålet. Ändamålen med behandlingen är att
        utvärdera, utveckla och förbättra vår verksamhet. Detta vägs sedan mot
        dina rättigheter och behandlingen av dina personuppgifter sker endast i
        det fall det föreligger ett berättigat intresse för
        <strong>Lägenhetsbyte Sverige AB</strong> i att förbättra din
        användarupplevelse och att utvärdera, utveckla och förbättra
        verksamheten.
      </p>
      <p>
        I övrigt kan dina personuppgifter, såsom namn, personnummer,
        kontaktuppgifter (t ex adress, e-postadress och telefonnummer),
        uppgifter i bytesansökan, betalningsinformation och korrespondens komma
        att behandlas för att vi ska kunna uppfylla våra rättsliga
        förpliktelser. Det innebär sådan hantering som är nödvändig för att vi
        ska uppfylla våra rättsliga förpliktelser enligt lagkrav, domslut eller
        myndighetsbeslut.
      </p>
      <h4>
        <i
          >Kategorier av mottagare till vilka personuppgifter kan komma att
          lämnas</i
        >
      </h4>
      <p>
        Personuppgifter som behandlas av
        <strong>Lägenhetsbyte Sverige AB</strong> inom ramen för tjänsten
        <strong>Bytesansökan</strong> kommer att överlämnas till de för
        bytesansökan angivna hyresvärdarna. Vidare kan Lägenhetsbyte Sverige AB
        komma att lämna ut dina uppgifter till andra bolag inom samma koncern
        som <strong>Lägenhetsbyte Sverige AB</strong>. Därutöver kan din
        information komma att lämnas ut till av
        <strong>Lägenhetsbyte Sverige AB</strong> anlitad leverantör för
        behandling för vår räkning enligt de ovan angivna ändamålen.
        <strong>Lägenhetsbyte Sverige AB</strong> är ansvarigt för att eventuell
        behandling av dina personuppgifter som sker av sådan leverantör sker
        enligt gällande dataskyddslagstiftning och endast för de ändamål som
        beskrivs i dessa villkor.
      </p>
      <p>
        Till exempel kan <strong>Lägenhetsbyte Sverige AB</strong> komma att
        lämna ut dina uppgifter till kreditupplysningsleverantörer i syfte att
        möjliggöra din bytesansökan och till betalningstjänstleverantörer,
        fakturatjänstleverantörer samt i förekommande fall
        inkassotjänstleverantörer i syfte att hantera betalningar för ditt
        användande av tjänsten. Därutöver kan dina personuppgifter komma att
        lämnas ut till leverantörer av e-signeringsplattformar,
        avtalshanteringssystem, leverantörer och datalagringsleverantörer samt
        till andra partners såsom reklamkunder samt analys- och mätverktyg.
      </p>
      <p>
        <strong>Lägenhetsbyte Sverige AB</strong> kan även komma att dela dina
        personuppgifter med vissa företag som är självständigt
        personuppgiftsansvariga, t ex statliga myndigheter (Polisen,
        Skatteverket eller andra myndigheter) om vi är skyldiga att göra det
        enligt lag eller vid misstanke om brott, och företag som erbjuder
        betalningslösningar (t ex kortinlösande företag, banker och andra
        betalningstjänstleverantörer). När dina personuppgifter delas med ett
        företag som är självständigt personuppgiftsansvarig gäller det
        företagets integritetspolicy och personuppgiftshantering.
      </p>
      <h4>
        <i>Lagring och gallring av personuppgifter</i>
      </h4>
      <p>
        De personuppgifter som
        <strong>Lägenhetsbyte Sverige AB</strong> behandlar när vi utför uppdrag
        inom tjänsten <strong>Bytesansökan</strong> gallras eller anonymiseras
        24 månader efter det att uppdraget slutförts i syfte att kunna hantera
        eventuella reklamationsärenden och vad gäller information insamlad för
        att förebygga, utreda och begränsa missbruk av tjänsten samt för att
        utvärdera, utveckla och förbättra verksamheten inom 24 månader efter
        insamlandet.
        <br />
        Dina personuppgifter kan komma att sparas efter utgången av ovan angivna
        tidsperioder om krav på ytterligare lagring finns i andra lagar som
        t.ex. bokföringslagen.
      </p>
      <h4>
        <i>Rätt till tillgång till personuppgifter, rättelse m.m.</i>
      </h4>
      <p>
        Du har rätt att få information om och en kopia av dina personuppgifter
        som behandlas av <strong>Lägenhetsbyte Sverige AB</strong>. Du har även
        rätt att begära att <strong>Lägenhetsbyte Sverige AB</strong> rättar,
        dina personuppgifter som du anser är felaktiga eller ofullständiga, samt
        blockerar, begränsar eller raderar dina personuppgifter. I de fall vi
        använder en intresseavvägning som laglig grund för ett ändamål har du
        möjlighet att invända mot behandlingen. Du har rätt att begära ut dina
        personuppgifter som behandlas av
        <strong>Lägenhetsbyte Sverige AB</strong> i ett allmänt maskinläsbart
        format. Om det är tekniskt möjligt, vilket avgörs av
        <strong>Lägenhetsbyte Sverige AB</strong>, har du även rätt att få dina
        personuppgifter som behandlas hos
        <strong>Lägenhetsbyte Sverige AB</strong> överförda till en annan
        personuppgiftsansvarig, t ex om du byter till en annan tjänst som liknar
        Lägenhetsbyte.se. För att utöva dina rättigheter enligt ovan eller om du
        har frågor kring vår behandling kan du skriva till oss på följande
        adress: compliance@lagenhetsbyte.se
      </p>
      <h4><i>Rätt att inge klagomål</i></h4>
      <p>
        Om du anser att <strong>Lägenhetsbyte Sverige AB</strong>:s behandling
        av dina personuppgifter strider mot dataskyddslagstiftningen kan du ge
        in ett klagomål till Integritetsskyddsmyndigheten. För mer information
        se Integritetsskyddsmyndighetens hemsida
        <a href="https://www.imy.se/">https://www.imy.se/</a>.
      </p>
      <div class="approve-container">
        <BaseButton
          v-if="showApproveBtn"
          :is-rounded="true"
          type="button"
          @click="$emit('click', 'APPROVE')"
          >Jag godkänner</BaseButton
        >
        <BaseButton
          v-else
          :is-rounded="true"
          :is-default="true"
          type="button"
          @click="$emit('click', 'APPROVE')"
          >Stäng</BaseButton
        >
      </div>
    </div>
  </InfoModal>
</template>
<script>
import InfoModal from '@/components/InfoModal.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    InfoModal,
    BaseButton
  },
  props: {
    showApproveBtn: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped lang="scss">
h4 {
  font-size: 1.6rem;
  font-family: 'Poppins';
  color: var(--color-dark);
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 0.5rem;
}

p {
  font-size: 1.6rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  font-weight: 400;
  color: var(--color-dark);
  max-width: none;
}

.terms-modal {
  :deep(.info-modal) {
    max-width: min(86rem, 94vw);
    @include mobile {
      max-width: none;
    }
  }
}

.approve-container {
  text-align: center;
  button {
    font-size: 1.8rem;
    width: 400px;
    max-width: 100%;
  }
}

.body {
  max-width: 600px;
}
</style>
