<template>
  <div>
    <InfoModal
      v-if="errorModalVisible"
      title="Något gick fel"
      :show-close-btn="false"
      confirm-label="Försök igen"
      abort-label="Logga ut"
      @click="errorModalClick"
    >
      <p>Det gick inte att hämta dina byten.</p>
    </InfoModal>
    <div
      v-if="isLoading || isFetchingApplications || isCreatingApplication"
      class="spinner-wrapper"
    >
      <BaseLoadingSpinner />
      <p class="spinner-label">{{ loadingText }}</p>
    </div>
    <InfoModal
      v-else-if="!application"
      :title="
        applications.length > 0
          ? 'Välj befintligt byte'
          : 'Här är det tomt just nu'
      "
      mobile-full-width
      :show-close-btn="true"
      :disable-outside-click="true"
      @click="$emit('logout')"
    >
      <div class="empty-body-container">
        <template v-if="applications.length === 0">
          <section class="no-applications-container">
            <p>
              Det ser ut som att du inte har något påbörjat byte från
              Lägenhetsbyte.se än. Fortsätt ditt sökande på
              <a
                href="https://www.lagenhetsbyte.se"
                target="_blank"
                rel="noopener noreferrer"
                >Lägenhetsbyte.se</a
              >
              och återkom när du hittat ditt byte.
            </p>
          </section>
        </template>
      </div>
      <div class="body-container">
        <template v-if="applications.length > 0">
          <section
            v-for="(group, title) in groupedApplications"
            :key="`group-${title}`"
            class="existing-applications"
          >
            <h4>{{ title }}</h4>
            <ul>
              <li v-for="application in group" :key="application.id">
                <i
                  v-if="isNotInactivator(application)"
                  class="disclaimer-disabled"
                  >(Avbruten av annan part)</i
                >
                <ListItem
                  :selected-value="selectedApplication"
                  :radio-btn-value="`${application.id}`"
                  :item-id="application.id"
                  :is-disabled="isNotInactivator(application)"
                  :progress="application.progress"
                  :apartments="getApplicationApartments(application)"
                  :members="getApplicationNames(application)"
                  @change="setSelectedApplication"
                />
              </li>
            </ul>
          </section>
        </template>
        <div class="btn-wrapper">
          <BaseButton
            :is-rounded="true"
            :is-small="true"
            :is-disabled="!selectedApplication"
            type="button"
            class="button"
            @click="selectApplication(selectedApplication)"
          >
            Gå vidare till valt byte
            <BaseIconText
              class="button-arrow-right"
              :icon-url="svgs.ICONS.ARROW_RIGHT"
            >
            </BaseIconText>
          </BaseButton>
          <BaseButton
            v-if="!isLbMode"
            type="button"
            :is-default="true"
            :is-small="true"
            :is-rounded="true"
            class="button create-new-btn"
            @click="createNewApplication"
          >
            <BaseIconText :icon-url="svgs.ICONS.ADD_CIRCLE_BLUE"
              >Skapa helt ny ansökan
            </BaseIconText>
          </BaseButton>
        </div>
      </div>
    </InfoModal>
    <div v-else class="spinner-wrapper">
      <BaseLoadingSpinner />
      <p class="spinner-label">Hämtar information...</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import applicationService from '@/services/applicationService';
import InfoModal from '@/components/InfoModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';
import ListItem from '@/components/ListItem.vue';

import svgs from '@/utils/icon-constants';
import { readableNames } from '@/utils/common';

export default {
  components: {
    InfoModal,
    BaseLoadingSpinner,
    BaseIconText,
    BaseButton,
    ListItem
  },

  data() {
    return {
      isFetchingApplications: false,
      isCreatingApplication: false,
      applications: [],
      errorModalVisible: false,
      svgs,
      selectedApplication: ''
    };
  },

  computed: {
    ...mapState({
      application: state => state.application.application,
      landlord: state => state.app.landlord,
      appReady: state => state.app.appReady,
      isLoading: state => state.app.isLoading
    }),
    ...mapGetters({
      isLbMode: 'application/isLbMode'
    }),
    groupedApplications() {
      if (!this.applications) {
        return [];
      }

      const groups = this.applications.reduce((result, obj) => {
        const { status } = obj;

        if (status === 'SENT') {
          // Sent
          result = this.createGroup(result, 'Skickade', obj);
        } else if (status === 'INACTIVE') {
          // Inactive
          result = this.createGroup(result, 'Avbrutna', obj);
        } else if (status === 'ARCHIVED') {
          // Declined/archived
          result = this.createGroup(result, 'Avslutade', obj);
        } else {
          // Rest
          result = this.createGroup(result, 'Pågående', obj);
        }
        return result;
      }, {});

      const entries = Object.entries(groups);

      entries.sort(([key1], [key2]) => {
        if (key1 === 'Pågående') {
          return -1;
        } else if (key2 === 'Pågående') {
          return 1;
        } else {
          return 0;
        }
      });
      // Make sure 'Pågående' always is first
      return Object.fromEntries(entries);
    },
    loadingText() {
      if (this.isFetchingApplications) {
        return 'Hämtar dina byten...';
      }
      if (this.isCreatingApplication) {
        return 'Skapar byte...';
      }
      return 'Hämtar information...';
    }
  },
  async created() {
    this.fetchUserApplications();
  },
  methods: {
    ...mapMutations({ setApplicationId: 'application/setApplicationId' }),
    setSelectedApplication(value) {
      this.selectedApplication = value;
    },
    async handleShowModal() {
      if (
        !this.application &&
        !this.isLbMode &&
        this.applications.length === 0
      ) {
        await this.createNewApplication();
      }
    },

    createGroup(list, groupKey, item) {
      if (!list[groupKey]) {
        list[groupKey] = [];
      }
      list[groupKey].push(item);
      return list;
    },

    isNotInactivator(application) {
      return application.status === 'INACTIVE' && !application.isInactivator;
    },

    async selectApplication(applicationId) {
      this.setApplicationId(applicationId);
      this.$emit('initiate');
    },

    async createNewApplication() {
      this.isCreatingApplication = true;
      const newApplication = await applicationService.createApplication(
        this.landlord?.landlordId,
        this.landlord?.name
      );
      this.setApplicationId(newApplication.id);
      this.$emit('initiate');
    },

    async fetchUserApplications() {
      this.isFetchingApplications = true;
      try {
        const res = await applicationService.getUserApplications();
        this.applications = res.sort((a, b) => b.createdDate - a.createdDate);
        await this.handleShowModal();
      } catch {
        this.errorModalVisible = true;
      }
      this.isFetchingApplications = false;
    },

    getApplicationApartments(application) {
      return application.applicants
        .map(x => x.apartments)
        .map(y => y.apartment1)
        .map(z => (z.address ? z.address : 'Adress saknas'))
        .join(', ');
    },

    getApplicationNames(application) {
      const names = application.applicants
        .map(x => x.members)
        .map(y => y.member1)
        .map(z => (z.name ? z.name?.split(' ')[0] : 'Namn saknas'));

      return readableNames(names);
    },

    errorModalClick(retry) {
      if (retry) {
        this.errorModalVisible = false;
        this.fetchUserApplications();
      } else {
        this.$emit('logout');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
}

p {
  max-width: none;
  font-size: 1.6rem;
  @include mobile {
    font-size: 1.4rem;
  }
}

.no-applications-container {
  margin-bottom: 2rem;

  a {
    text-decoration: none;
    color: var(--color-blue);
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.existing-applications {
  margin-bottom: 2rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #f8f8fa;
    border-radius: 10px;
    max-height: 250px;
    overflow-y: auto;
  }

  a {
    text-decoration: none;
  }

  li {
    position: relative;
  }

  li .disclaimer-disabled {
    position: absolute;
    bottom: 0.4rem;
    right: 0.7rem;
    font-size: 1.2rem;
  }

  li:nth-last-of-type(1) .list-item {
    border-bottom: none;
  }
}

.create-container {
  margin-top: 20px;

  p {
    margin-bottom: 20px;
  }
}

.spinner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.spinner-label {
  margin: 1rem 0 0;
}

.btn-wrapper {
  position: sticky;
  bottom: -1px;
  margin: 0;
  height: 60px;
  background-color: #fff;
  display: flex;
  padding: 10px 0 10px 0;
  gap: 1rem;
  @include mobile {
    display: block;
    height: 120px;
    .button {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

.button {
  font-size: 1.2rem;
}

.button-arrow-right {
  margin-left: 1rem;
  :deep(img) {
    margin-right: 0;
  }
}

.create-new-btn {
  color: var(--color-blue);
  border-color: var(--color-blue);
}
</style>
