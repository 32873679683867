<template>
  <div class="tooltip-container">
    <slot />
    <div
      v-if="tooltipExists"
      ref="tooltip"
      :class="[
        'tooltip',
        { 'show-tooltip': showTooltip },
        { bottom: position === 'bottom' },
        { right: position === 'right' },
        { 'bottom-right': position === 'bottom-right' }
      ]"
    >
      <div class="tooltip-header">
        <BaseImage :url="svgs.ICONS.TIP" class="tooltip-header-image" />
        <p>{{ currentTooltipStep + 1 }} av {{ maxTooltipSteps }}</p>
      </div>
      <p v-for="(c, idx) in getCurrentTooltip()" :key="idx">
        {{ c }}
      </p>

      <div class="tooltip-footer">
        <BaseButton
          :is-rounded="true"
          :is-small="true"
          :is-default="true"
          type="submit"
          @click="markTooltipAsSeen({ reference: step })"
        >
          <span class="tooltip-button-text">Jag förstår</span>
          <BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" class="invert">
          </BaseIconText>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import svgs from '@/utils/icon-constants';
import BaseImage from '@/components/BaseImage.vue';

export default {
  components: { BaseButton, BaseIconText, BaseImage },
  props: {
    position: {
      type: String,
      default: 'bottom'
    },
    step: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    ...mapState({
      authenticated: state => state.app.authenticated
    }),
    ...mapGetters({
      tooltipsForScreensize: 'tooltips/tooltipsForScreensize'
    }),
    applicant() {
      // This component is used by both AH & BA
      // Therefore it's an optional getter for BA, to prevent AH from crashing
      return this.$store.getters['application/applicant'] || null;
    },

    showTooltip() {
      return (
        this.authenticated &&
        this.currentTooltipStep < this.maxTooltipSteps &&
        this.tooltipsForScreensize[this.currentTooltipStep].reference ===
          this.step &&
        !this.seenToolTip
      );
    },

    seenToolTip() {
      return this.tooltipsForScreensize.some(
        tip => tip.reference === this.step && tip.seen
      );
    },

    currentTooltipStep() {
      return this.tooltipsForScreensize.filter(tip => tip.seen).length;
    },

    maxTooltipSteps() {
      return this.tooltipsForScreensize.length;
    },

    tooltipExists() {
      if (!this.applicant) {
        return false;
      }
      return this.tooltipsForScreensize.some(tip => {
        if (tip.reference !== this.step) {
          return false;
        }

        for (const condition of tip.conditions) {
          let propertyValue = this.applicant[condition.property];

          // We assume we are always interested in the first object, and get the child property
          if (Array.isArray(propertyValue)) {
            propertyValue = propertyValue[0][condition.childProperty];
          }

          // Check if the value is falsy, and if the falsy value matches the condition
          if (!propertyValue !== condition.isFalsy) {
            return false;
          }
        }

        return true;
      });
    }
  },

  methods: {
    ...mapActions({
      markTooltipAsSeen: 'tooltips/markTooltipAsSeen'
    }),

    getCurrentTooltip() {
      if (this.currentTooltipStep >= this.maxTooltipSteps) {
        return null;
      }

      return this.tooltipsForScreensize[this.currentTooltipStep].content;
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
}

.tooltip-container:has(.hamburger) {
  margin-bottom: -5px;
}

.tooltip {
  --time: 0.3s;
  --tooltip-color: #7b52ea;

  visibility: hidden;
  color: #fff;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  width: 77vw;
  max-width: 420px;
  margin-top: 15px;
  margin-left: -60px;
  white-space: normal;

  position: absolute;
  z-index: 10;

  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
  font-weight: 600;
  font-size: 1.6rem;

  background: var(--tooltip-color);

  @include mobile {
    margin-left: -50px;
  }

  &.show-tooltip {
    visibility: visible;
  }

  &.bottom.show-tooltip {
    left: 60px;
    animation: slide-bottom var(--time) ease-in;
  }

  &.right.show-tooltip {
    top: -120px;
    left: 280px;
    animation: slide-right var(--time) ease-in;
  }

  &.bottom-right.show-tooltip {
    right: -1rem;
    animation: slide-bottom var(--time) ease-in;
  }
}

.tooltip-header {
  display: flex;
  vertical-align: center;

  .tooltip-header-image {
    height: 20px;
    width: 20px;
    margin-right: 12px;
  }
}

.tooltip p {
  margin-bottom: 20px;
}

.tooltip::after {
  content: ' ';
  position: absolute;
  border-style: solid;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

.bottom::after {
  bottom: 100%;
  left: 10%;
  margin-left: -10px;
  border-width: 10px;
  border-color: transparent transparent var(--tooltip-color) transparent;
}

.bottom-right::after {
  bottom: 100%;
  left: 91%;
  margin-left: -10px;
  border-width: 10px;
  border-color: transparent transparent var(--tooltip-color) transparent;
}

.right::after {
  bottom: 50%;
  left: 0%;
  margin-left: -20px;
  border-width: 10px;
  border-color: transparent var(--tooltip-color) transparent transparent;
}

.tooltip-footer {
  .text-icon :deep(.icon) {
    margin-right: 0;
  }

  :deep(.is-default) {
    border: none;
  }

  .invert {
    :deep(img) {
      filter: invert(100%);
    }

    :deep(.is-default) {
      border: none;
    }
  }

  .tooltip-button-text {
    color: #000;
  }
}
</style>
