<template>
  <SwapInput
    :value="value"
    :label="label"
    :description="description"
    :dropdown="htmlDropdown.length > 0 ? htmlDropdown : null"
    :validation="validation"
    v-bind="$attrs"
    :error-text="errorText"
    :is-loading="isSearching"
    v-on="$listeners"
    @dropdownClick="dropdownClick"
  />
</template>

<script>
import debounce from '@/utils/debounce';
import applicationService from '@/services/applicationService';
import { generateHighlightedHtml } from '@/utils/common';

import SwapInput from '@/components/SwapInput.vue';

export default {
  components: {
    SwapInput
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: null,
      required: false
    },
    description: {
      type: String,
      default: null,
      required: false
    },
    errorText: {
      type: String,
      default: null,
      required: false
    },
    validation: {
      required: false,
      type: Function,
      default(val) {
        return val?.length > 0;
      }
    }
  },
  data() {
    return {
      searchResult: [],
      isSearching: false
    };
  },
  computed: {
    htmlDropdown() {
      return generateHighlightedHtml(this.searchResult, this.postalCode);
    }
  },
  watch: {
    value(val = '') {
      if (val.length < 2) {
        this.searchPostalCodeDebounced.cancel();
        this.searchResult = [];
        return;
      }
      this.landlordAutoCompleted = false;
      const trimmedVal = val.trim();
      this.searchPostalCodeDebounced(trimmedVal);
    }
  },
  created() {
    this.searchPostalCodeDebounced = debounce(this.searchPostalCode, 400);
  },
  beforeDestroy() {
    this.searchPostalCodeDebounced.cancel();
  },
  methods: {
    dropdownClick(value) {
      const match = this.searchResult.find(result => result.name === value);
      if (match) {
        this.searchPostalCodeDebounced.cancel();
        this.$emit('dropdownMatch', match);
      }
    },
    async searchPostalCode(query) {
      this.isSearching = true;
      const result = await applicationService.searchPostalCode(query);
      this.searchResult = result
        ? result.filter(result => result.name.length === 5)
        : [];
      this.isSearching = false;
    }
  }
};
</script>

<style></style>
