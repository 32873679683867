<template>
  <div class="field-wrapper">
    <label>
      <p class="title">{{ title }}</p>
      <p class="description">{{ description }}</p>
    </label>
    <div class="select-wrapper" :class="{ error: hasError }">
      <select
        :disabled="isDisabled"
        :title="infoTooltip"
        @input="$emit('input', $event.target.value)"
      >
        <option
          v-for="(value, index) in options"
          :key="index"
          :selected="value === selectedOption"
          :value="value"
        >
          {{ value }}
        </option>
      </select>
    </div>
    <p v-if="hasError" class="select-error">
      {{ errorText }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    options: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    description: {
      type: String,
      default: '',
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    selectedOption: {
      type: String,
      default: '',
      required: true
    },
    errorText: {
      type: String,
      default: 'Detta fält måste fyllas i korrekt'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    infoTooltip: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
label {
  line-height: 1.5;
  padding-bottom: 1rem;
  display: block;
}

.field-wrapper {
  margin-bottom: 4.8rem;
  position: relative;
}

.title {
  font-weight: 600;
  font-size: 1.6rem;
}
.description {
  font-weight: 400;
  font-size: 1.2rem;
}

.select-wrapper {
  position: relative;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  color: var(--color-dark);
  font-size: 1.6rem;
  line-height: 1.5;
  border: 2px solid transparent;
  background-color: var(--color-light-gray);
  padding-right: 1rem;
  &.error {
    border-color: var(--color-red);
  }
  @include mobile {
    width: 100%;
  }
}

select {
  cursor: pointer;
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 2px solid transparent;
  position: relative;
  outline: 0px;
  width: 100%;
  padding: 0.8rem 2rem;
  &[disabled] {
    appearance: none;
    color: var(--color-disabled);
    cursor: not-allowed;
  }
}

.select-error {
  margin-top: 0.3rem;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--color-red);
  position: absolute;
  font-weight: 400;
  top: 100%;
  left: 0;
}
</style>
