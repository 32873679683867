import Vue from 'vue';
import Vuex from 'vuex';
import createAppModule from '../shared/app';
import createTooltipsModule from '../shared/tooltips';
import createCompaniesModule from '../shared/companies';
import ovApplication from './ovApplication';
import ovValidation from './ovValidation';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    app: createAppModule(),
    tooltips: createTooltipsModule(),
    companies: createCompaniesModule(),
    ovApplication,
    ovValidation
  }
});
