<template>
  <CommonApp>
    <DevInfo />
    <MainHeader
      :show-logo="true"
      :is-mobile="isMobile"
      :show-right-container="authenticated"
      :side-menu-open="sideMenuVisible"
      :landlord="landlord"
      :default-logo-src="andrahandLogo"
      default-logo-alt="Andrahand logo"
      @showSubletInformationClick="handleShowSubletInformationToggle"
      @toggleMenuClick="toggleSideMenuVisible(!sideMenuVisible)"
      @logoutClick="logout"
    />

    <template v-if="appReady && authenticated">
      <SubletRenewalModal
        v-if="renewalModalVisible"
        :is-loading="extendApplicationLoading"
        @response="handleRenewalResponse"
      />
      <SubletInformationModal
        v-else-if="subletInformationModalVisible"
        :has-read-info="hasReadInfo"
        @confirmAndCloseInfoModal="handleUpdateHasReadInfo(application.id)"
        @closeInfoModal="subletInformationModalVisible = false"
      />
    </template>
    <div class="app-inner-wrapper">
      <SubletSideMenu
        v-if="!loading && authenticated"
        :visible="sideMenuVisible"
        @close="toggleSideMenuVisible(false)"
        @logout="
          toggleSideMenuVisible(false);
          logout();
        "
      />

      <main class="app-inner-wrapper-main">
        <div v-if="loading" class="app-spinner-wrapper">
          <BaseLoadingSpinner />
          <p class="app-spinner-label">Hämtar information...</p>
        </div>
        <div v-else class="content-wrapper">
          <transition
            name="fade-list"
            mode="out-in"
            appear
            @enter="forceScrollTop"
          >
            <div :key="$route.path">
              <router-view @initiate="initiate" />
            </div>
          </transition>
        </div>
        <SupportButton></SupportButton>
      </main>
    </div>
  </CommonApp>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import auth from '@/services/auth';
import andrahandLogo from '@/assets/svgs/andrahand.svg';

import SubletSideMenu from './components/SubletSideMenu.vue';
import MainHeader from '@/components/MainHeader.vue';
import SubletInformationModal from '@/components/SubletInformationModal.vue';
import SubletRenewalModal from '@/components/SubletRenewalModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import SupportButton from '@/components/SupportButton.vue';
import DevInfo from '@/components/DevInfo.vue';
import CommonApp from '@/components/CommonApp.vue';

export default {
  name: 'SubletApp',
  components: {
    SubletSideMenu,
    MainHeader,
    SupportButton,
    SubletInformationModal,
    SubletRenewalModal,
    BaseLoadingSpinner,
    DevInfo,
    CommonApp
  },
  data() {
    return {
      subletInformationModalVisible: true,
      extendApplicationLoading: false,
      loading: false,
      inviteError: false,
      andrahandLogo
    };
  },
  computed: {
    ...mapState({
      appReady: state => state.app.appReady,
      authenticated: state => state.app.authenticated,
      sideMenuVisible: state => state.app.sideMenuVisible,
      renewalModalVisible: state => state.subletApplication.renewalModalVisible,
      landlord: state => state.app.landlord,
      application: state => state.subletApplication.application,
      applicationId: state => state.subletApplication.applicationId
    }),
    ...mapGetters({
      hasReadInfo: 'subletApplication/hasReadInfo',
      applicationIsSentAndApproved:
        'subletApplication/applicationIsSentAndApproved',
      applicationIsSentAndArchived:
        'subletApplication/applicationIsSentAndArchived',
      sideMenu: 'subletApplication/sideMenu',
      isMobile: 'app/isMobile'
    }),
    ableToCreateNewApplication() {
      return (
        this.applicationIsSentAndArchived || this.applicationIsSentAndApproved
      );
    }
  },
  watch: {
    ableToCreateNewApplication: {
      handler(isAbleToCreateNewApplication) {
        if (isAbleToCreateNewApplication) {
          this.setRenewalModalVisible(true);
          this.subletInformationModalVisible = false;
        }
      },
      immediate: true
    }
  },
  async created() {
    await this.initiate();
  },
  methods: {
    ...mapActions({
      toggleSideMenuVisible: 'app/toggleSideMenuVisible',
      fetchApplication: 'subletApplication/fetchApplication',
      updateHasReadInfoCheck: 'subletApplication/updateHasReadInfoCheck',
      extendAhApplication: 'subletApplication/extendAhApplication',
      createNewAhApplication: 'subletApplication/createNewAhApplication',
      fetchSubletApplication: 'subletApplication/fetchSubletApplication',
      getNextAllowedPath: 'subletApplication/getNextAllowedPath'
    }),
    ...mapMutations({
      setRenewalModalVisible: 'subletApplication/setRenewalModalVisible',
      setInitData: 'app/setInitData',
      setApplicationId: 'subletApplication/setApplicationId',
      setApplication: 'subletApplication/setApplication'
    }),
    async reset() {
      this.setApplication(null);
      this.setApplicationId(null);
      await this.initiate();
    },
    async logout() {
      localStorage.clear();
      await this.reset();
      await this.$router.push('/logga-in');
    },
    getRedirectPath() {
      let redirectPath = '/logga-in';
      const pathArr = window.location.pathname.split('/');
      const page = pathArr[1];
      const params = [];

      // Example: trying to access andrahand.se/granska/1337, but not authed
      // save info, later on in the Vue router(router/index.js)
      // we'll check these params and route user correctly
      if (page) {
        params.push(`page=${page}`);
      }

      return params.length > 0
        ? `${redirectPath}?${params.join('&')}`
        : redirectPath;
    },
    getQueryParams() {
      const urlParams = new URLSearchParams(window.location.search);
      return {
        page: urlParams.get('page')
      };
    },
    clearUserLocalStorage() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userId');
    },
    async checkIfPathAllowedOrGoNext(path) {
      const pathItem = this.sideMenu.find(item => item.url === path);
      if (!pathItem) {
        await this.$router.push('/');
      } else if (pathItem.enabled) {
        await this.$router.push(pathItem.url);
      } else {
        const nextAllowedPath = await this.getNextAllowedPath(this.$router);
        await this.$router.push(nextAllowedPath);
      }
    },
    async initiate() {
      this.loading = true;

      if (!this.applicationId) {
        const localStorageApplicationId = localStorage.getItem('applicationId');
        if (localStorageApplicationId) {
          this.setApplicationId(localStorageApplicationId);
        }
      }

      const valid = await auth.validateSession();

      if (valid) {
        const { page } = this.getQueryParams();
        const landlordIdWithFallback = this.landlord?.landlordId || '169223';

        await this.fetchSubletApplication(landlordIdWithFallback);

        if (page) {
          if (page === 'logga-in') {
            await this.$router.push('/');
          } else {
            await this.checkIfPathAllowedOrGoNext(page);
          }
        } else if (window.location.pathname === '/logga-in') {
          await this.$router.push('/');
        } else {
          await this.checkIfPathAllowedOrGoNext(this.$route.path);
        }
      } else {
        // Not authed
        this.clearUserLocalStorage();

        if (window.location.pathname !== '/logga-in') {
          await this.$router.push(this.getRedirectPath());
        }
      }

      this.setInitData({
        appReady: true,
        authenticated: valid
      });

      this.loading = false;
    },
    forceScrollTop() {
      this.$nextTick(() => {
        window.scroll(0, 0);
      });
    },
    handleUpdateHasReadInfo(applicationId) {
      this.updateHasReadInfoCheck(applicationId);
      this.subletInformationModalVisible = false;
    },
    handleShowSubletInformationToggle() {
      this.subletInformationModalVisible = !this.subletInformationModalVisible;
    },
    async handleRenewalResponse(response) {
      if (response === 'EXTEND') {
        this.extendApplicationLoading = true;
        await this.extendAhApplication();
        this.$router.push('/uppgifter');
        this.extendApplicationLoading = false;
      }
      if (response === 'CREATE_NEW') {
        this.extendApplicationLoading = true;
        await this.createNewAhApplication();
        this.$router.push('/uppgifter');
        this.extendApplicationLoading = false;
      }
      if (response === 'CLOSE') {
        this.setRenewalModalVisible(false);
        this.subletInformationModalVisible = false;
      } else {
        this.setRenewalModalVisible(false);
        this.subletInformationModalVisible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sublet-page-header {
  :deep(.text-container) {
    padding-top: 0;
  }
}

.center {
  margin: 0 auto;
}
</style>
