function linkEnabled(statusName, reasonType) {
  let enabled = true;
  let disabledReason = '';

  if (statusName === 'DOCUMENTS' && !reasonType) {
    enabled = false;
  }
  return { enabled, disabledReason };
}

export default {
  filteredMenu(state, getters) {
    const blackList = ['DATA_SUMMARY', 'STATUS'];

    return getters.sideMenu.filter(
      item => !blackList.includes(item.statusName)
    );
  },
  sideMenu(state, getters) {
    const applicationIsEditable = getters.applicationIsEditable;
    const reasonType = state.application?.reason?.type || '';

    const menu = [
      {
        label: 'Dina uppgifter',
        url: '/uppgifter',
        key: 'currentTenants',
        statusName: 'PERSONAL_INFORMATION'
      },
      {
        label: 'Din lägenhet',
        url: '/lagenhet',
        key: 'apartment',
        statusName: 'APARTMENT'
      },
      {
        label: 'Ditt skäl',
        url: '/skal',
        key: 'reason',
        statusName: 'REASON'
      },
      {
        label: 'Ladda upp filer',
        url: '/dokument',
        key: 'documents',
        statusName: 'DOCUMENTS'
      },
      {
        label: 'Din andrahandshyresgäst',
        url: '/andrahandshyresgast',
        key: 'secondaryTenants',
        statusName: 'SUBTENANT_INFORMATION'
      },
      {
        label: 'Förhandsgranska',
        url: '/granska',
        key: '',
        statusName: 'DATA_SUMMARY'
      },
      {
        label: 'Vad händer nu?',
        url: '/status',
        key: '',
        statusName: 'STATUS'
      }
    ];

    return menu
      .filter(item => {
        if (item.statusName === 'STATUS' && applicationIsEditable) {
          return false;
        }
        return true;
      })
      .map(item => {
        const { enabled, disabledReason } = linkEnabled(
          item.statusName,
          reasonType
        );
        return {
          ...item,
          enabled,
          disabledReason
        };
      });
  },
  applicationIsSentAndApproved(state) {
    if (!state.application) {
      return false;
    }
    return (
      state.application.status === 'SENT' &&
      state.application.landlordStatus === 'APPROVED'
    );
  },
  applicationIsSentAndArchived(state) {
    if (!state.application) {
      return false;
    }

    const archivedStatuses = [
      'CLOSED',
      'REJECTED',
      'ABORTED',
      'REMOVED',
      'PENDING_REMOVE'
    ];

    return (
      state.application.status === 'SENT' &&
      archivedStatuses.includes(state.application.landlordStatus)
    );
  },
  applicationIsEditable(state) {
    if (!state.application) {
      return false;
    }
    return state.application.status === 'WAITING';
  },
  applicationCategoryRejected(state, getters) {
    return getters.rejectedCategories.some(category => !!category);
  },
  hasReadInfo(state) {
    return state.application?.hasReadInfo || false;
  },
  rejectedCategories(state) {
    if (!state.application) {
      return [];
    }

    const categories = [
      'currentTenants',
      'apartment',
      'reason',
      'documents',
      'computedDocumentsGroups',
      'secondaryTenants'
    ];

    return Object.keys(state.application).filter(key => {
      if (!categories.includes(key)) {
        return false;
      }

      const category = state.application[key];

      if (key === 'computedDocumentsGroups') {
        return Object.values(category || {}).some(
          doc => doc.status === 'REJECTED'
        );
      }

      if (Array.isArray(category)) {
        return category.find(cat => cat.status === 'REJECTED');
      }
      return category.status === 'REJECTED';
    });
  },
  categoryRejected: (state, getters) => category => {
    if (!state.application) {
      return [];
    }
    return !!getters.rejectedCategories.find(item => item === category);
  },
  documentsMissing(state, getters) {
    const documents = state.application.documents;
    const expectedDocuments = getters.requiredDocuments;
    const uploadedDocuments = Array.isArray(documents)
      ? documents.map(doc => doc.category)
      : [];

    let missingDocuments = [];

    if (expectedDocuments.length > 0) {
      missingDocuments = expectedDocuments.filter(
        document => !uploadedDocuments.includes(document)
      );
    } else {
      missingDocuments = [];
    }

    return (
      missingDocuments.length > 0 ||
      (expectedDocuments.length === 0 && uploadedDocuments.length === 0)
    );
  },
  requiredDocuments(state) {
    if (!state.application?.computedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.computedDocumentsGroups)
      .filter(([, props]) => props.required === 1)
      .map(item => item[0]);
  },
  noneRequiredDocuments(state) {
    if (!state.application?.computedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.computedDocumentsGroups)
      .filter(([, props]) => props.required === 0)
      .map(item => item[0]);
  }
};
