<template>
  <aside ref="stickySidebar" class="sticky-sidebar-aside">
    <slot />
  </aside>
</template>

<script>
import StickySidebar from '@/utils/sticky-sidebar';

export default {
  name: 'StickyContainer',
  data() {
    return {
      stickySidebar: null
    };
  },
  mounted() {
    this.stickySidebar = new StickySidebar(this.$refs.stickySidebar, {
      containerSelector: '.app-inner-wrapper'
    });
  }
};
</script>
<style lang="scss">
.sticky-sidebar-aside {
  z-index: 8;
  @include mobile {
    height: auto !important;
    .inner-wrapper-sticky {
      position: static !important;
    }
  }
}
</style>
