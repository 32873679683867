<template>
  <Summary
    v-if="companyDetails"
    :show-change-btn="false"
    :title="companyDetails.name"
    :application-is-editable="false"
  >
    <SummaryBody :sub-heading="`Om ${companyDetails.name}`">
      <SummaryField label="VD">{{ companyDetails.ceo }}</SummaryField>
      <SummaryField label="Adress">{{ companyDetails.address }}</SummaryField>
      <SummaryField label="SNI-branch">{{ companyDetails.sni }}</SummaryField>
      <SummaryField label="Registreringsdatum">{{
        new Date(companyDetails.registrationDate).toLocaleDateString('sv-SE')
      }}</SummaryField>
      <SummaryField label="Antal anställda">{{
        companyDetails.employeesAmount
      }}</SummaryField>
      <SummaryField label="Verksamhet & ändamål">{{
        companyDetails.activities
      }}</SummaryField>
    </SummaryBody>
  </Summary>
</template>
<script>
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import SummaryField from '@/components/summary/SummaryField.vue';

export default {
  components: {
    Summary,
    SummaryBody,
    SummaryField
  },
  props: {
    companyDetails: {
      type: Object,
      default: null
    }
  }
};
</script>
