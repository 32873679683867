import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  selectedApplicantIndex: 0,
  selectedPossessionIndex: 0,
  application: null,
  applicationId: null,
  isLoadingDocument: false,
  inviteCode: undefined,
  skipRouterIndexReset: false, //Router resets applicant/possesionIndex after each route change, this will bypass that once
  menu: {
    Byteskedja: [
      {
        label: 'Skapa byteskedja',
        url: '/byteskedja',
        statusName: 'APPLICATION'
      }
    ],
    Grundläggande: [
      {
        label: 'Lägenhet',
        url: '/lagenhet',
        statusName: 'APARTMENTS'
      },
      {
        label: 'Boenden',
        url: '/boenden',
        statusName: 'MEMBERS'
      },
      {
        label: 'Skäl till bytet',
        url: '/skal-till-bytet',
        statusName: 'SWAPREASON'
      },
      {
        label: 'Bytesdetaljer',
        url: '/bytesdetaljer',
        statusName: 'SWAPDETAILS'
      }
      // {
      //   label: 'Erbjudanden',
      //   url: '/erbjudanden',
      //   statusName: null
      // }
    ],
    Dokument: [
      {
        label: 'Min UC',
        url: '/ladda-upp/minuc',
        statusName: 'CREDIT_INFORMATION'
      },
      {
        label: 'Familjebevis',
        url: '/ladda-upp/personbevis',
        statusName: 'FAMILY_CERTIFICATE'
      },
      {
        label: 'Inkomstunderlag',
        url: '/ladda-upp/inkomstbevis',
        statusName: 'INCOME_BASIS'
      },
      {
        label: 'Hyresavtal',
        url: '/ladda-upp/hyresavtal',
        statusName: 'LEASE_AGREEMENT'
      },
      {
        label: 'Aktuell hyra',
        url: '/ladda-upp/aktuell-hyra',
        statusName: 'RENTAL_INVOICE'
      },
      {
        label: 'Övriga dokument',
        url: '/ladda-upp/ovriga-dokument',
        statusName: 'OTHER'
      },
      {
        label: 'Vad händer nu?',
        url: '/status',
        statusName: 'STATUS'
      }
    ]
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
