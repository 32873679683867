<template>
  <label
    :for="`r_id_${itemId}`"
    class="list-item"
    :class="{
      disabled: isDisabled
    }"
  >
    <input
      :id="`r_id_${itemId}`"
      :value="radioBtnValue"
      :disabled="isDisabled"
      type="radio"
      name="radio_select_application"
      class="input-radio"
      :checked="isSelected"
      @change="$emit('change', radioBtnValue)"
    />
    <label :for="`r_id_${itemId}`" class="custom-radiobox"></label>
    <div
      v-if="progress"
      class="progress"
      :data-progress="progress"
      :style="{
        '--progress': `${progress * 3.6}deg`
      }"
    >
      {{ progress }}
    </div>
    <div class="application-meta">
      <p class="application-apartments">{{ apartments }}</p>
      <p class="application-members">
        {{ members }}
      </p>
    </div>
  </label>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    itemId: {
      type: String,
      required: true
    },
    progress: {
      type: Number,
      default: null
    },
    selectedValue: {
      type: String,
      required: true
    },
    radioBtnValue: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false
    },
    apartments: {
      type: String,
      default: null
    },
    members: {
      type: String,
      required: true
    }
  },
  computed: {
    isSelected() {
      return this.radioBtnValue === this.selectedValue;
    }
  }
};
</script>

<style scoped>
.list-item {
  margin: 0 2rem;
  padding: 1.2rem 0;
  color: #000;
  display: flex;
  gap: 2rem;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--color-light-gray);
  text-align: left;
}

.list-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.input-radio {
  position: absolute;
  left: -9999px;
}

.input-radio:checked + .custom-radiobox::after {
  opacity: 1;
}

.custom-radiobox {
  width: 1.6rem;
  height: 1.6rem;
  background-color: transparent;
  border: 2px solid var(--color-blue);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}
.custom-radiobox::after {
  content: '';
  position: absolute;
  height: 0.8rem;
  width: 0.8rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-blue);
  border-radius: 50%;
  opacity: 0;
  transition: 0.3s ease;
}

.progress {
  flex-shrink: 0;
  display: flex;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background: conic-gradient(
    var(--color-blue) var(--progress),
    var(--color-light-gray) 0deg
  );
  font-size: 0;
}

.progress::after {
  content: attr(data-progress) '%';
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5px;
  border-radius: 50%;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
  text-align: center;
}

.application-apartments {
  font-weight: 600;
}

.application-members {
  color: var(--color-gray);
}
</style>
