<template>
  <div>
    <PageHeader
      :key="$route.path"
      class="sublet-page-header"
      :is-mobile="false"
      :names="names"
      :title="title"
      :show-tab-menu="false"
    >
    </PageHeader>
    <!-- <transition
      v-for="category in categories"
      :key="category.index || 0"
      mode="out-in"
      name="accordion-fade"
    > -->
    <CollapseCard
      v-for="category in filteredCategories"
      :key="category.index || 0"
      :is-alert="true"
      class="mb-2"
    >
      <h3 slot="title-text">
        {{ getMessage(category) }}
      </h3>
      <template slot="body-text">
        <p>{{ category.rejectReason }}</p>
      </template>
    </CollapseCard>
    <!-- </transition> -->
    <slot />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import CollapseCard from '@/components/CollapseCard.vue';

export default {
  name: 'SubletPage',
  components: {
    PageHeader,
    CollapseCard
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    names: {
      type: String,
      default: ''
    },
    categories: {
      type: [Array],
      default: () => []
    }
  },
  computed: {
    filteredCategories() {
      return this.categories.filter(
        category => category.status === 'REJECTED' && category.rejectReason
      );
    }
  },
  methods: {
    getMessage(category) {
      // If fileName then its a document - skip
      if (category.hasOwnProperty('index') && !category.fileName) {
        return `Kommentar till ${category.name || `Person ${category.index + 1}`} från
          handläggare`;
      }
      return 'Kommentar från handläggare';
    }
  }
};
</script>

<style></style>
