<template>
  <div class="field-wrapper" :class="{ noMargin }">
    <transition name="fade" mode="out-in">
      <div v-if="formExpanded">
        <slot />
      </div>
    </transition>
    <div class="btn-container">
      <ExpandButton
        :icon-url="iconUrl"
        :is-disabled="disabled"
        type="button"
        @click="$emit('click')"
      >
        {{ btnText }}
      </ExpandButton>
    </div>
  </div>
</template>

<script>
import ExpandButton from './ExpandButton.vue';
import svgs from '@/utils/icon-constants';

export default {
  name: 'ExpandableForm',
  components: {
    ExpandButton
  },
  props: {
    btnText: {
      type: String,
      default: ''
    },
    formExpanded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    iconUrl() {
      if (this.formExpanded) {
        return this.disabled
          ? svgs.ICONS.REMOVE_CIRCLE_GREY
          : svgs.ICONS.REMOVE_CIRCLE_BLUE;
      }
      return this.disabled
        ? svgs.ICONS.ADD_CIRCLE_GREY
        : svgs.ICONS.ADD_CIRCLE_BLUE;
    }
  }
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  .btn-container {
    margin-bottom: 4.8rem;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  &.noMargin {
    margin: 0 !important;
    .btn-container {
      margin: 0;
    }
  }
}
</style>
