<template>
  <SubletPage
    v-if="secondaryTenants"
    :categories="secondaryTenants"
    title="Uppgifter på andrahandshyresgäst"
  >
    <SubletForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      :form-type="formType"
      v-on="$listeners"
    >
      <div class="input-wrapper">
        <SubletInput
          v-model="secondaryTenants[0].name"
          label="Namn"
          placeholder="Namn"
          description="Ange för- och efternamn på den person som ska hyra i andra hand"
          autocomplete="given-name"
        />
        <Fieldset>
          <SubletInput
            v-model="secondaryTenants[0].email"
            label="E-post"
            placeholder="E-post"
            description="Ange e-postadress till den person som ska hyra i andra hand"
            type="email"
            :validation="validEmail"
            error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
            autocomplete="email"
          />
          <SubletInput
            v-model="secondaryTenants[0].phone"
            label="Telefonnummer"
            placeholder="Telefonnummer"
            description="Ange telefonnummer till den person som ska hyra i andra hand"
            :validation="validateNumber"
            error-text="Fältet får endast innehålla siffror"
            inputmode="numeric"
            pattern="[0-9]*"
            autocomplete="tel"
          />
        </Fieldset>
        <SubletInput
          v-model="secondaryTenants[0].ssn"
          label="Personnummer"
          placeholder="Personnummer"
          description="Ange personnummer på den person som ska hyra i andra hand"
          :validation="validateSsn"
          :error-text="ssnErrorText"
        />
        <SubletInput
          v-model="secondaryTenants[0].address"
          label="Gatuadress"
          placeholder="Gatuadress"
          description="Ange nuvarande folkbokföringsadress till förslagna andrahandshyresgästen"
        />
      </div>
      <ExpandableForm
        :disabled="!applicationIsEditable"
        :btn-text="
          formExpanded
            ? 'Ta bort den andra hyresgästen'
            : 'Lägg till en andra hyresgäst'
        "
        :form-expanded="formExpanded"
        @click="handleButtonClick"
      >
        <template v-if="secondaryTenants && secondaryTenants[1]">
          <h1 ref="expandableForm">Andra personen på kontraktet</h1>
          <SubletInput
            v-model="secondaryTenants[1].name"
            label="Namn"
            placeholder="Namn"
            description="Ange för- och efternamn på andra personen på kontraktet"
            autocomplete="given-name"
          />
          <Fieldset>
            <SubletInput
              v-model="secondaryTenants[1].email"
              label="E-post"
              placeholder="E-post"
              description="Ange e-postadress till andra personen på kontraktet"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              autocomplete="email"
            />
            <SubletInput
              v-model="secondaryTenants[1].phone"
              label="Telefonnummer"
              placeholder="Telefonnummer"
              description="Ange telefonnummer till andra personen på kontraktet"
              :validation="validateNumber"
              error-text="Fältet får endast innehålla siffror"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="tel"
            />
          </Fieldset>
          <SubletInput
            v-model="secondaryTenants[1].ssn"
            label="Personnummer"
            placeholder="Personnummer"
            description="Ange personnummer på andra personen på kontraktet"
            :validation="validateSsn"
            :error-text="ssnErrorText"
          />
          <SubletInput
            v-model="secondaryTenants[1].address"
            label="Gatuadress"
            placeholder="Gatuadress"
            description="Ange nuvarande folkbokföringsadress till andra personen på kontraktet"
          />
          <InfoModal
            v-if="confirmModalVisible"
            title="Är du säker?"
            :mobile-full-width="isMobile"
            :show-close-btn="false"
            confirm-label="Ta bort"
            :confirm-icon="false"
            abort-label="Avbryt"
            @click="confirmModalClick"
          >
            <p>Vill du ta bort den andra hyresgästen?</p></InfoModal
          >
        </template>
      </ExpandableForm>
    </SubletForm>
  </SubletPage>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePersonalNumber
} from '@/utils/input-validations';
import SubletPage from '@/components/SubletPage.vue';
import ExpandableForm from '@/components/ExpandableForm.vue';
import SubletInput from '@/components/SubletInput.vue';
import SubletForm from '@/components/SubletForm.vue';
import InfoModal from '@/components/InfoModal.vue';
import Fieldset from '@/components/Fieldset.vue';
import { scrollExpandableForm } from '@/utils/scroll';
import { cloneTenant } from '@/utils/sublet';

export default {
  name: 'SubletTenantInformation',
  components: {
    SubletPage,
    ExpandableForm,
    SubletInput,
    SubletForm,
    InfoModal,
    Fieldset
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formExpanded: false,
      validEmail,
      validateNumber,
      ssnErrorText:
        'Personnummer måste anges med 10 siffror, t.ex. YYMMDD-XXXX',
      confirmModalVisible: false,
      formType: 'secondaryTenants'
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable'
    }),
    secondaryTenants: {
      get() {
        return this.application?.secondaryTenants;
      },
      set() {
        this.$emit();
      }
    }
  },
  mounted() {
    if (this.secondaryTenants[1]) {
      this.formExpanded = true;
    }
  },
  methods: {
    async confirmModalClick(eventType) {
      if (eventType === 'CONFIRM') {
        this.$delete(this.secondaryTenants, 1);
        this.emitEditing();
        this.formExpanded = false;
      }
      this.confirmModalVisible = false;
    },
    handleButtonClick() {
      const expand = !this.formExpanded;

      if (!expand && this.secondaryTenants[1]) {
        this.confirmModalVisible = true;
      } else {
        // Create second tenant if not exist
        this.$set(this.secondaryTenants, 1, cloneTenant(this.secondaryTenants));
        this.emitEditing();
        this.formExpanded = expand;
      }

      this.$nextTick(() => {
        this.scroll();
      });
    },
    scroll() {
      const formInput = this.$refs.expandableForm;
      const targetId = 'sticky-text-container';

      scrollExpandableForm(formInput, targetId);
    },
    validateSsn(value) {
      if (!validatePersonalNumber(value)) {
        this.ssnErrorText = 'Det personnummer du angivit är inte giltigt';
        return false;
      }
      return validatePersonalNumber(value);
    },
    emitEditing() {
      this.$emit('isEditing', this.formType);
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 2.8rem;
}
</style>
