export default {
  addDoc(state, newDocument) {
    state.application.documents = [
      ...(state.application.documents || []),
      newDocument
    ];
  },
  removeDoc(state, uuid) {
    state.application.documents = state.application.documents.filter(
      x => x.uuid !== uuid
    );
  },
  setApplicationId(state, id) {
    state.applicationId = id;
  },
  setApplicationStatus(state, status) {
    state.application.status = status;
  },
  setApplication(state, application) {
    state.application = application;
  },
  setApplicationType(state, type) {
    state.applicationType = type;
  },
  setRenewalModalVisible(state, visible) {
    state.renewalModalVisible = visible;
  }
};
