import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default function createAppModule() {
  return {
    namespaced: true,
    state: {
      screenSize: 'DESKTOP',
      authenticated: false,
      appReady: false,
      isLoading: false,
      landlord: null,
      sideMenuVisible: false,
      sideMenuDisabled: false,
      supportAddress: '',
      altSupportAddress: ''
    },
    getters,
    mutations,
    actions
  };
}
