import {
  fieldIsEmpty,
  validateNumber,
  validatePostalCode,
  validatePersonalNumber,
  validEmail
} from '@/utils/input-validations';

const summarizeStatuses = statuses => {
  if (!statuses || statuses.every(status => status === '')) return '';
  if (statuses.every(status => status === 'EMPTY')) return 'EMPTY';
  if (statuses.includes('COMMENT')) return 'COMMENT';
  if (statuses.every(status => status === 'APPROVED')) return 'APPROVED';
  if (statuses.includes('INVALID')) return 'INVALID';
  if (statuses.some(status => ['INCOMPLETE', 'EMPTY'].includes(status)))
    return 'INCOMPLETE';
  return 'COMPLETE';
};

function validateField(field) {
  if (fieldIsEmpty(field.value)) {
    return field.optional ? '' : 'EMPTY';
  }
  if (!field.valid) {
    return 'INVALID';
  }
  return 'COMPLETE';
}

function getAdminStatus(obj, index) {
  const theObj = index !== null && index !== undefined ? obj[index] : obj;

  if (theObj.status === 'REJECTED') {
    return 'COMMENT';
  }
  if (theObj.status === 'APPROVED') {
    return 'APPROVED';
  }
  return '';
}
const statusTranslator = {
  REJECTED: 'COMMENT',
  APPROVED: 'APPROVED'
};
function getAdminDocGroupStatuses(documentGroup) {
  if (!documentGroup) {
    return [];
  }
  return Object.values(documentGroup)
    .map(item => statusTranslator[item.status])
    .filter(n => !!n);
}

function generateValidationField(field) {
  const entries = Object.entries(field);
  return entries.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: validateField(value)
    }),
    {}
  );
}

export default {
  PERSONAL_INFORMATION(state, getters, rootState) {
    if (!rootState.subletApplication?.application) {
      return [];
    }
    const { currentTenants } = rootState.subletApplication.application;

    return currentTenants.map(tenant => {
      let fields = {
        name: {
          label: 'Namn',
          key: 'name',
          value: tenant.name,
          valid: true
        },
        email: {
          label: 'E-post',
          key: 'email',
          value: tenant.email,
          valid: validEmail(tenant.email)
        },
        phone: {
          label: 'Telefonnummer',
          key: 'phone',
          value: tenant.phone,
          valid: validateNumber(tenant.phone)
        },
        ssn: {
          label: 'Personnummer',
          key: 'ssn',
          value: tenant.ssn,
          valid: validatePersonalNumber(tenant.ssn)
        }
      };
      if (tenant.hasOwnProperty('onCurrentContract')) {
        fields = {
          ...fields,
          onCurrentContract: {
            label: 'Står på hyreskontraktet',
            key: 'onCurrentContract',
            value: tenant.onCurrentContract ? 'Ja' : 'Nej',
            valid: true
          }
        };
      }
      return fields;
    });
  },
  PERSONAL_INFORMATION_VALIDATION(state, getters) {
    if (!getters.PERSONAL_INFORMATION) {
      return {};
    }
    return getters.PERSONAL_INFORMATION.map(field =>
      generateValidationField(field)
    );
  },
  APARTMENT(state, getters, rootState) {
    if (!rootState.subletApplication?.application) {
      return {};
    }
    const { apartment } = rootState.subletApplication.application;
    return {
      address: {
        label: 'Adress',
        key: 'address',
        value: apartment.address,
        valid: true
      },
      postalCode: {
        label: 'Postnummer',
        key: 'postalCode',
        value: apartment.postalCode,
        valid: validatePostalCode(apartment.postalCode)
      },
      city: {
        label: 'Ort',
        key: 'city',
        value: apartment.city,
        valid: true
      },
      objectNumber: {
        label: 'Objekt/kontraktsnummer',
        key: 'objectNumber',
        value: apartment.objectNumber,
        valid: true
      },
      apartmentNumber: {
        label: 'Lägenhetsnummer',
        key: 'apartmentNumber',
        value: apartment.apartmentNumber,
        valid: validateNumber(apartment.apartmentNumber)
      },
      rent: {
        label: 'Månadshyra',
        key: 'rent',
        value: apartment.rent,
        valid: validateNumber(apartment.rent)
      },
      furnished: {
        label: 'Hyr ut möblerat',
        key: 'furnished',
        value: apartment.furnished ? 'Ja' : 'Nej',
        valid: true
      }
    };
  },
  APARTMENT_VALIDATION(state, getters) {
    if (!getters.APARTMENT) {
      return {};
    }

    return generateValidationField(getters.APARTMENT);
  },
  SUBTENANT_INFORMATION(state, getters, rootState) {
    if (!rootState.subletApplication?.application) {
      return [];
    }
    const { secondaryTenants } = rootState.subletApplication.application;
    return secondaryTenants.map(tenant => ({
      name: {
        label: 'Namn',
        key: 'name',
        value: tenant.name,
        valid: true
      },
      email: {
        label: 'E-post',
        key: 'email',
        value: tenant.email,
        valid: validEmail(tenant.email)
      },
      phone: {
        label: 'Telefonnummer',
        key: 'phone',
        value: tenant.phone,
        valid: validateNumber(tenant.phone)
      },
      ssn: {
        label: 'Personnummer',
        key: 'ssn',
        value: tenant.ssn,
        valid: validatePersonalNumber(tenant.ssn)
      },
      address: {
        label: 'Adress',
        key: 'address',
        value: tenant.address,
        valid: true
      }
    }));
  },
  SUBTENANT_INFORMATION_VALIDATION(state, getters) {
    if (!getters.SUBTENANT_INFORMATION) {
      return {};
    }
    return getters.SUBTENANT_INFORMATION.map(field =>
      generateValidationField(field)
    );
  },
  REASON(state, getters, rootState) {
    if (!rootState.subletApplication?.application) {
      return {};
    }
    const { reason } = rootState.subletApplication.application;
    let fields = {
      rentFrom: {
        label: 'Önskar hyra ut fr.o.m',
        key: 'rentFrom',
        value: reason.rentFrom,
        valid: true
      },
      rentTo: {
        label: 'Önskar hyra ut t.o.m',
        key: 'rentTo',
        value: reason.rentTo,
        valid: true
      }
    };
    if (reason.type === 'sambo') {
      fields = {
        ...fields,
        address: {
          label: 'Adress',
          key: 'address',
          value: reason.address,
          valid: true
        },
        postalCode: {
          label: 'Postnummer',
          key: 'postalCode',
          value: reason.postalCode,
          valid: validatePostalCode(reason.postalCode)
        },
        partnerName: {
          label: 'Namn',
          key: 'partnerName',
          value: reason.partnerName,
          valid: true
        },
        partnerSsn: {
          label: 'Personnummer',
          key: 'partnerSsn',
          value: reason.partnerSsn,
          valid: validatePersonalNumber(reason.partnerSsn)
        },
        housingType: {
          label: 'Boendeform',
          key: 'housingType',
          value: reason.housingType,
          valid: true
        }
      };
      if (reason.housingType === 'hyresratt') {
        fields = {
          ...fields,
          nameOnContract: {
            label: 'Namn på hyreskontrakt',
            key: 'nameOnContract',
            value: reason.nameOnContract,
            valid: true
          },
          houseOwner: {
            label: 'Innehavare',
            key: 'houseOwner',
            value: reason.houseOwner,
            valid: true
          },
          ownerEmail: {
            label: 'E-post',
            key: 'ownerEmail',
            value: reason.ownerEmail,
            valid: validEmail(reason.ownerEmail)
          },
          ownerPhone: {
            label: 'Telefonnummer till innehavare',
            key: 'ownerPhone',
            value: reason.ownerPhone,
            valid: validateNumber(reason.ownerPhone)
          }
        };
      } else {
        fields = {
          ...fields,
          houseOwner: {
            label: 'Innehavare',
            key: 'houseOwner',
            value: reason.houseOwner,
            valid: true
          },
          ownerEmail: {
            label: 'E-post',
            key: 'ownerEmail',
            value: reason.ownerEmail,
            valid: validEmail(reason.ownerEmail)
          },
          ownerPhone: {
            label: 'Telefonnummer till innehavare',
            key: 'ownerPhone',
            value: reason.ownerPhone,
            valid: validateNumber(reason.ownerPhone)
          },
          secondHouseOwner: {
            label: 'Innehavare',
            key: 'secondHouseOwner',
            value: reason.secondHouseOwner,
            valid: true,
            optional: true
          },
          secondOwnerEmail: {
            label: 'E-post till innehavare',
            key: 'secondOwnerEmail',
            value: reason.secondOwnerEmail,
            valid: validEmail(reason.secondOwnerEmail),
            optional: true
          },
          secondOwnerPhone: {
            label: 'Telefonnummer till innehavare',
            key: 'secondOwnerPhone',
            value: reason.secondOwnerPhone,
            valid: validateNumber(reason.secondOwnerPhone),
            optional: true
          }
        };
      }
    }
    if (['studie', 'arbete', 'other'].includes(reason.type)) {
      fields = {
        ...fields,
        city: {
          label: 'Ort',
          key: 'city',
          value: reason.city,
          valid: true
        }
      };
    }

    if (reason.type === 'other') {
      fields = {
        ...fields,
        text: {
          label: 'Beskriv annan anledning',
          key: 'text',
          value: reason.text,
          valid: true
        }
      };
    }
    return fields;
  },
  REASON_VALIDATION(state, getters) {
    if (!getters.REASON) {
      return {};
    }
    return generateValidationField(getters.REASON);
  },
  DOCUMENTS_VALIDATION(state, getters, rootState) {
    if (!rootState.subletApplication?.application) {
      return {};
    }
    const { computedDocumentsGroups, documents } =
      rootState.subletApplication.application;
    if (
      !computedDocumentsGroups ||
      !Object.keys(computedDocumentsGroups).length
    ) {
      return {};
    }

    return Object.entries(computedDocumentsGroups).reduce((acc, [key]) => {
      const doc = documents.find(doc => doc.category === key);

      if (doc) {
        let status = 'COMPLETE';
        if (doc.status === 'REJECTED') {
          status = 'COMMENT';
        } else if (doc.status === 'APPROVED') {
          status = 'APPROVED';
        }
        acc[key] = status;
      } else {
        acc[key] = computedDocumentsGroups[key].required === 0 ? '' : 'EMPTY';
      }

      return acc;
    }, {});
  },
  summarized(state, getters, rootState) {
    if (!rootState.subletApplication?.application) {
      return {};
    }

    const {
      currentTenants,
      apartment,
      reason,
      secondaryTenants,
      computedDocumentsGroups
    } = rootState.subletApplication.application;
    const {
      PERSONAL_INFORMATION_VALIDATION,
      APARTMENT_VALIDATION,
      REASON_VALIDATION,
      DOCUMENTS_VALIDATION,
      SUBTENANT_INFORMATION_VALIDATION
    } = getters;

    return {
      PERSONAL_INFORMATION: summarizeStatuses([
        ...PERSONAL_INFORMATION_VALIDATION.flatMap((field, index) =>
          getAdminStatus(currentTenants, index)
            ? getAdminStatus(currentTenants, index)
            : Object.values(field)
        )
      ]),
      APARTMENT: getAdminStatus(apartment)
        ? getAdminStatus(apartment)
        : summarizeStatuses(Object.values(APARTMENT_VALIDATION)),
      REASON: getAdminStatus(reason)
        ? getAdminStatus(reason)
        : summarizeStatuses(Object.values(REASON_VALIDATION)),
      DOCUMENTS: summarizeStatuses(
        [
          ...Object.values(DOCUMENTS_VALIDATION),
          ...getAdminDocGroupStatuses(computedDocumentsGroups)
        ].filter(n => !!n)
      ),
      SUBTENANT_INFORMATION: summarizeStatuses([
        ...SUBTENANT_INFORMATION_VALIDATION.flatMap((field, index) =>
          getAdminStatus(secondaryTenants, index)
            ? getAdminStatus(secondaryTenants, index)
            : Object.values(field)
        )
      ])
    };
  },
  applicationComplete(state, getters, rootState) {
    if (!rootState.subletApplication?.application) {
      return {};
    }
    return Object.values(getters.summarized).every(status =>
      ['COMPLETE', 'APPROVED'].includes(status)
    );
  }
};
