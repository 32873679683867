<template>
  <SubletPage
    :names="application?.currentTenants?.name"
    title="Förhandsgranska"
  >
    <div class="data-summary-wrapper">
      <Summary
        title="Uppgifter"
        category="PERSONAL_INFORMATION"
        :has-empty-info-fields="
          ['EMPTY', 'INVALID', 'COMMENT'].includes(
            summarized.PERSONAL_INFORMATION
          )
        "
        :is-approved="summarized.PERSONAL_INFORMATION === 'APPROVED'"
        :reject-reason="rejectedCategory(currentTenants)"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody
          v-for="(fields, index) in PERSONAL_INFORMATION"
          :key="index"
          :sub-heading="index === 0 ? 'Dina uppgifter' : 'Medhyresgäst'"
        >
          <SummaryField
            v-for="field in fields"
            :key="field.label"
            :label="field.label"
            :status="PERSONAL_INFORMATION_VALIDATION[index][field.key]"
            >{{ field.value }}</SummaryField
          ></SummaryBody
        >
      </Summary>
      <Summary
        title="Lägenhet"
        category="APARTMENT"
        :has-empty-info-fields="
          ['EMPTY', 'INVALID', 'COMMENT'].includes(summarized.APARTMENT)
        "
        :is-approved="summarized.APARTMENT === 'APPROVED'"
        :reject-reason="rejectedCategory([apartment])"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Din lägenhet">
          <SummaryField
            v-for="field in Object.values(APARTMENT)"
            :key="field.label"
            :label="field.label"
            :status="APARTMENT_VALIDATION[field.key]"
            >{{ field.value }}</SummaryField
          >
        </SummaryBody>
      </Summary>
      <Summary
        title="Skäl"
        category="REASON"
        :has-empty-info-fields="
          ['EMPTY', 'INVALID', 'COMMENT'].includes(summarized.REASON)
        "
        :is-approved="summarized.REASON === 'APPROVED'"
        :reject-reason="rejectedCategory([reason])"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Ditt skäl">
          <SummaryField v-if="reason?.type" label="Valt skäl:">{{
            renderReasonTypeData
          }}</SummaryField>
          <p v-else>Ingen information angiven</p>
        </SummaryBody>
        <SummaryBody sub-heading="Datum för uthyrning">
          <div v-if="reason?.type">
            <SummaryField
              label="Önskar hyra ut fr.o.m:"
              :status="REASON_VALIDATION.rentFrom"
              >{{
                isValidDate(REASON.rentFrom.value)
                  ? new Date(REASON.rentFrom.value).toLocaleDateString('sv-SE')
                  : ''
              }}</SummaryField
            >
            <SummaryField
              label="Önskar hyra ut t.o.m:"
              :status="REASON_VALIDATION.rentTo"
            >
              {{
                isValidDate(reason.rentTo)
                  ? new Date(reason.rentTo).toLocaleDateString('sv-SE')
                  : ''
              }}</SummaryField
            >
          </div>
          <p v-else>Ingen information angiven</p>
        </SummaryBody>
        <div v-if="reason?.type === 'sambo'" class="more-info-wrapper">
          <SummaryBody sub-heading="Uppgifter på den du ska provbo med">
            <SummaryField
              label="Namn:"
              :status="REASON_VALIDATION.partnerName"
              >{{ reason.partnerName }}</SummaryField
            >
            <SummaryField
              label="Personnummer:"
              :status="REASON_VALIDATION.partnerSsn"
              >{{ reason.partnerSsn }}</SummaryField
            >
          </SummaryBody>
          <SummaryBody sub-heading="Adress under uthyrningstiden">
            <SummaryField label="Adress:" :status="REASON_VALIDATION.address">{{
              reason.address
            }}</SummaryField>
            <SummaryField
              label="Postnummer:"
              :status="REASON_VALIDATION.postalCode"
              >{{ reason.postalCode }}</SummaryField
            >
            <SummaryField label="Ort:" :status="REASON_VALIDATION.city">{{
              reason.city
            }}</SummaryField>
          </SummaryBody>
          <SummaryBody sub-heading="Boendeform">
            <SummaryField label="Boendeform:">{{
              renderHousingTypeData
            }}</SummaryField>
            <SummaryField
              v-if="reason?.housingType === 'hyresratt'"
              label="Namn på hyreskontrakt:"
              :status="
                reason.housingType === 'hyresratt'
                  ? REASON_VALIDATION.nameOnContract
                  : ''
              "
              >{{ reason.nameOnContract }}</SummaryField
            >
            <SummaryField
              :label="labelName"
              :status="REASON_VALIDATION.houseOwner"
              >{{ reason.houseOwner }}</SummaryField
            >
            <SummaryField
              :label="labelPhone"
              :status="REASON_VALIDATION.ownerPhone"
              >{{ reason.ownerPhone }}</SummaryField
            >
            <SummaryField
              :label="labelEmail"
              :status="REASON_VALIDATION.ownerEmail"
              >{{ reason.ownerEmail }}</SummaryField
            >
            <div v-if="secondHouseOwner">
              <SummaryField
                :label="labelName"
                :status="REASON_VALIDATION.secondHouseOwner"
                >{{ reason.secondHouseOwner }}</SummaryField
              >
              <SummaryField
                :label="labelPhone"
                :status="REASON_VALIDATION.secondOwnerPhone"
                >{{ reason.secondOwnerPhone }}</SummaryField
              >
              <SummaryField
                :label="labelEmail"
                :status="REASON_VALIDATION.secondOwnerEmail"
                >{{ reason.secondOwnerEmail }}</SummaryField
              >
            </div>
          </SummaryBody>
          <SummaryBody
            v-if="reason?.housingType !== 'hyresratt'"
            :sub-heading="subHeadingOwner"
          >
            <SummaryField :label="labelOwner">{{
              calcPropertyOwner
            }}</SummaryField>
          </SummaryBody>
        </div>
        <div
          v-if="reason?.type === 'arbete' || reason?.type === 'studie'"
          class="more-info-wrapper"
        >
          <SummaryBody sub-heading="Flytt utomlands">
            <SummaryField label="Jag kommer att bo utomlands:">{{
              calcMovingAbroad
            }}</SummaryField>
          </SummaryBody>
          <SummaryBody sub-heading="Ort">
            <SummaryField
              label="Ort under uthyrningstiden:"
              :status="REASON_VALIDATION.city"
              >{{ reason.city }}</SummaryField
            >
          </SummaryBody>
        </div>
        <div v-if="reason?.type === 'other'" class="more-info-wrapper">
          <SummaryBody sub-heading="Ort">
            <SummaryField
              label="Ort under uthyrningstiden:"
              :status="REASON_VALIDATION.city"
              >{{ reason.city }}</SummaryField
            >
          </SummaryBody>
          <SummaryBody sub-heading="Anledning">
            <SummaryField
              label="Beskriv annan anledning:"
              :status="REASON_VALIDATION.text"
              >{{ reason.text }}</SummaryField
            >
          </SummaryBody>
        </div>
        <div
          v-if="reason?.type === 'utlandsvistelse'"
          class="more-info-wrapper"
        >
          <SummaryBody sub-heading="Ort">
            <SummaryField
              label="Ort under uthyrningstiden:"
              :status="REASON_VALIDATION.city"
              >{{ reason.city }}</SummaryField
            >
          </SummaryBody>
          <SummaryBody v-if="reason?.text" sub-heading="Övrigt">
            <SummaryField label="Övrig information:">{{
              reason.text
            }}</SummaryField>
          </SummaryBody>
        </div>
      </Summary>
      <Summary
        title="Dokument"
        category="DOCUMENTS"
        class="documents-view-wrapper"
        :has-empty-info-fields="
          ['EMPTY', 'INVALID', 'INCOMPLETE', 'COMMENT'].includes(
            summarized.DOCUMENTS
          )
        "
        :is-approved="summarized.DOCUMENTS === 'APPROVED'"
        :reject-reason="rejectedDocumentCategoryReason"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody sub-heading="Uppladdade filer">
          <SubletDocumentsGrid
            v-if="documentsForSelectedReason.length > 0"
            :documents="documentsForSelectedReason"
            :application-id="application.id"
          />
          <p v-else>Inga uppladdade filer</p>
        </SummaryBody>
      </Summary>
      <Summary
        title="Andrahandshyresgäst"
        category="SUBTENANT_INFORMATION"
        :has-empty-info-fields="
          ['EMPTY', 'INVALID', 'COMMENT'].includes(
            summarized.SUBTENANT_INFORMATION
          )
        "
        :is-approved="summarized.SUBTENANT_INFORMATION === 'APPROVED'"
        :reject-reason="rejectedCategory(secondaryTenants)"
        :application-is-editable="applicationIsEditable"
        @click="goToCategory"
      >
        <SummaryBody
          v-for="(fields, index) in SUBTENANT_INFORMATION"
          :key="index"
          :sub-heading="
            index === 0
              ? 'Din andrahandshyresgäst'
              : 'Andra personen på kontraktet'
          "
        >
          <SummaryField
            v-for="field in fields"
            :key="field.label"
            :label="field.label"
            :status="SUBTENANT_INFORMATION_VALIDATION[index][field.key]"
            >{{ field.value }}</SummaryField
          ></SummaryBody
        >
      </Summary>
    </div>
    <div class="info-container">
      <p>
        <i>
          Säkerställ att du har läst igenom
          <button
            class="info-button"
            type="button"
            @click="subletInformationModalVisible = true"
          >
            informationen om andrahandsuthyrning
          </button>
          innan du skickar in din ansökan
        </i>
      </p>
      <p>
        <br />
        <Checkbox
          v-if="applicationIsEditable && applicationComplete"
          v-model="checkedInsuranceReq"
          name="checkedInsuranceReq"
          description="Jag tar ansvar för att både jag och min hyresgäst har varsin hemförsäkring"
          :checked="checkedInsuranceReq"
          @change="checkedInsuranceReq = !checkedInsuranceReq"
        />
      </p>
      <InfoModal
        v-if="documentsInfoTextVisible && applicationIsEditable"
        class="documents-info-modal"
        title="Den här kategorin är låst"
        @click="documentsInfoTextVisible = false"
      >
        <p>
          För att kunna ladda upp filer behöver du ange ett <b>skäl</b> för
          andrahandsuthyrning.
        </p>
        <BaseButton
          :is-loading="false"
          :is-rounded="true"
          :is-fullwidth="isMobile"
          :is-small="isMobile"
          type="button"
          @click="closeModalAndRedirectToReason"
          ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
            Ange skäl
          </BaseIconText>
        </BaseButton>
      </InfoModal>
      <SubletInformationModal
        v-if="subletInformationModalVisible"
        :has-read-info="hasReadInfo"
        @closeInfoModal="subletInformationModalVisible = false"
      />
    </div>
    <BaseButton
      v-if="applicationIsEditable"
      :is-fullwidth="isMobile"
      :is-default="false"
      :is-rounded="true"
      :is-disabled="submitDisabled"
      type="button"
      class="next-category-button"
      @click="submitApplicationModalVisible = true"
      >Skicka in för granskning</BaseButton
    >
    <InfoModal
      v-if="submitApplicationModalVisible"
      title="Bekräfta skicka in för granskning"
      :mobile-full-width="isMobile"
      :show-close-btn="false"
      confirm-label="Jag vill skicka in ansökan"
      abort-label="Avbryt"
      @click="confirmModalClick"
    >
      <p>
        Efter att du skickat in din ansökan kommer du inte att kunna göra några
        ändringar. Är du säker på att du vill du skicka in ansökan?
      </p></InfoModal
    >
  </SubletPage>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InfoModal from '@/components/InfoModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import SubletPage from '@/components/SubletPage.vue';
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import SummaryField from '@/components/summary/SummaryField.vue';
import svgs from '@/utils/icon-constants';
import SubletInformationModal from '@/components/SubletInformationModal.vue';
import SubletDocumentsGrid from '@/components/SubletDocumentsGrid.vue';
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'AhDataSummary',
  components: {
    InfoModal,
    BaseButton,
    SubletPage,
    BaseIconText,
    Summary,
    SummaryBody,
    SummaryField,
    SubletInformationModal,
    SubletDocumentsGrid,
    Checkbox
  },
  props: {
    application: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      submitApplicationModalVisible: false,
      subletInformationModalVisible: false,
      downloadingId: null,
      completedStatuses: ['COMPLETE', 'APPROVED'],
      documentsInfoTextVisible: false,
      svgs,
      checkedInsuranceReq: false
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable',
      hasReadInfo: 'subletApplication/hasReadInfo',
      rejectedCategories: 'subletApplication/rejectedCategories',
      requiredDocuments: 'subletApplication/requiredDocuments',
      noneRequiredDocuments: 'subletApplication/noneRequiredDocuments',
      applicationComplete: 'ahValidation/applicationComplete',
      sideMenu: 'subletApplication/sideMenu',
      summarized: 'ahValidation/summarized',
      PERSONAL_INFORMATION: 'ahValidation/PERSONAL_INFORMATION',
      PERSONAL_INFORMATION_VALIDATION:
        'ahValidation/PERSONAL_INFORMATION_VALIDATION',
      SUBTENANT_INFORMATION: 'ahValidation/SUBTENANT_INFORMATION',
      SUBTENANT_INFORMATION_VALIDATION:
        'ahValidation/SUBTENANT_INFORMATION_VALIDATION',
      APARTMENT: 'ahValidation/APARTMENT',
      APARTMENT_VALIDATION: 'ahValidation/APARTMENT_VALIDATION',
      REASON: 'ahValidation/REASON',
      REASON_VALIDATION: 'ahValidation/REASON_VALIDATION',
      DOCUMENTS_VALIDATION: 'ahValidation/DOCUMENTS_VALIDATION'
    }),
    submitDisabled() {
      if (!this.checkedInsuranceReq) {
        return true;
      }
      return !this.applicationComplete;
    },
    currentTenants() {
      return this.application?.currentTenants;
    },
    secondaryTenants() {
      return this.application?.secondaryTenants;
    },
    apartment() {
      return this.application?.apartment;
    },
    reason() {
      return this.application?.reason;
    },
    documents() {
      return this.application?.documents || [];
    },
    computedDocumentsGroups() {
      return this.application?.computedDocumentsGroups || {};
    },
    secondHouseOwner() {
      return this.application?.reason?.secondHouseOwner;
    },
    renderReasonTypeData() {
      if (!this.reason) {
        return null;
      }
      switch (this.reason.type) {
        case 'sambo':
          return 'Provsambo';
        case 'arbete':
          return 'Arbete på annan ort eller utomlands';
        case 'studie':
          return 'Studera på annan ort eller utomlands';
        case 'other':
          return 'Annan anledning';
        case 'utlandsvistelse':
          return 'Utlandsvistelse';
        default:
          return '';
      }
    },
    renderHousingTypeData() {
      if (!this.reason) {
        return null;
      }

      if (this.reason.housingType === 'hyresratt') {
        return 'Hyresrätt';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Bostadsrätt';
      } else {
        return 'Villa';
      }
    },

    documentsForSelectedReason() {
      let documentsToShow = [
        ...this.requiredDocuments,
        ...this.noneRequiredDocuments
      ];

      const filteredDocuments = this.documents.filter(doc =>
        documentsToShow.includes(doc.category)
      );

      return filteredDocuments;
    },
    calcMovingAbroad() {
      if (this.reason?.movingAbroad) {
        return 'Ja';
      } else {
        return 'Nej';
      }
    },
    calcPropertyOwner() {
      if (this.reason?.propertyOwner) {
        return 'Ja';
      } else {
        return 'Nej';
      }
    },

    changeBtnDisabled() {
      if (!this.application?.reason?.type) {
        return true;
      } else return false;
    },
    labelName() {
      if (this.reason.housingType === 'hyresratt') {
        return 'Hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Innehavare:';
      } else {
        return 'Ägare:';
      }
    },
    labelPhone() {
      if (this.reason.housingType === 'hyresratt') {
        return 'Telefonnummer till hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Telefonnummer till innehavare:';
      } else {
        return 'Telefonnummer till ägare:';
      }
    },
    labelEmail() {
      if (this.reason.housingType === 'hyresratt') {
        return 'E-post till hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'E-post till innehavare:';
      } else {
        return 'E-post till ägare:';
      }
    },
    subHeadingOwner() {
      if (this.reason.housingType === 'bostadsratt') {
        return 'Delägare / innehavare till boendet';
      } else if (this.reason.housingType === 'villa') {
        return 'Delägare / ägare till boendet';
      } else {
        return '';
      }
    },
    labelOwner() {
      if (this.reason.housingType === 'bostadsratt') {
        return 'Jag är delägare / innehavare:';
      } else if (this.reason.housingType === 'villa') {
        return 'Jag är delägare / ägare:';
      } else {
        return '';
      }
    },
    rejectedDocumentCategoryReason() {
      let categoriesInDocumentsGroups = Object.keys(
        this.computedDocumentsGroups
      );

      const rejectedDocuments = this.documents.filter(
        doc => doc.status === 'REJECTED' && doc.rejectReason
      );

      const rejectedDocumentsGroupsCategory =
        categoriesInDocumentsGroups.filter(
          category =>
            this.computedDocumentsGroups[category].status === 'REJECTED' &&
            this.computedDocumentsGroups[category].rejectReason
        );

      if (
        rejectedDocuments.length === 1 &&
        !rejectedDocumentsGroupsCategory.length
      ) {
        return rejectedDocuments[0].rejectReason;
      } else if (
        rejectedDocumentsGroupsCategory.length === 1 &&
        !rejectedDocuments.length
      ) {
        return this.computedDocumentsGroups[rejectedDocumentsGroupsCategory]
          .rejectReason;
      } else if (
        rejectedDocuments.length > 0 ||
        rejectedDocumentsGroupsCategory.length > 0
      ) {
        return 'Det finns flera kommentarer för den här kategorin. Gå till sidan "Ladda upp filer" för att läsa alla.';
      }
      return '';
    }
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'subletApplication/goToNextCategory',
      submitApplication: 'subletApplication/submitApplication'
    }),
    closeModalAndGoToStatusPage() {
      this.submitApplicationModalVisible = false;
      this.goToNextCategory(this.$router);
    },
    async confirmModalClick(eventType) {
      this.submitApplicationModalVisible = true;

      switch (eventType) {
        case 'CONFIRM':
          await this.submitApplication(this.application.id);
          this.closeModalAndGoToStatusPage();

          break;
        case 'REJECT':
          this.submitApplicationModalVisible = false;
          break;
        default:
          this.submitApplicationModalVisible = false;
      }
    },
    async goToCategory(category) {
      const currentCategory = category;

      const categoryToChange = this.sideMenu.find(
        item => item.statusName === currentCategory
      );

      if (
        categoryToChange.statusName === 'DOCUMENTS' &&
        !this.application?.reason?.type
      ) {
        this.documentsInfoTextVisible = true;
      } else if (categoryToChange && categoryToChange.url) {
        await this.$router.push({
          path: categoryToChange.url
        });
      }
    },
    isValidDate(date) {
      const validDate = new Date(date);
      return !!date && validDate instanceof Date && !isNaN(validDate);
    },
    rejectedCategory(categories) {
      const rejectedCategory = categories.find(
        category => category.status === 'REJECTED' && category.rejectReason
      );

      if (rejectedCategory) {
        return rejectedCategory.rejectReason;
      } else {
        return '';
      }
    },

    closeModalAndRedirectToReason() {
      this.documentsInfoTextVisible = false;

      this.$router.push('/skal');
    }
  }
};
</script>

<style lang="scss" scoped>
.data-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  margin-bottom: 4.8rem;
  .more-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }
  }
}

.info-container {
  font-size: 1.5rem;
  margin-bottom: 2.8rem;

  .info-button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
