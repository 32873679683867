<template>
  <div class="field-wrapper">
    <div v-if="label || description" class="label">
      <span class="label-text">
        <span class="title">{{ label }}</span>
        <span class="description" v-html="description"></span>
      </span>
      <slot />
    </div>
    <div :class="['input-wrapper', inputStatusClass, isDirty ? 'dirty' : '']">
      <textarea
        v-if="$attrs.type === 'textarea'"
        v-bind="$attrs"
        :value="strValue"
        :name="label"
        :disabled="$attrs.disabled"
        :class="{
          focused: isFocused
        }"
        @input="$emit('input', $event.target.value)"
        @keyup="handleKeyUp"
      ></textarea>
      <input
        v-else
        v-bind="$attrs"
        :name="label"
        :type="$attrs.type || 'text'"
        :value="strValue"
        :disabled="$attrs.disabled"
        :class="{
          focused: isFocused
        }"
        @blur="$emit('blur', $event)"
        @input="$emit('input', $event.target.value)"
        @keyup="handleKeyUp"
        @keyup.enter="$emit('enter', $event)"
      />
      <div v-if="isLoading" class="input-loader"><BaseLoadingSpinner /></div>
      <div ref="dropdownEl" class="dropdown">
        <ul>
          <li v-for="item in dropdown" :key="item.id">
            <button
              type="button"
              @mousedown="$emit('dropdownClick', item.value)"
            >
              <span class="button-text" v-html="item.label"></span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <p v-if="inputStatusClass === 'inValid'" class="input-error">
      {{ errorText }}
    </p>

    <p v-if="inputStatusClass === 'warning'" class="input-warning">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="#409fff"
        class="svg-circle"
        viewBox="0 0 16 16"
      >
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
        />
      </svg>
      {{ errorText }}
    </p>
  </div>
</template>

<script>
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

export default {
  name: 'Input',
  components: {
    BaseLoadingSpinner
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    showGreenCheckmark: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null,
      required: false
    },
    description: {
      type: String,
      default: null,
      required: false
    },
    validation: {
      required: false,
      type: Function,
      default(val) {
        return val?.length > 0;
      }
    },
    errorText: {
      type: String,
      default: 'Detta fält måste fyllas i korrekt',
      required: false
    },
    dropdown: {
      type: Array,
      default: null,
      required: false
    },
    noValidate: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocused: false,
      isDirty: false
    };
  },
  computed: {
    strValue() {
      // Autoconverts numbers to strs, use this instead of value prop
      // since .length checks wont work on numbers and validation fails
      if (this.value === null) {
        return '';
      }
      return `${this.value}`;
    },
    inputStatusClass() {
      if (this.noValidate) {
        return '';
      }
      if (this.isWarning && !this.validation(this.strValue)) {
        return 'warning';
      }
      if (this.strValue?.length && !this.validation(this.strValue)) {
        return 'inValid';
      }

      if (
        this.strValue?.length &&
        this.validation(this.strValue) &&
        this.showGreenCheckmark
      ) {
        return 'valid';
      }
      return '';
    }
  },
  watch: {
    dropdown(oldDropdown, newDropdown) {
      if (oldDropdown?.length !== newDropdown?.length) {
        if (this.$refs.dropdownEl) {
          this.$refs.dropdownEl.scrollTo({ top: 0, behavior: 'instant' });
        }
      }
    }
  },
  methods: {
    handleKeyUp() {
      this.isDirty = true;
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.label {
  line-height: 1.5;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  color: var(--color-dark);
}

.label-text {
  flex-grow: 1;
  word-break: break-word;
}

.field-wrapper {
  width: 100%;
  margin-bottom: 4.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 1.6rem;
  display: block;
}

.description {
  display: block;
  font-weight: 400;
  font-size: 1.2rem;
}

.input-wrapper {
  margin-top: auto;
  position: relative;
  border-radius: 10px;
  width: 100%;
  color: var(--color-dark);
  font-size: 1.6rem;
  line-height: 1.5;

  @include mobile {
    width: 100%;
  }

  &.valid {
    &:after {
      content: '';
      height: 3rem;
      width: 2rem;
      right: 1.2rem;
      top: 0.7rem;
      position: absolute;
      background: url('../assets/svgs/icons/icon-check-mark-green.svg') 0% 0% /
        cover;
      pointer-events: none;
    }
  }

  &.inValid {
    input,
    textarea {
      border-color: var(--color-red);
    }
  }

  &.warning {
    input,
    textarea {
      border-color: var(--color-blue);
    }
  }
}

input,
textarea {
  border-radius: 10px;
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  position: relative;
  outline: 0px;
  appearance: none;
  width: 100%;
  padding: 0.8rem 2rem;
  border: 2px solid transparent;
  background-color: var(--color-light-gray);
  &[type='date'] {
    padding-right: 4rem;
  }
  &:focus {
    background-color: #fff;
    border-color: var(--color-blue);
    ~ .dropdown {
      top: 100%;
      opacity: 1;
      visibility: visible;
      transition:
        height 0.3s ease,
        opacity 0.3s ease,
        visibility 0.3s ease;
    }
  }
  &[disabled] {
    cursor: not-allowed;
    color: var(--color-disabled);
  }
}

/* Fixes iOS input[type="date"] height collapsing */
input::-webkit-date-and-time-value {
  height: 1.5em;
}

textarea {
  min-height: 15rem;
  resize: none;
}

p.input-error {
  margin-top: 0.3rem;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--color-red);
  position: absolute;
  font-weight: 400;
  top: 100%;
  left: 0;
  @include mobile {
    position: static;
  }
}
.input-warning {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--color-blue);
  position: absolute;
  top: 100%;
  left: 0;
  > svg {
    width: 1.8rem;
    height: 1.8rem;
    flex-shrink: 0;
  }
}
.svg-circle {
  margin-right: 5px;
}
.dropdown {
  z-index: 2;
  background-color: #fff;
  top: -99999px;
  left: 0;
  width: 100%;
  position: absolute;
  max-height: 20rem;
  overflow: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 15px 20px rgb(0 0 0 / 15%);
  opacity: 0;
  visibility: hidden;
  transition:
    height 0.3s ease,
    opacity 0.3s ease,
    visibility 0.3s ease,
    top 0s 0.3s;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    color: var(--color-dark);
    text-align: left;
    padding: 1.2rem 2rem;
    cursor: pointer;
    display: block;
    border: 0;
    box-shadow: none;
    width: 100%;
    background-color: transparent;
    font-weight: 400;
    transition: 0.3s ease;
    .button-text {
      transition: inherit;
      display: block;
    }
    strong {
      font-weight: 800;
    }
    &:hover,
    &:focus {
      background-color: #e9efff;
      outline: none;
      .button-text {
        transform: translateX(5px);
      }
    }
  }
}

::placeholder {
  color: var(--color-gray);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--color-gray);
}

::-ms-input-placeholder {
  color: var(--color-gray);
}

input[disabled]::placeholder {
  color: var(--color-disabled);
}
input[disabled] ::-ms-input-placeholder {
  color: var(--color-disabled);
}
input[disabled] :-ms-input-placeholder {
  color: var(--color-disabled);
}

/* placeholder date specific */
input[type='date']::-webkit-datetime-edit-text,
input[type='date']::-webkit-datetime-edit-month-field,
input[type='date']::-webkit-datetime-edit-day-field,
input[type='date']::-webkit-datetime-edit-year-field {
  color: var(--color-gray);
  .valid &,
  .inValid &,
  .warning &,
  .dirty & {
    color: inherit;
  }
}

/* date highlight */
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: var(--color-blue) !important;
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input-loader {
  position: absolute;
  right: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
}
</style>
