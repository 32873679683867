<template>
  <SubletPage
    :names="application?.currentTenants?.name"
    title="Förhandsgranska"
  >
    <div class="summary-page">
      <div class="data-summary-wrapper">
        <CompanySummary
          v-if="!isPrivateFlow"
          :company-details="activeCurrentCompanyDetails"
        />
        <Summary
          :show-change-btn="isOwner"
          :title="transferorTitle"
          category="PERSONAL_INFORMATION"
          :has-empty-info-fields="
            ['EMPTY', 'INVALID', 'COMMENT'].includes(
              summarized.PERSONAL_INFORMATION
            )
          "
          :is-approved="summarized.PERSONAL_INFORMATION === 'APPROVED'"
          :reject-reason="rejectedCategory(currentTenants)"
          :application-is-editable="applicationIsEditable"
          @click="goToCategory"
        >
          <SummaryBody
            v-for="(fields, title) in currentTenantsFields"
            :key="title"
            :sub-heading="title"
          >
            <SummaryField
              v-for="field in fields"
              :key="field.label"
              :label="field.label"
              :status="field.status"
              >{{ field.value }}</SummaryField
            >
          </SummaryBody>
        </Summary>
        <Summary
          :show-change-btn="isOwner"
          :title="apartmentTitle"
          category="APARTMENT"
          :has-empty-info-fields="
            ['EMPTY', 'INVALID', 'COMMENT'].includes(summarized.APARTMENT)
          "
          :is-approved="summarized.APARTMENT === 'APPROVED'"
          :reject-reason="rejectedCategory([apartment])"
          :application-is-editable="applicationIsEditable"
          @click="goToCategory"
        >
          <SummaryBody :sub-heading="isPrivateFlow ? 'Lägenheten' : 'Lokalen'">
            <SummaryField
              v-for="apartmentField in apartmentFields"
              :key="apartmentField.label"
              :label="apartmentField.label"
              :status="apartmentField.status"
              >{{ apartmentField.value }}</SummaryField
            >
            <template v-for="(items, label) in ovObjects">
              <template v-for="(ovObject, i) in items">
                <SummaryField
                  :key="`${ovObject.address}-${ovObject.objectNumber}`"
                  :label="`${label} ${i + 1}:`"
                  >{{ ovObject.address }} ({{
                    ovObject.objectNumber
                  }})</SummaryField
                >
              </template>
            </template>
          </SummaryBody>
        </Summary>
        <Summary
          :show-change-btn="isOwner"
          title="Skäl"
          category="REASON"
          :has-empty-info-fields="
            ['EMPTY', 'INVALID', 'COMMENT'].includes(summarized.REASON)
          "
          :is-approved="summarized.REASON === 'APPROVED'"
          :reject-reason="rejectedCategory([reason])"
          :application-is-editable="applicationIsEditable"
          @click="goToCategory"
        >
          <SummaryBody sub-heading="Skäl">
            <SummaryField
              v-if="isPrivateFlow"
              label="Angivet skäl:"
              :status="REASON.type"
              >{{ renderReasonTypeData }}</SummaryField
            >
            <SummaryField
              label="Önskat överlåtelsedatum:"
              :status="REASON.transferDate"
              ><template v-if="reason?.transferDate">{{
                isValidDate(reason.transferDate)
                  ? new Date(reason.transferDate).toLocaleDateString('sv-SE')
                  : ''
              }}</template></SummaryField
            >
            <SummaryField
              v-if="!isPrivateFlow"
              label="Ska hela verksamheten ska överlåtas:"
              :status="REASON.isFullTransfer"
              >{{ reason?.isFullTransfer ? 'Ja' : 'Nej' }}</SummaryField
            >
            <SummaryField
              v-if="isPrivateFlow"
              label="Relation till tilltänkta hyresgästen"
              :status="REASON.relationship"
              ><template v-if="reason?.relationship">{{
                reason.relationship
              }}</template>
            </SummaryField>
          </SummaryBody>
          <SummaryBody
            :sub-heading="
              isPrivateFlow
                ? 'Verksamheten som ska bedrivas i lokalen'
                : 'Skäl för överlåtelsen & verksamhet som ska bedrivas i lokalen'
            "
          >
            <SummaryField
              :label="
                isPrivateFlow
                  ? 'Beskriv kortfattat situationen:'
                  : 'Verksamheten som ska bedrivas:'
              "
              :status="REASON.text"
              ><template v-if="reason?.text">{{
                reason.text
              }}</template></SummaryField
            >
          </SummaryBody>
        </Summary>
        <Summary
          :show-change-btn="isOwner"
          :title="
            currentTenants && currentTenants[0].name
              ? `${getGenitive(currentTenants[0].name)} dokument`
              : 'Överlåtarens dokument'
          "
          category="OWNER_DOCUMENTS"
          class="documents-view-wrapper"
          :has-empty-info-fields="
            ['EMPTY', 'INVALID', 'COMMENT'].includes(summarized.OWNER_DOCUMENTS)
          "
          :is-approved="summarized.OWNER_DOCUMENTS === 'APPROVED'"
          :reject-reason="rejectedDocumentCategoryReason('transferor')"
          :application-is-editable="applicationIsEditable"
          @click="goToCategory"
        >
          <SummaryBody sub-heading="Uppladdade filer">
            <SubletDocumentsGrid
              v-if="transferorDocumentsForSelectedReason.length > 0"
              :documents="transferorDocumentsForSelectedReason"
              :application-id="application.id"
            />
            <p v-else>Inga uppladdade filer</p>
          </SummaryBody>
        </Summary>
        <CompanySummary
          v-if="!isPrivateFlow"
          :company-details="activeSecondaryCompanyDetails"
        />
        <Summary
          :show-change-btn="!hasInvitee || !isOwner"
          :title="transfereeTitle"
          category="SUBTENANT_INFORMATION"
          :has-empty-info-fields="
            ['EMPTY', 'INVALID', 'COMMENT'].includes(
              summarized.SUBTENANT_INFORMATION
            )
          "
          :is-approved="summarized.SUBTENANT_INFORMATION === 'APPROVED'"
          :reject-reason="rejectedCategory(secondaryTenants)"
          :application-is-editable="applicationIsEditable"
          @click="goToCategory"
        >
          <SummaryBody
            v-for="(fields, title) in secondaryTenantsFields"
            :key="title"
            :sub-heading="title"
          >
            <SummaryField
              v-for="field in fields"
              :key="field.label"
              :label="field.label"
              :status="field.status"
              >{{ field.value }}</SummaryField
            >
          </SummaryBody>
        </Summary>
        <Summary
          :show-change-btn="!hasInvitee || !isOwner"
          :title="
            secondaryTenants && secondaryTenants[0].name
              ? `${getGenitive(secondaryTenants[0].name)} dokument`
              : 'Övertagarens dokument'
          "
          category="INVITEE_DOCUMENTS"
          class="documents-view-wrapper"
          :has-empty-info-fields="
            ['EMPTY', 'INVALID', 'COMMENT'].includes(
              summarized.INVITEE_DOCUMENTS
            )
          "
          :is-approved="summarized.INVITEE_DOCUMENTS === 'APPROVED'"
          :reject-reason="rejectedDocumentCategoryReason('transferee')"
          :application-is-editable="applicationIsEditable"
          @click="goToCategory"
        >
          <SummaryBody sub-heading="Uppladdade filer">
            <SubletDocumentsGrid
              v-if="transfereeDocumentsForSelectedReason.length > 0"
              :documents="transfereeDocumentsForSelectedReason"
              :application-id="application.id"
            />
            <p v-else>Inga uppladdade filer</p>
          </SummaryBody>
        </Summary>
      </div>
      <template v-if="isOwner">
        <div class="info-container">
          <p>
            <i>
              Säkerställ att du har läst igenom
              <button
                class="info-button"
                type="button"
                @click="ovInformationModalVisible = true"
              >
                informationen om överlåtelser
              </button>
              innan du skickar in din ansökan
            </i>
          </p>
          <p>
            <br />
            <Checkbox
              v-if="applicationIsEditable && applicationComplete"
              v-model="checkedInsuranceReq"
              name="checkedInsuranceReq"
              description="Jag godkänner informationen om överlåtelser"
              :checked="checkedInsuranceReq"
              @change="checkedInsuranceReq = !checkedInsuranceReq"
            />
          </p>
          <InfoModal
            v-if="documentsInfoTextVisible && applicationIsEditable"
            class="documents-info-modal"
            title="Den här kategorin är låst"
            @click="documentsInfoTextVisible = false"
          >
            <p>
              För att kunna ladda upp filer behöver du ange ett
              <b>skäl</b> för andrahandsuthyrning.
            </p>
            <BaseButton
              :is-loading="false"
              :is-rounded="true"
              :is-fullwidth="isMobile"
              :is-small="isMobile"
              type="button"
              @click="closeModalAndRedirectToReason"
              ><BaseIconText
                :icon-url="svgs.ICONS.ARROW_RIGHT"
                :align-right="true"
              >
                Ange skäl
              </BaseIconText>
            </BaseButton>
          </InfoModal>
          <OvInformationModal
            v-if="ovInformationModalVisible"
            :has-read-info="hasReadInfo"
            @closeInfoModal="ovInformationModalVisible = false"
          />
        </div>

        <BaseButton
          v-if="applicationIsEditable"
          :is-fullwidth="isMobile"
          :is-default="false"
          :is-rounded="true"
          :is-disabled="
            submitDisabled || (hasInvitee && !application?.inviteeStatus)
          "
          type="button"
          class="next-category-button"
          @click="submitApplicationModalVisible = true"
          >Skicka in för granskning</BaseButton
        >
        <p
          v-if="hasInvitee && !application?.inviteeStatus"
          style="padding: 1rem"
        >
          <i
            >Den inbjudna parten är inte klar med sin del. Så fort denne är redo
            kan du skicka in ansökan.</i
          >
        </p>
        <InfoModal
          v-if="submitApplicationModalVisible"
          title="Bekräfta skicka in för granskning"
          :mobile-full-width="isMobile"
          :show-close-btn="false"
          confirm-label="Jag vill skicka in ansökan"
          abort-label="Avbryt"
          @click="confirmModalClick"
        >
          <p>
            Efter att du skickat in din ansökan kommer du inte att kunna göra
            några ändringar. Är du säker på att du vill du skicka in ansökan?
          </p></InfoModal
        >
      </template>
      <template v-else>
        <BaseButton
          v-if="application?.inviteeStatus !== 'TREATING'"
          :is-fullwidth="isMobile"
          :is-default="false"
          :is-rounded="true"
          :is-disabled="!applicationIsEditable"
          type="button"
          class="next-category-button"
          @click="inviteeDoneModalVisible = true"
          >Jag är klar med min del {{ application?.inviteeStatus }}</BaseButton
        >
        <BaseButton
          v-else
          :is-fullwidth="isMobile"
          :is-default="false"
          :is-rounded="true"
          :is-disabled="true"
          type="button"
          class="next-category-button"
          >Markerad som klar</BaseButton
        >
        <InfoModal
          v-if="inviteeDoneModalVisible"
          title="Allt klart?"
          :mobile-full-width="isMobile"
          :show-close-btn="false"
          confirm-label="Jag är klar!"
          abort-label="Avbryt"
          @click="confirmIsDoneClick"
        >
          <p>
            Efter att du markerat dig som klar så kommer överlåtaren kunna
            skicka in ansökan. När ansökan är inskickad så kommer du inte att
            kunna göra några ändringar. Är du säker på att du vill du är klar?
          </p></InfoModal
        >
      </template>
    </div>
  </SubletPage>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getGenitive } from '@/utils/genitive';
import { getFirstWord } from '@/utils/common';
import { validateOrganizationNumber } from '@/utils/input-validations';
import InfoModal from '@/components/InfoModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import SubletPage from '@/components/SubletPage.vue';
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import SummaryField from '@/components/summary/SummaryField.vue';
import svgs from '@/utils/icon-constants';
import OvInformationModal from '@/components/OvInformationModal.vue';
import SubletDocumentsGrid from '@/components/SubletDocumentsGrid.vue';
import Checkbox from '@/components/Checkbox.vue';
import CompanySummary from './CompanySummary.vue';

export default {
  name: 'DataSummary',
  components: {
    InfoModal,
    BaseButton,
    SubletPage,
    BaseIconText,
    Summary,
    SummaryBody,
    SummaryField,
    OvInformationModal,
    SubletDocumentsGrid,
    Checkbox,
    CompanySummary
  },
  data() {
    return {
      submitApplicationModalVisible: false,
      ovInformationModalVisible: false,
      downloadingId: null,
      completedStatuses: ['COMPLETE', 'APPROVED'],
      documentsInfoTextVisible: false,
      svgs,
      checkedInsuranceReq: false,
      inviteeDoneModalVisible: false,
      getGenitive,
      getFirstWord,
      validateOrganizationNumber
    };
  },
  computed: {
    ...mapState({
      application: state => state.ovApplication.application,
      companies: state => state.companies.companies
    }),
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'ovApplication/applicationIsEditable',
      hasReadInfo: 'ovApplication/hasReadInfo',
      rejectedCategories: 'ovApplication/rejectedCategories',
      requiredDocuments: 'ovApplication/requiredDocuments',
      requiredInviteeDocuments: 'ovApplication/requiredInviteeDocuments',
      noneRequiredDocuments: 'ovApplication/noneRequiredDocuments',
      noneInviteeRequiredDocuments:
        'ovApplication/noneInviteeRequiredDocuments',
      getFieldsWithErrors: 'ovApplication/getFieldsWithErrors',
      menu: 'ovApplication/menu',
      hasInvitee: 'ovApplication/hasInvitee',
      isOwner: 'ovApplication/isOwner',
      applicationComplete: 'ovValidation/applicationComplete',
      PERSONAL_INFORMATION: 'ovValidation/PERSONAL_INFORMATION',
      APARTMENT: 'ovValidation/APARTMENT',
      SUBTENANT_INFORMATION: 'ovValidation/SUBTENANT_INFORMATION',
      REASON: 'ovValidation/REASON',
      summarized: 'ovValidation/summarized',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),
    apartmentTitle() {
      if (!this.currentTenants) {
        return '';
      }
      if (!this.isPrivateFlow) {
        let name = getGenitive(this.currentTenants[0].name);
        if (this.activeCurrentCompanyDetails) {
          name = getGenitive(this.activeCurrentCompanyDetails.name);
        }
        return name ? `${name} lokal` : 'Överlåtarens lokal';
      }
      return this.currentTenants[0].name
        ? `${getGenitive(this.currentTenants[0].name)} lägenhet`
        : 'Överlåtarens lägenhet';
    },
    activeCurrentCompanyDetails() {
      if (
        !this.validateOrganizationNumber(
          this.currentTenants[0]?.organizationNumber
        )
      ) {
        return null;
      }
      return this.companies[
        this.currentTenants[0].organizationNumber.split('-').join('')
      ];
    },
    activeSecondaryCompanyDetails() {
      if (
        !this.validateOrganizationNumber(
          this.secondaryTenants[0]?.organizationNumber
        )
      ) {
        return null;
      }
      return this.companies[
        this.secondaryTenants[0].organizationNumber.split('-').join('')
      ];
    },
    currentTenantsFields() {
      if (!this.currentTenants?.length) {
        return [];
      }

      return this.currentTenants.reduce(
        (acc, curr, index) => ({
          ...acc,
          [curr?.name
            ? `${getGenitive(getFirstWord(curr.name))} uppgifter`
            : 'Överlåtarens uppgifter']: [
            {
              label: 'Namn:',
              status: this.PERSONAL_INFORMATION[index].name,
              value: curr.name
            },
            {
              label: 'E-post:',
              status: this.PERSONAL_INFORMATION[index].email,
              value: curr.email
            },
            {
              label: 'Telefonnummer:',
              status: this.PERSONAL_INFORMATION[index].phone,
              value: curr.phone
            }
          ]
        }),
        {}
      );
    },
    apartmentFields() {
      if (Object.keys(this.APARTMENT).length === 0) {
        return [];
      }
      const fields = [
        {
          label: 'Adress:',
          status: this.APARTMENT.address,
          value: this.apartment.address
        },
        {
          label: 'Postnummer:',
          status: this.APARTMENT.postalCode,
          value: this.apartment.postalCode
        },
        {
          label: 'Ort:',
          status: this.APARTMENT.city,
          value: this.apartment.city
        }
      ];
      if (this.isPrivateFlow) {
        fields.push(
          {
            label: 'Objekt/kontraktnummer:',
            status: this.APARTMENT.objectNumber,
            value: this.apartment.objectNumber
          },
          {
            label: 'Lägenhetsnummer:',
            status: this.APARTMENT.apartmentNumber,
            value: this.apartment.apartmentNumber
          },
          {
            label: 'Månadshyra:',
            status: this.APARTMENT.rent,
            value: this.apartment.rent
          }
        );
      } else {
        fields.push({
          label: 'Hyreskontraktnr.:',
          status: this.APARTMENT.contractNumber,
          value: this.apartment.contractNumber
        });
      }
      return fields;
    },
    ovObjects() {
      if (!this.apartment) {
        return {};
      }
      return {
        Parkering: this.apartment.parkings,
        Garage: this.apartment.garages,
        Förråd: this.apartment.storages
      };
    },
    secondaryTenantsFields() {
      if (!this.secondaryTenants?.length) {
        return [];
      }

      return this.secondaryTenants.reduce(
        (acc, curr, index) => ({
          ...acc,
          [curr?.name
            ? `${getGenitive(getFirstWord(curr.name))} uppgifter`
            : 'Övertagarens uppgifter']: [
            {
              label: 'Namn:',
              status: this.SUBTENANT_INFORMATION[index].name,
              value: curr.name
            },
            {
              label: 'E-post:',
              status: this.SUBTENANT_INFORMATION[index].email,
              value: curr.email
            },
            {
              label: 'Telefonnummer:',
              status: this.SUBTENANT_INFORMATION[index].phone,
              value: curr.phone
            },
            {
              label: 'Personnummer:',
              status: this.SUBTENANT_INFORMATION[index].ssn,
              value: curr.ssn
            },
            {
              label: 'Adress:',
              status: this.SUBTENANT_INFORMATION[index].address,
              value: curr.address
            },
            {
              label: 'Postnummer:',
              status: this.SUBTENANT_INFORMATION[index].postalCode,
              value: curr.postalCode
            },
            {
              label: 'Ort:',
              status: this.SUBTENANT_INFORMATION[index].city,
              value: curr.city
            }
          ]
        }),
        {}
      );
    },
    transferorTitle() {
      if (!this.isPrivateFlow) {
        return 'Behörig firmatecknare';
      }
      if (!this.currentTenants || !this.currentTenants[0]?.name) {
        return 'Överlåtaren';
      }
      if (this.currentTenants[0]?.name && !this.currentTenants[1]?.name) {
        return this.currentTenants[0].name;
      }
      if (this.currentTenants[0]?.name && this.currentTenants[1]?.name) {
        return `${this.currentTenants[0].name} & ${this.currentTenants[1].name}`;
      }
      return 'Överlåtaren';
    },
    transfereeTitle() {
      if (!this.secondaryTenants || !this.secondaryTenants[0]?.name) {
        return 'Övertagaren';
      }
      if (this.secondaryTenants[0]?.name && !this.secondaryTenants[1]?.name) {
        return this.secondaryTenants[0].name;
      }
      if (this.secondaryTenants[0]?.name && this.secondaryTenants[1]?.name) {
        return `${this.secondaryTenants[0].name} & ${this.secondaryTenants[1].name}`;
      }
      return 'Övertagaren';
    },
    submitDisabled() {
      if (!this.checkedInsuranceReq) {
        return true;
      }
      return !this.applicationComplete;
    },
    currentTenants() {
      return this.application?.currentTenants;
    },
    secondaryTenants() {
      return this.application?.secondaryTenants;
    },
    apartment() {
      return this.application?.apartment;
    },
    reason() {
      return this.application?.reason;
    },
    documents() {
      return this.application?.documents || [];
    },
    computedDocumentsGroups() {
      return this.application?.computedDocumentsGroups || {};
    },
    inviteeComputedDocumentsGroups() {
      return this.application?.inviteeComputedDocumentsGroups || {};
    },
    secondHouseOwner() {
      return this.application?.reason?.secondHouseOwner;
    },
    renderReasonTypeData() {
      if (!this.reason) {
        return null;
      }
      switch (this.reason.type) {
        case 'halsa':
          return 'Hälsoskäl';
        case 'separation':
          return 'Separation/skilsmässa';
        case 'dodsfall':
          return 'Dödsfall';
        case 'ekonomi':
          return 'Ekonomiska skäl';
        case 'aldreboende':
          return 'Flytt till äldreboende';
        case 'student':
          return 'Studentlägenhet';
        case 'annat':
          return 'Annat';
        default:
          return this.reason.type;
      }
    },
    renderHousingTypeData() {
      if (!this.reason) {
        return null;
      }

      if (this.reason.housingType === 'hyresratt') {
        return 'Hyresrätt';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Bostadsrätt';
      } else {
        return 'Villa';
      }
    },

    transferorDocumentsForSelectedReason() {
      return this.documents.filter(
        doc =>
          [...this.requiredDocuments, ...this.noneRequiredDocuments].includes(
            doc.category
          ) && doc.categoryType === 'transferor'
      );
    },
    transfereeDocumentsForSelectedReason() {
      return this.documents.filter(
        doc =>
          [
            ...this.requiredInviteeDocuments,
            ...this.noneInviteeRequiredDocuments
          ].includes(doc.category) && doc.categoryType === 'transferee'
      );
    },
    changeBtnDisabled() {
      if (!this.application?.reason?.type) {
        return true;
      } else return false;
    },
    labelName() {
      if (this.reason.housingType === 'hyresratt') {
        return 'Hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Innehavare:';
      } else {
        return 'Ägare:';
      }
    },
    labelPhone() {
      if (this.reason.housingType === 'hyresratt') {
        return 'Telefonnummer till hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'Telefonnummer till innehavare:';
      } else {
        return 'Telefonnummer till ägare:';
      }
    },
    labelEmail() {
      if (this.reason.housingType === 'hyresratt') {
        return 'E-post till hyresvärd:';
      } else if (this.reason.housingType === 'bostadsratt') {
        return 'E-post till innehavare:';
      } else {
        return 'E-post till ägare:';
      }
    },
    subHeadingOwner() {
      if (this.reason.housingType === 'bostadsratt') {
        return 'Delägare / innehavare till boendet';
      } else if (this.reason.housingType === 'villa') {
        return 'Delägare / ägare till boendet';
      } else {
        return '';
      }
    },
    labelOwner() {
      if (this.reason.housingType === 'bostadsratt') {
        return 'Jag är delägare / innehavare:';
      } else if (this.reason.housingType === 'villa') {
        return 'Jag är delägare / ägare:';
      } else {
        return '';
      }
    }
  },
  watch: {
    application: {
      handler(newApp) {
        if (newApp?.currentTenants) {
          if (this.isPrivateFlow) {
            return;
          }
          if (
            this.validateOrganizationNumber(
              this.currentTenants[0]?.organizationNumber
            )
          ) {
            this.fetchCompany(this.currentTenants[0].organizationNumber);
          }
          if (
            this.validateOrganizationNumber(
              this.secondaryTenants[0]?.organizationNumber
            )
          ) {
            this.fetchCompany(this.secondaryTenants[0].organizationNumber);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'ovApplication/goToNextCategory',
      submitApplication: 'ovApplication/submitApplication',
      submitInvitee: 'ovApplication/submitInvitee',
      fetchCompany: 'companies/fetchCompany'
    }),
    rejectedDocumentCategoryReason(categoryType) {
      const documentGroups =
        categoryType === 'transferor'
          ? this.computedDocumentsGroups
          : this.inviteeComputedDocumentsGroups;
      let categoriesInDocumentsGroups = Object.keys(documentGroups);

      const rejectedDocuments = this.documents.filter(
        doc =>
          doc.status === 'REJECTED' &&
          doc.rejectReason &&
          doc.categoryType === categoryType
      );

      const rejectedDocumentsGroupsCategory =
        categoriesInDocumentsGroups.filter(
          category =>
            documentGroups[category].status === 'REJECTED' &&
            documentGroups[category].rejectReason
        );

      if (
        rejectedDocuments.length === 1 &&
        !rejectedDocumentsGroupsCategory.length
      ) {
        return rejectedDocuments[0].rejectReason;
      } else if (
        rejectedDocumentsGroupsCategory.length === 1 &&
        !rejectedDocuments.length
      ) {
        return documentGroups[rejectedDocumentsGroupsCategory].rejectReason;
      } else if (
        rejectedDocuments.length > 0 ||
        rejectedDocumentsGroupsCategory.length > 0
      ) {
        return 'Det finns flera kommentarer för den här kategorin, klicka på "Ändra" för att se mer';
      }
      return '';
    },
    closeModalAndGoToStatusPage() {
      this.submitApplicationModalVisible = false;
      this.goToNextCategory(this.$router);
    },
    async confirmModalClick(eventType) {
      switch (eventType) {
        case 'CONFIRM':
          await this.submitApplication(this.application.id);
          this.closeModalAndGoToStatusPage();
          break;
        case 'REJECT':
          this.submitApplicationModalVisible = false;
          break;
        default:
          this.submitApplicationModalVisible = false;
      }
    },
    async confirmIsDoneClick(eventType) {
      if (eventType === 'CONFIRM') {
        await this.submitInvitee(this.application.id);
      }
      this.inviteeDoneModalVisible = false;
    },
    async goToCategory(category) {
      const currentCategory = category;

      const menuItem = this.menu.find(
        item => item.statusName === currentCategory
      );

      if (
        menuItem.statusName === 'OWNER_DOCUMENTS' &&
        !this.application?.reason?.type
      ) {
        this.documentsInfoTextVisible = true;
      } else if (menuItem?.url) {
        await this.$router.push({
          path: menuItem.url
        });
      }
    },
    blockDocumentsView(category) {
      const currentCategory = category;

      const categoryToChange = this.menu.find(
        item => item.statusName === currentCategory
      );

      if (categoryToChange.statusName === 'REASON') {
        if (this.isPrivateFlow) {
          return !this.reason.type;
        } else {
          return !this.reason.text;
        }
      }

      return false;
    },
    isValidDate(date) {
      const validDate = new Date(date);
      return !!date && validDate instanceof Date && !isNaN(validDate);
    },
    fieldHasError(category, index, fieldKey) {
      const errorFields = this.getFieldsWithErrors(category, index);
      return errorFields.includes(fieldKey);
    },
    rejectedCategory(categories) {
      if (!categories) {
        return '';
      }

      const rejectedCategory = categories.find(
        category => category?.status === 'REJECTED' && category?.rejectReason
      );

      if (rejectedCategory) {
        return rejectedCategory.rejectReason;
      }
      return '';
    },

    closeModalAndRedirectToReason() {
      this.documentsInfoTextVisible = false;

      this.$router.push('/skal');
    }
  }
};
</script>

<style lang="scss" scoped>
.data-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  margin-bottom: 4.8rem;
  .more-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }
  }
}

.info-container {
  font-size: 1.5rem;
  margin-bottom: 2.8rem;

  .info-button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.summary-page {
  padding-bottom: 8rem;
}
</style>
