<template>
  <div class="wrapper">
    <div
      ref="otpCont"
      :class="[
        'container',
        isLoading && 'container--loading',
        containerCountClass
      ]"
    >
      <div v-for="(el, ind) in digits" :key="el + ind" class="container-item">
        <input
          v-model="digits[ind]"
          type="text"
          :autofocus="ind === 0"
          :placeholder="onlyDigits ? '0' : ''"
          :disabled="isDisabled || forceDisable"
          maxlength="1"
          :inputmode="onlyDigits ? 'numeric' : 'text'"
          :pattern="onlyDigits ? '[0-9]*' : ''"
          @keydown="handleKeyDown($event, ind)"
          @paste="handleMousePaste"
        />
      </div>
    </div>
    <transition name="fade-in-out" appear>
      <div v-if="isLoading" class="spinner-container">
        <BaseLoadingSpinner color="#fff" />
      </div>
    </transition>
  </div>
</template>
<script>
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

export default {
  components: {
    BaseLoadingSpinner
  },
  props: {
    digitCount: {
      type: Number,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    onlyDigits: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      digits: [],
      forceDisable: false
    };
  },
  computed: {
    containerCountClass() {
      return `container-count-${this.digitCount}`;
    },
    digitsFull() {
      for (const digit of this.digits) {
        if (digit == null || digit == undefined) {
          return false;
        }
      }
      return true;
    }
  },
  mounted() {
    for (let i = 0; i < this.digitCount; i++) {
      this.$set(this.digits, i, null);
    }
  },
  methods: {
    resetInputs() {
      for (let i = 0; i < this.digits.length; i++) {
        this.$set(this.digits, i, null);
      }
    },
    isCtrlOrCmd(event) {
      const keyCode = event.which || event.keyCode;

      if (keyCode === 86 && (event.ctrlKey || event.metaKey)) {
        return true;
      }
      return false;
    },
    async readAndSetClipboard() {
      if (this.forceDisable) {
        return;
      }
      const text = await navigator.clipboard.readText();

      if (this.onlyDigits && !Number(text)) {
        return;
      }
      const charsArr = text.split(/(?!$)/u);

      //  Length matches digitCount
      if (charsArr.length === this.digitCount) {
        this.forceDisable = true;

        await Promise.all(
          charsArr.map(
            async (char, i) =>
              // Typewriter effect
              new Promise(resolve =>
                setTimeout(() => {
                  this.$set(this.digits, i, char);
                  resolve();
                }, i * 100)
              )
          )
        );
        this.forceDisable = false;
      }
    },
    async handleMousePaste() {
      await this.readAndSetClipboard();
      this.submitIfDone();
    },
    async handleKeyDown(event, index) {
      if (
        event.key !== 'Tab' &&
        event.key !== 'ArrowRight' &&
        event.key !== 'ArrowLeft'
      ) {
        event.preventDefault();
      }

      if (event.key === 'Backspace') {
        this.$set(this.digits, index, null);

        if (index !== 0) {
          this.$refs.otpCont
            .querySelector(`.container-item:nth-of-type(${index}) input`)
            .focus();
        }
        this.$emit('keydown');
        return;
      }

      //  Paste code directly
      if (this.isCtrlOrCmd(event)) {
        event.preventDefault();
        await this.readAndSetClipboard();
        this.submitIfDone();
        return;
      }

      const regEx = this.onlyDigits ? '^([0-9])$' : '^([0-9a-zA-Z])$';
      //  Regular input
      if (new RegExp(regEx).test(event.key)) {
        const uppercaseKey = event.key.toUpperCase(); // Transform to uppercase
        this.$set(this.digits, index, uppercaseKey);

        if (index !== this.digitCount - 1) {
          this.$refs.otpCont
            .querySelector(`.container-item:nth-of-type(${index + 2}) input`)
            .focus();
        }
        this.$emit('keydown');
      }
      this.submitIfDone();
    },
    async submitIfDone() {
      if (this.digitsFull) {
        // Wait a sec for user to witness all the numbers :)
        await new Promise(resolve => setTimeout(resolve, 200));

        const digitsStr = this.digits.join('');
        this.resetInputs();

        // Fixes strange transition glitch :/
        setTimeout(() => this.$emit('completed', digitsStr), 10);
      }
    }
  }
};
</script>
<style scoped lang="scss">
$transition: 0.5s;

.wrapper {
  position: relative;
}
.container {
  display: inline-flex;
  gap: 5px;
  transition: $transition;
  position: relative;
  height: 6rem;
  justify-content: center;
}
.container-item {
  width: 6rem;
  height: 6rem;
  position: relative;
  transition: $transition;
}
input {
  border-radius: 10px;
  outline: 0;
  appearance: none;
  border: 2px solid transparent;
  background-color: var(--color-light-gray);
  display: block;
  font-size: 2.4rem;
  text-align: center;
  width: 100%;
  height: 100%;
  transition: $transition;
  &:focus {
    border-color: var(--color-blue);
  }
}

.container--loading {
  gap: 0;

  input {
    border-radius: 50%;
  }
  &.container-count-2 {
    .container-item:nth-child(1) {
      transform: translate3d(50%, 0, 0);
    }
    .container-item:nth-child(2) {
      transform: translate3d(-50%, 0, 0);
    }
  }
  &.container-count-3 {
    .container-item:nth-child(1) {
      transform: translate3d(100%, 0, 0);
    }
    .container-item:nth-child(3) {
      transform: translate3d(-100%, 0, 0);
    }
  }
  &.container-count-4 {
    .container-item:nth-child(1) {
      transform: translate3d(150%, 0, 0);
    }
    .container-item:nth-child(2) {
      transform: translate3d(50%, 0, 0);
    }
    .container-item:nth-child(3) {
      transform: translate3d(-50%, 0, 0);
    }
    .container-item:nth-child(4) {
      transform: translate3d(-150%, 0, 0);
    }
  }
  &.container-count-5 {
    .container-item:nth-child(1) {
      transform: translate3d(200%, 0, 0);
    }
    .container-item:nth-child(2) {
      transform: translate3d(100%, 0, 0);
    }
    .container-item:nth-child(4) {
      transform: translate3d(-100%, 0);
    }
    .container-item:nth-child(5) {
      transform: translate3d(-200%, 0, 0);
    }
  }
}

.spinner-container {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

::placeholder {
  color: var(--color-gray);
  opacity: 1;
  .container--loading & {
    color: transparent;
  }
}

:-ms-input-placeholder {
  color: var(--color-gray);
  .container--loading & {
    color: transparent;
  }
}

::-ms-input-placeholder {
  color: var(--color-gray);
  .container--loading & {
    color: transparent;
  }
}
</style>
