<template>
  <InfoModal
    v-if="logoutModalVisible"
    title="Logga ut"
    confirm-label="Logga ut"
    abort-label="Avbryt"
    @click="confirmLogout"
    ><p style="margin-bottom: 1rem">Är du säker på att du vill logga ut?</p>
  </InfoModal>
  <InfoModal
    v-else-if="CURRENT_VIEW === 'CREATE_NEW'"
    title="Skapa ny överlåtelse"
    confirm-label="Ja, skapa ny"
    abort-label="Avbryt"
    @click="handleCreateNewConfirm"
  >
    <div class="wrapper">
      <p style="margin-bottom: 1rem">
        Är du säker på att du vill skapa en ny överlåtelse ({{
          isPrivateFlow ? 'privat' : 'företag'
        }})?
      </p>
    </div>
  </InfoModal>
  <InfoModal
    v-else-if="ovApplications.length"
    :show-close-btn="true"
    :disable-outside-click="true"
    :title="isPrivateFlow ? 'Privata ärenden' : 'Företagsärenden'"
    @click="modalClick"
  >
    <div class="wrapper">
      <div v-if="!activeOvApplications.length" style="text-align: left">
        <section
          v-for="(group, title) in ovApplicationsGroups"
          :key="`group-${title}`"
          class="ov-applications"
        >
          <h4>Vill skapa en nytt ärende?</h4>
          <p>
            Om du istället ska <strong>övertaga ett objekt</strong> behöver du
            bli <strong>inbjuden av överlåtaren</strong>. Du kommer då få ett
            mail med en länk som kommer att koppla dig till ärendet.
          </p>
          <BaseButton
            :is-rounded="true"
            type="button"
            class="got-code-btn"
            :is-small="true"
            @click="CURRENT_VIEW = 'CREATE_NEW'"
          >
            Skapa ny överlåtelse
          </BaseButton>
        </section>
      </div>
      <section
        v-for="(group, title) in ovApplicationsGroups"
        :key="`group-${title}`"
        class="ov-applications"
      >
        <h4>{{ title }}</h4>
        <ul>
          <li v-for="application in group" :key="application.id">
            <i class="owner-info"
              ><template v-if="application.isOwner">Överlåter</template
              ><template v-else>Övertar</template></i
            >
            <ListItem
              :selected-value="selectedApplicationId"
              :radio-btn-value="`${application.id}`"
              :item-id="`${application.id}`"
              :apartments="application.apartment.address"
              :members="getMemberName(application.currentTenants)"
              @change="setSelectedApplicationId"
            />
          </li>
        </ul>
      </section>
      <BaseButton
        :is-disabled="!selectedApplicationId"
        class="confirm-create-btn"
        :is-rounded="true"
        @click="chooseApplication(selectedApplicationId)"
        >Se vald ansökan</BaseButton
      >
    </div>
  </InfoModal>
  <InfoModal
    v-else
    :show-close-btn="true"
    :disable-outside-click="true"
    :title="isPrivateFlow ? 'Privata ärenden' : 'Företagsärenden'"
    @click="modalClick"
  >
    <div class="wrapper">
      <h2>Ska du överlåta eller övertaga?</h2>
      <p>
        Om du ska <strong>övertaga ett objekt</strong> behöver du bli
        <strong>inbjuden av överlåtaren</strong>. Du kommer då få ett mail med
        en länk som kommer att koppla dig till ärendet.
      </p>
      <BaseButton
        :is-rounded="true"
        type="button"
        class="got-code-btn"
        :is-small="true"
        @click="CURRENT_VIEW = 'CREATE_NEW'"
      >
        Skapa ny överlåtelse
      </BaseButton>
    </div>
  </InfoModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import svgs from '@/utils/icon-constants';
import APPLICATION_TYPE from '@/utils/applicationType';

import InfoModal from '@/components/InfoModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import ListItem from '@/components/ListItem.vue';

export default {
  name: 'OvApplicationsHandler',
  components: {
    InfoModal,
    BaseButton,
    ListItem
  },
  data() {
    return {
      svgs,
      loading: false,
      CURRENT_VIEW: '',
      selectedApplicationId: '',
      logoutModalVisible: false
    };
  },
  computed: {
    ...mapState({
      landlord: state => state.app.landlord,
      application: state => state.ovApplication.application,
      applicationType: state => state.ovApplication.applicationType,
      ovApplications: state => state.ovApplication.ovApplications
    }),
    ...mapGetters({
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),

    ovApplicationsGroups() {
      if (!this.ovApplications.length) {
        return {};
      }

      const groups = {};

      if (this.activeOvApplications.length) {
        groups['Aktiva ärenden'] = this.activeOvApplications;
      }
      if (this.approvedOvApplications.length) {
        groups['Godkända ärenden'] = this.approvedOvApplications;
      }
      if (this.rejectedOvApplications.length) {
        groups['Nekade ärenden'] = this.rejectedOvApplications;
      }
      return groups;
    },
    activeOvApplications() {
      return this.ovApplications.filter(
        app => !['APPROVED', 'REJECTED'].includes(app.landlordStatus)
      );
    },
    rejectedOvApplications() {
      return this.ovApplications.filter(
        app => app.landlordStatus === 'REJECTED'
      );
    },
    approvedOvApplications() {
      return this.ovApplications.filter(
        app => app.landlordStatus === 'APPROVED'
      );
    }
  },
  methods: {
    ...mapActions({
      createAndSetOverlatelse: 'ovApplication/createAndSetOverlatelse',
      setApplicationAndStorage: 'ovApplication/setApplicationAndStorage'
    }),
    getMemberName(tenants) {
      if (tenants && tenants[0]) {
        const organizationNumber = tenants[0]?.organizationNumber || '';
        const name = tenants[0]?.name || '';
        if (!this.isPrivateFlow) {
          if (!organizationNumber) {
            return name || 'Namn saknas';
          }
          return organizationNumber;
        }
        return tenants[0]?.name || 'Namn saknas';
      }
      return '';
    },
    handleCreateNewConfirm(event) {
      if (event === 'CONFIRM') {
        this.createNewApplication();
      } else {
        this.CURRENT_VIEW = '';
      }
    },
    chooseApplication(applicationId) {
      const application = this.ovApplications.find(
        app => app.id === Number(applicationId)
      );
      this.setApplicationAndStorage(application);

      if (application.isOwner) {
        this.$router.push({
          path: '/uppgifter',
          query: { t: Date.now() }
        });
      } else {
        this.$router.push({
          path: '/andrahandshyresgast',
          query: { t: Date.now() }
        });
      }
    },
    setSelectedApplicationId(id) {
      this.selectedApplicationId = id;
    },
    confirmLogout(event) {
      if (event === 'CONFIRM') {
        this.$emit('logout');
      } else {
        this.logoutModalVisible = false;
      }
    },
    modalClick(event) {
      if (event === 'CANCEL') {
        this.logoutModalVisible = true;
      }
    },
    async createNewApplication() {
      this.loading = true;
      await this.createAndSetOverlatelse({
        landlordId: this.landlord?.landlordId || '169223',
        applicationType: this.isPrivateFlow
          ? APPLICATION_TYPE.OVERLATELSE_PRIVATE
          : APPLICATION_TYPE.OVERLATELSE_COMPANY
      });
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  text-align: center;
  min-width: 300px;
  max-width: 100%;
  h2 {
    margin: 0 0 0rem;
  }
  p {
    margin: 0 0 2rem;
    line-height: 1.6;
    max-width: none;
  }
  > *:nth-last-child(1) {
    margin: 0;
  }
}

.invite-container {
  text-align: center;
}

.invite-input {
  justify-content: center;
}

.invite-input-wrapper {
  position: relative;
  padding-bottom: 40px;
}

.invite-error {
  margin: 0;
  position: absolute;
  color: var(--color-error);
  font-size: 1.6rem;
  margin: 3px 0 0;
  text-align: center;
  left: 0;
  right: 0;
}
.stealth-btn {
  cursor: pointer;
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.continue-btn {
  margin: 0 0 1rem;
  font-size: 1.6rem;
}
.got-code-btn {
  font-size: 1.6rem;
}

.ov-choice {
  width: 100%;
}

.ov-choice-label {
  display: block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 2px solid var(--color-dark);
  color: var(--color-dark);
  background-color: #fff;
  &:hover {
    background-color: #e6f0ff;
    color: var(--color-dark);
  }
}

.ov-choice-input {
  display: none;
  &:checked + .ov-choice-label {
    background-color: var(--color-dark);
    color: #fff;
  }
}

.ov-choices {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 2rem 0;
}

.flex-btns {
  margin-top: 2rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.cancel-btn {
  font-size: 1.4rem;
}

.ov-applications {
  margin-bottom: 2rem;
  text-align: left;
  width: 40rem;
  max-width: 100%;
  h4 {
    margin: 0 0 1rem;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #f8f8fa;
    border-radius: 10px;
    max-height: 250px;
    overflow-y: auto;
  }

  a {
    text-decoration: none;
  }

  li {
    position: relative;
  }

  li .owner-info {
    position: absolute;
    bottom: 0.4rem;
    right: 0.7rem;
    font-size: 1.2rem;
  }

  li:nth-last-of-type(1) .list-item {
    border-bottom: none;
  }
}
</style>
