export default {
  isMobile: state => state.screenSize === 'MOBILE',
  isSmallDesktop: state => state.screenSize === 'DESKTOP_SMALL',
  isDesktop: state => state.screenSize === 'DESKTOP',
  getSupportAddress(state) {
    return state.landlord?.hasAltEmailAddress
      ? state.altSupportAddress
      : state.supportAddress;
  }
};
