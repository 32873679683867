import { render, staticRenderFns } from "./OvExtraObject.vue?vue&type=template&id=3e929977&scoped=true"
import script from "./OvExtraObject.vue?vue&type=script&lang=js"
export * from "./OvExtraObject.vue?vue&type=script&lang=js"
import style0 from "./OvExtraObject.vue?vue&type=style&index=0&id=3e929977&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e929977",
  null
  
)

export default component.exports