import {
  fieldIsEmpty,
  validateNumber,
  validatePostalCode,
  validatePersonalNumber,
  validEmail,
  validateOrganizationNumber
} from '@/utils/input-validations';
import APPLICATION_TYPE from '@/utils/applicationType';

const summarizeStatuses = statuses => {
  if (!statuses || statuses.every(status => status === '')) return '';
  if (statuses.every(status => status === 'EMPTY')) return 'EMPTY';
  if (statuses.includes('COMMENT')) return 'COMMENT';
  if (statuses.every(status => status === 'APPROVED')) return 'APPROVED';
  if (statuses.includes('INVALID')) return 'INVALID';
  if (statuses.some(status => ['INCOMPLETE', 'EMPTY'].includes(status)))
    return 'INCOMPLETE';
  return 'COMPLETE';
};

function validateField(field) {
  if (fieldIsEmpty(field.value)) {
    return 'EMPTY';
  }
  if (!field.valid) {
    return 'INVALID';
  }
  return 'COMPLETE';
}

function getAdminStatus(obj, index) {
  const theObj = index !== null && index !== undefined ? obj[index] : obj;

  if (theObj.status === 'REJECTED') {
    return 'COMMENT';
  }
  if (theObj.status === 'APPROVED') {
    return 'APPROVED';
  }
  return '';
}
const statusTranslator = {
  REJECTED: 'COMMENT',
  APPROVED: 'APPROVED'
};
function getAdminDocGroupStatuses(documentGroup) {
  if (!documentGroup) {
    return [];
  }
  return Object.values(documentGroup)
    .map(item => statusTranslator[item.status])
    .filter(n => !!n);
}

function docValidator(documentsGroups, documents, categoryType) {
  return Object.entries(documentsGroups).reduce((acc, [key]) => {
    const doc = documents.find(
      doc => doc.category === key && doc.categoryType === categoryType
    );

    if (doc) {
      let status = 'COMPLETE';
      if (doc.status === 'REJECTED') {
        status = 'COMMENT';
      } else if (doc.status === 'APPROVED') {
        status = 'APPROVED';
      }
      acc[key] = status;
    } else {
      acc[key] = documentsGroups[key].required === 0 ? '' : 'EMPTY';
    }

    return acc;
  }, {});
}

export default {
  PERSONAL_INFORMATION(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return [];
    }
    const { currentTenants, applicationType } =
      rootState.ovApplication.application;
    return currentTenants.map((tenant, index) => {
      let fields = {
        name: validateField({
          value: tenant.name,
          valid: true
        }),
        email: validateField({
          value: tenant.email,
          valid: validEmail(tenant.email)
        }),
        phone: validateField({
          value: tenant.phone,
          valid: true
        })
      };
      if (applicationType === APPLICATION_TYPE.OVERLATELSE_COMPANY) {
        fields = {
          ...fields,
          address: validateField({
            value: tenant.address,
            valid: true
          }),
          postalCode: validateField({
            value: tenant.postalCode,
            valid: validatePostalCode(tenant.postalCode)
          }),
          city: validateField({
            value: tenant.city,
            valid: true
          }),
          ...(index === 0
            ? {
                organizationNumber: validateField({
                  value: tenant.organizationNumber,
                  valid: validateOrganizationNumber(tenant.organizationNumber)
                })
              }
            : {})
        };
      }

      return fields;
    });
  },
  APARTMENT(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return {};
    }
    const { apartment, applicationType } = rootState.ovApplication.application;
    let fields = {
      address: validateField({
        value: apartment.address,
        valid: true
      }),
      postalCode: validateField({
        value: apartment.postalCode,
        valid: validatePostalCode(apartment.postalCode)
      }),
      city: validateField({
        value: apartment.city,
        valid: true
      }),
      contractStartDate: validateField({
        value: apartment.contractStartDate,
        valid: true
      })
    };
    if (applicationType === APPLICATION_TYPE.OVERLATELSE_COMPANY) {
      fields = {
        ...fields,
        contractNumber: validateField({
          value: apartment.contractNumber,
          valid: true
        })
      };
    } else {
      fields = {
        ...fields,
        objectNumber: validateField({
          value: apartment.objectNumber,
          valid: true
        }),
        apartmentNumber: validateField({
          value: apartment.apartmentNumber,
          valid: validateNumber(apartment.apartmentNumber)
        }),
        rent: validateField({
          value: apartment.rent,
          valid: true
        })
      };
    }
    return fields;
  },
  SUBTENANT_INFORMATION(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return [];
    }
    const { secondaryTenants, applicationType } =
      rootState.ovApplication.application;
    return secondaryTenants.map((tenant, index) => {
      let fields = {
        name: validateField({
          value: tenant.name,
          valid: true
        }),
        email: validateField({
          value: tenant.email,
          valid: validEmail(tenant.email)
        }),
        phone: validateField({
          value: tenant.phone,
          valid: true
        }),
        address: validateField({
          value: tenant.address,
          valid: true
        }),
        postalCode: validateField({
          value: tenant.postalCode,
          valid: validatePostalCode(tenant.postalCode)
        }),
        city: validateField({
          value: tenant.city,
          valid: true
        })
      };
      if (applicationType === APPLICATION_TYPE.OVERLATELSE_COMPANY) {
        fields = {
          ...fields,
          ...(index === 0
            ? {
                organizationNumber: validateField({
                  value: tenant.organizationNumber,
                  valid: validateOrganizationNumber(tenant.organizationNumber)
                })
              }
            : {})
        };
      } else {
        fields = {
          ...fields,
          ssn: validateField({
            value: tenant.ssn,
            valid: validatePersonalNumber(tenant.ssn)
          })
        };
      }
      return fields;
    });
  },
  REASON(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return {};
    }
    const { reason, applicationType } = rootState.ovApplication.application;
    let fields = {
      text: validateField({
        value: reason.text,
        valid: true
      }),
      transferDate: validateField({
        value: reason.transferDate,
        valid: true
      })
    };
    if (applicationType === APPLICATION_TYPE.OVERLATELSE_PRIVATE) {
      fields = {
        ...fields,
        relationship: validateField({
          value: reason.relationship,
          valid: true
        }),
        type: validateField({
          value: reason.type,
          valid: true
        })
      };
    } else {
      fields = {
        ...fields,
        relationship: validateField({
          value: reason.isFullTransfer,
          valid: true
        })
      };
    }
    return fields;
  },
  OWNER_DOCUMENTS(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return {};
    }
    const { computedDocumentsGroups, documents } =
      rootState.ovApplication.application;
    if (
      !computedDocumentsGroups ||
      !Object.keys(computedDocumentsGroups).length
    ) {
      return {};
    }

    return docValidator(computedDocumentsGroups, documents, 'transferor');
  },
  INVITEE_DOCUMENTS(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return {};
    }
    const { inviteeComputedDocumentsGroups, documents } =
      rootState.ovApplication.application;
    if (
      !inviteeComputedDocumentsGroups ||
      !Object.keys(inviteeComputedDocumentsGroups).length
    ) {
      return {};
    }

    return docValidator(
      inviteeComputedDocumentsGroups,
      documents,
      'transferee'
    );
  },
  summarized(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return {};
    }

    const {
      currentTenants,
      apartment,
      reason,
      secondaryTenants,
      computedDocumentsGroups,
      inviteeComputedDocumentsGroups
    } = rootState.ovApplication.application;
    const {
      PERSONAL_INFORMATION,
      APARTMENT,
      REASON,
      OWNER_DOCUMENTS,
      SUBTENANT_INFORMATION,
      INVITEE_DOCUMENTS
    } = getters;

    return {
      PERSONAL_INFORMATION: summarizeStatuses([
        ...PERSONAL_INFORMATION.flatMap((field, index) =>
          getAdminStatus(currentTenants, index)
            ? getAdminStatus(currentTenants, index)
            : Object.values(field)
        )
      ]),
      APARTMENT: getAdminStatus(apartment)
        ? getAdminStatus(apartment)
        : summarizeStatuses(Object.values(APARTMENT)),
      REASON: getAdminStatus(reason)
        ? getAdminStatus(reason)
        : summarizeStatuses(Object.values(REASON)),
      OWNER_DOCUMENTS: summarizeStatuses(
        [
          ...Object.values(OWNER_DOCUMENTS),
          ...getAdminDocGroupStatuses(computedDocumentsGroups)
        ].filter(n => !!n)
      ),
      SUBTENANT_INFORMATION: summarizeStatuses([
        ...SUBTENANT_INFORMATION.flatMap((field, index) =>
          getAdminStatus(secondaryTenants, index)
            ? getAdminStatus(secondaryTenants, index)
            : Object.values(field)
        )
      ]),
      INVITEE_DOCUMENTS: summarizeStatuses(
        [
          ...Object.values(INVITEE_DOCUMENTS),
          ...getAdminDocGroupStatuses(inviteeComputedDocumentsGroups)
        ].filter(n => !!n)
      )
    };
  },
  applicationComplete(state, getters, rootState) {
    if (!rootState.ovApplication?.application) {
      return {};
    }
    return Object.values(getters.summarized).every(status =>
      ['COMPLETE', 'APPROVED'].includes(status)
    );
  }
};
