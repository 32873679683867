<template>
  <div class="landing-page">
    <div class="splash">
      <img
        src="../../assets/svgs/idea.svg"
        alt="Två personer som pratar om andrahandsansökan"
      />
    </div>
    <div class="content">
      <h1>Välkommen till Andrahand</h1>

      <p class="ingress">
        Slipp allt krångel med blanketter och jobbiga skrivare. Gör din
        andrahandsansökan direkt i vår tjänst.
      </p>
      <p>
        Behöver du hjälp med er ansökan? Kontakta oss på
        <a
          :href="`mailto:${getSupportAddress}`"
          class="mail-to"
          target="_blank"
          >{{ getSupportAddress }}</a
        >.
      </p>

      <!-- Be aware that .disclaimer is used on bytesansökan, the disclaimer here might differ
          As of this writing there is no disclaimer specifically for andrahand
      -->
      <!-- <CollapseCard v-if="landlord?.disclaimer" :is-alert="true" class="mb-2">
        <template slot="body-text">
          <p style="display: flex; gap: 2rem">
            <BaseImage class="icon-tip" :url="svgs.ICONS.TIP_DARK" />{{
              landlord.disclaimer
            }}
          </p>
        </template>
      </CollapseCard> -->
      <BankidLogin
        @switchLogin="loginBankid = false"
        @initiate="$emit('initiate')"
      >
        <template slot="header">
          {{
            isMobile ? 'Registrera / Logga in' : 'Starta ny ansökan / Logga in'
          }}
        </template>
      </BankidLogin>
    </div>

    <BaseModal
      v-if="!loginBankid"
      v-bind="$attrs"
      v-on="$listeners"
      @close="loginBankid = true"
    >
      <div class="login-view-body">
        <button class="close-button simple-button" @click="loginBankid = true">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.41 12L17.71 7.71C17.8983 7.5217 18.0041 7.2663 18.0041 7C18.0041 6.7337 17.8983 6.47831 17.71 6.29C17.5217 6.1017 17.2663 5.99591 17 5.99591C16.7337 5.99591 16.4783 6.1017 16.29 6.29L12 10.59L7.71 6.29C7.5217 6.1017 7.2663 5.99591 7 5.99591C6.7337 5.99591 6.4783 6.1017 6.29 6.29C6.1017 6.47831 5.99591 6.7337 5.99591 7C5.99591 7.2663 6.1017 7.5217 6.29 7.71L10.59 12L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L12 13.41L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29L13.41 12Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseModal from '@/components/BaseModal.vue';
import BankidLogin from '@/components/BankidLogin.vue';
// import CollapseCard from '@/components/CollapseCard.vue';
import svgs from '@/utils/icon-constants';
// import BaseImage from '@/components/BaseImage.vue';

export default {
  components: {
    BaseModal,
    /*BaseImage,*/
    BankidLogin
    /*CollapseCard*/
  },

  data() {
    return {
      svgs,
      loginBankid: true
    };
  },

  computed: {
    //...mapState('app', ['landlord']),
    ...mapGetters({
      isMobile: 'app/isMobile',
      getSupportAddress: 'app/getSupportAddress'
    })
  }
};
</script>

<style lang="scss" scoped>
.landing-page {
  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
    margin-bottom: 8rem;
  }
}

.splash {
  margin-left: -15rem;
  margin-right: 6rem;

  @include small-desktop {
    margin-left: -3rem;
  }

  @include smaller-desktop {
    margin-left: -2rem;
  }

  @include mobile {
    display: none;
  }
}

.content {
  > h1 {
    line-height: 1.2;
    font-size: 3.2rem;
    @include mobile {
      font-size: 2.4rem;
    }
  }
  > p {
    font-size: 1.6rem;
    margin: 2rem 0;
    line-height: 1.5;
    @include mobile {
      font-size: 1.5rem;
      margin: 1rem 0;
    }
  }
  .ingress {
    font-weight: 600;
  }
  .mail-to {
    color: var(--color-blue);
  }
}

:deep(.modal-content) {
  @include mobile {
    width: calc(100vw - 20px);
  }
}

.login-view-body {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  width: 500px;
  max-width: 95%;
  padding: 35px;
  position: relative;
}

.inline-login {
  max-width: 500px;

  background: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1.4rem 2rem 2rem;
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
</style>
