<template>
  <div>
    <InfoModal
      v-if="showConfirmSubmitModal"
      title="Spara ändringar?"
      confirm-label="Spara och fortsätt"
      abort-label="Avbryt"
      :is-disabled="isSaving"
      @click="confirmSubmitClick"
    >
      <InvalidFieldsInfo
        :invalid-fields="invalidFields"
        :missing-fields="missingFields"
        >Om du vill kan du fylla i det senare.</InvalidFieldsInfo
      >
    </InfoModal>
    <InfoModal
      v-if="confirmModalVisible"
      title="Spara ändringar?"
      confirm-label="Spara och fortsätt"
      abort-label="Spara inte"
      :is-disabled="isSaving"
      @click="confirmModalClick"
    >
      <InvalidFieldsInfo
        v-if="invalidFields.length || missingFields.length"
        :invalid-fields="invalidFields"
        :missing-fields="missingFields"
        >Vill du spara din ändringar ändå innan du
        fortsätter?</InvalidFieldsInfo
      >
      <p v-else>
        Du har gjort ändringar på sidan. Vill du spara dina ändringar innan du
        fortsätter?
      </p>
    </InfoModal>
    <InfoModal
      v-if="errorModalVisible"
      title="Något gick fel"
      abort-label="Stäng"
      @click="errorModalVisible = false"
    >
      <p>Det gick inte att spara dina ändringar, försök igen senare.</p>
    </InfoModal>
    <ConnectAccountModal
      v-if="connectAccountModalVisible"
      :applicant="activeApplicant"
      @accepted="connectAccount(true)"
      @denied="connectAccount(false)"
    />
    <router-view
      v-if="clonedApplication"
      :active-applicant="activeApplicant"
      :applicants="applicants"
      :saving-application-data="isSaving"
      :form-has-changed="formHasChanged"
      :active-member-swap-addresses="activeMemberSwapAddresses"
      :swap-details-members-list="swapDetailsMembersList"
      :swap-details-active-member-title="swapDetailsActiveMemberTitle"
      :merge-member-names="mergeMemberNames"
      @swapFormSubmit="checkChangesAndMaybeSubmit"
      @isEditing="handleEditingForm"
      @applicantClick="applicantClick"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import applicationService from '@/services/applicationService';
import {
  alphabet as _alphabet,
  getActiveMemberSwapAddresses
} from '@/utils/common';
import { validateAll } from '@/utils/applicationValidation';
import InfoModal from '@/components/InfoModal.vue';
import ConnectAccountModal from '@/components/ConnectAccountModal.vue';
import InvalidFieldsInfo from '@/components/InvalidFieldsInfo.vue';

export default {
  name: 'SwapApplicantManager',
  components: {
    InfoModal,
    ConnectAccountModal,
    InvalidFieldsInfo
  },
  beforeRouteUpdate(to, from, next) {
    //Leaving to new route without unmounting
    this.handleRouteLeave(next);
  },
  beforeRouteLeave(to, from, next) {
    //Leaving to new route and unmounting
    this.handleRouteLeave(next);
  },
  data() {
    return {
      formHasChanged: '',
      clonedApplication: null,
      isSaving: false,
      confirmModalVisible: false,
      errorModalVisible: false,
      connectAccountModalVisible: false,
      goNextRoute: null,
      nextIndexApplicantIndex: 0,
      alphabet: _alphabet,
      showConfirmSubmitModal: false
    };
  },
  computed: {
    ...mapGetters({
      allAppValidation: 'appValidation/all'
    }),
    ...mapState({
      application: state => state.application.application,
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    invalidFields() {
      if (!this.currentValidation.INVALID) {
        return [];
      }
      return this.currentValidation.INVALID;
    },
    missingFields() {
      if (!this.currentValidation.EMPTY) {
        return [];
      }
      return this.currentValidation.EMPTY;
    },
    currentValidation() {
      if (!this.formHasChanged) {
        return [];
      }
      const appValidation = validateAll(this.clonedApplication);
      const property =
        appValidation[this.selectedApplicantIndex][this.formHasChanged][
          this.selectedPossessionIndex
        ];

      return Object.keys(property).reduce((acc, key) => {
        const current = property[key];

        if (
          current.value === '' ||
          current.value === undefined ||
          current.value === null
        ) {
          if (!acc['EMPTY']) {
            acc['EMPTY'] = [];
          }
          acc['EMPTY'].push(current.label);
        } else if (!current.valid) {
          if (!acc['INVALID']) {
            acc['INVALID'] = [];
          }
          acc['INVALID'].push(current.label);
        }
        return acc;
      }, {});
    },
    applicants() {
      return this.clonedApplication?.applicants || [];
    },
    activeApplicant() {
      if (!this.applicants.length) {
        return null;
      }
      return this.applicants[this.selectedApplicantIndex];
    },
    activeApplicantApartments() {
      return this.activeApplicant?.apartments || [];
    },
    activeMember() {
      if (!this.activeApplicant?.members) {
        return null;
      }
      return this.activeApplicant.members[this.selectedPossessionIndex];
    },
    mergeMemberNames() {
      return (
        this.activeApplicant?.members.length === 2 &&
        this.activeMemberSwapAddresses.length === 1
      );
    },
    swapDetailsActiveMemberTitle() {
      if (!this.activeMember) {
        return '';
      }

      if (this.mergeMemberNames && this.activeApplicant) {
        return this.activeApplicant.members
          .map(member => {
            return member.name.split(' ')[0] || 'Medsökande';
          })
          .join(' och ');
      }

      const { name } = this.activeMember;
      return name?.length ? name.split(' ')[0] : 'Medsökande';
    },
    activeMemberSwapAddresses() {
      if (!this.applicants.length) {
        return null;
      }
      return getActiveMemberSwapAddresses(
        this.applicants,
        this.selectedApplicantIndex
      );
    },
    swapDetailsMembersList() {
      if (!this.activeApplicant?.members) {
        return [];
      }

      if (this.mergeMemberNames) {
        return [
          {
            title: this.swapDetailsActiveMemberTitle,
            active: true,
            tabLink: 0
          }
        ];
      }

      return this.activeApplicant.members.map((member, index) => ({
        title: member.name || `Medsökande`,
        active: this.selectedPossessionIndex === index,
        tabLink: index
      }));
    }
  },

  async created() {
    this.syncData();
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNativeLeave);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNativeLeave);
  },
  methods: {
    ...mapActions({
      goToNextSection: 'application/goToNextSection'
    }),
    ...mapMutations({
      setApplication: 'application/setApplication',
      setSelectedApplicantIndex: 'application/setSelectedApplicantIndex',
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    async handleGoToNext() {
      if (!this.confirmModalVisible) {
        switch (this.$route.name) {
          case 'APARTMENTS':
            await this.goToNext(this.activeApplicantApartments.length - 1);
            break;
          case 'MEMBERS':
            await this.goToNext(this.activeApplicant.members.length - 1);
            break;
          case 'SWAPDETAILS':
            await this.goToNext(this.swapDetailsMembersList.length - 1);
            break;
          default:
            await this.goToNext();
        }
      }
    },
    async goToNext(listCheck) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      await this.goToNextSection({ router: this.$router, listCheck });
    },
    syncData() {
      this.clonedApplication = JSON.parse(JSON.stringify(this.application));
    },
    async checkChangesAndMaybeSubmit(formHasChanged) {
      if (this.invalidFields.length || this.missingFields.length) {
        this.showConfirmSubmitModal = true;
      } else {
        if (!formHasChanged) {
          await this.handleGoToNext();
        } else {
          await this.handleFormSubmit();
        }
      }
    },
    async confirmSubmitClick(eventType) {
      if (eventType === 'CONFIRM') {
        await this.handleFormSubmit();
      }
      this.showConfirmSubmitModal = false;
    },
    async confirmModalClick(eventType) {
      switch (eventType) {
        case 'CONFIRM':
          await this.handleFormSubmit();
          this.closeModalAndGoNext();
          break;
        case 'REJECT':
          this.syncData();
          this.formHasChanged = '';
          this.closeModalAndGoNext();
          break;
        default:
          this.confirmModalVisible = false;
      }
    },
    closeModalAndGoNext() {
      this.confirmModalVisible = false;
      if (this.goNextRoute) {
        this.goNextRoute();
      } else {
        this.goNextIndex(this.nextIndexApplicantIndex);
      }
    },
    handleRouteLeave(next = null) {
      if (this.formHasChanged) {
        this.confirmModalVisible = true;
        this.goNextRoute = next;
      } else {
        next();
      }
    },
    applicantClick(index) {
      this.nextIndexApplicantIndex = index;

      if (this.formHasChanged) {
        this.confirmModalVisible = true;
        return;
      }

      this.goNextIndex(index);
    },
    goNextIndex(index) {
      this.setSelectedApplicantIndex(index);
      this.setSelectedPossessionIndex(0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleEditingForm(isEditing) {
      this.formHasChanged = isEditing;
    },
    updateObjProp(obj, value, propPath) {
      const [head, ...rest] = propPath.split('.');

      !rest.length
        ? (obj[head] = value)
        : this.updateObjProp(obj[head], value, rest.join('.'));
    },

    removeNewRejections(path) {
      const applicantCopy = {
        ...this.clonedApplication.applicants[this.selectedApplicantIndex]
      };
      this.updateObjProp(applicantCopy.newRejections, null, path);
      this.$set(
        this.clonedApplication.applicants,
        this.selectedApplicantIndex,
        applicantCopy
      );
    },
    async handleFormSubmit() {
      this.isSaving = true;

      try {
        const possessionIndex = this.selectedPossessionIndex;
        switch (this.$route.name) {
          case 'APARTMENTS': {
            const newApartments = await applicationService.updateApartments(
              this.activeApplicant.id,
              this.activeApplicantApartments
            );

            // Force overwrite landlord info
            newApartments?.forEach((newApartment, index) => {
              this.clonedApplication.applicants[
                this.selectedApplicantIndex
              ].apartments[index].landlordName = newApartment.landlordName;
              this.clonedApplication.applicants[
                this.selectedApplicantIndex
              ].landlordPhone = newApartment.landlordPhone;
              this.clonedApplication.applicants[
                this.selectedApplicantIndex
              ].landlordEmail = newApartment.landlordEmail;
              this.clonedApplication.applicants[
                this.selectedApplicantIndex
              ].landlordId = newApartment.landlordId;
            });
            this.removeNewRejections(`apartments.${possessionIndex}`);
            break;
          }
          case 'MEMBERS':
            await applicationService
              .updateMembers(
                this.activeApplicant.id,
                this.activeApplicant.members
              )
              .then(res => {
                const status = res?.status;
                if (status === 202 && this.selectedPossessionIndex === 0) {
                  this.setApplication(this.clonedApplication);
                  this.syncData();
                  this.connectAccountModalVisible = true;
                  throw Error('ConnectAccount');
                }
              });
            this.removeNewRejections(`members.${possessionIndex}`);
            break;
          case 'SWAPREASON':
            await applicationService.updateSwapReason(this.activeApplicant.id, {
              description: this.activeApplicant.swapReason.description
            });
            this.removeNewRejections('swapReason');
            break;
          case 'SWAPDETAILS':
            await applicationService.updateSwapDetails(
              this.activeApplicant.id,
              this.activeApplicant.swapDetails
            );
            this.removeNewRejections(`swapDetails.${possessionIndex}`);
            break;
          default:
            console.log('No match for', this.$route.name);
        }
        // Update global vuex state with clonedApplication and sync
        this.setApplication(this.clonedApplication);
        this.syncData();
        this.formHasChanged = '';

        await this.handleGoToNext();
      } catch (err) {
        console.error(err);
        Sentry.captureMessage(err);
        this.errorModalVisible = true;
      }
      this.isSaving = false;
    },
    async connectAccount(approved) {
      if (!approved) {
        this.activeMember.email = '';
        this.setApplication(this.localApplication);
        this.syncData();
      } else {
        this.setSelectedPossessionIndex(
          this.activeApplicant.members.length - 1
        );
      }

      this.connectAccountModalVisible = false;
      await this.goToNextSection({ router: this.$router });
    },
    preventNativeLeave(event) {
      if (!this.formHasChanged) {
        return;
      }
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }
  }
};
</script>

<style></style>
