<template>
  <div id="app">
    <slot />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CommonApp',
  async created() {
    window.addEventListener('resize', this.getAndsetScreenSize);
    this.getAndsetScreenSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getAndsetScreenSize);
  },
  methods: {
    ...mapActions({
      getAndsetScreenSize: 'app/getAndsetScreenSize'
    })
  }
};
</script>
