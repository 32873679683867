import tutorialService from '@/services/tutorialService';

async function markTooltipAsSeen(ctx, { reference }) {
  if (!reference) {
    return;
  }

  ctx.commit('markTooltipAsSeen', reference);

  await tutorialService.addSeenTutorialStep(reference);
}

async function syncTutorialSteps(ctx) {
  let seenTutorials = await tutorialService.getUserTutorialsSeen();

  if (!seenTutorials) {
    return;
  }

  // We clear seen tutorials on seen to enable correct counting when
  // new tutorials are present (1 of 1 on new visits instead of 3 of 3)
  for (let tutorialId of seenTutorials) {
    ctx.commit('clearSyncedTooltips', tutorialId);
  }
}

export default {
  syncTutorialSteps,
  markTooltipAsSeen
};
