<template>
  <div>
    <div class="text-container">
      <h3 class="title">{{ title }}</h3>
      <p class="description" v-html="description" />
    </div>
    <div class="buttons-wrapper">
      <div v-for="option in options" :key="option.id" class="button-item">
        <div class="selected-option">
          <button
            type="button"
            :disabled="applicationIsSent || applicationIsArchived"
            :title="
              applicationIsSent
                ? 'Under granskning, det går inte att göra ändringar för tillfället'
                : ''
            "
            :class="['button', selectedOption === option.id ? 'selected' : '']"
            @click="$emit('click', option)"
          >
            <span
              >{{ option.label
              }}<small>{{ new Date(option.id).getFullYear() }}</small></span
            >
          </button>
        </div>
        <div class="applicants-selected">
          <div
            v-for="(name, i) in getMatches(option.id)"
            :key="option + '_' + i"
            class="applicant"
            :title="`${name} har valt detta datum`"
          >
            {{ getInitials(name) }}
          </div>
        </div>
        <div
          v-if="selectedOption === option.id && hasConflict(option.id)"
          class="warning-icon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#409fff"
            class="bi bi-exclamation-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
            />
          </svg>
          <div class="warning-text">
            Ni har valt olika bytesdatum. Prata ihop er och bestäm ett gemensamt
            datum
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getInitials } from '@/utils/common';

export default {
  name: 'DatePicker',
  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedOption: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    description: {
      type: String,
      required: true,
      default: ''
    },
    applicantsSelectedDates: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      getInitials
    };
  },
  computed: {
    ...mapGetters({
      applicationIsSent: 'application/applicationIsSent',
      applicationIsArchived: 'application/applicationIsArchived'
    })
  },
  methods: {
    getMatches(date) {
      const selectedDatesNames = Object.keys(this.applicantsSelectedDates);
      if (!(selectedDatesNames.length > 0)) {
        return;
      }
      return selectedDatesNames.filter(name => {
        const selectedDate = this.applicantsSelectedDates[name];
        return selectedDate === date ? name.trim() : '';
      });
    },
    hasConflict() {
      const selectedDate = this.selectedOption;
      const selectedDatesNames = Object.keys(this.applicantsSelectedDates);

      if (selectedDatesNames.length === 0) {
        return false;
      }

      return selectedDatesNames.some(name => {
        const date = this.applicantsSelectedDates[name];
        return date !== selectedDate && date !== null;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-container {
  line-height: 1.5;
  margin-bottom: 1rem;
  color: var(--color-dark);
}
.selected-option {
  position: relative;
}
.warning-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.warning-text {
  font-weight: 500;
  text-align: center;
  color: var(--color-blue);
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 2px;
  @include mobile {
    width: 100%;
    margin-bottom: 2px;
  }
}
.title {
  font-weight: 600;
  margin: 0;
  font-size: 1.6rem;
}
.description {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
}
.buttons-wrapper {
  border-radius: 10px;
  display: flex;
  margin-bottom: 80px;
  flex-wrap: wrap;

  @include mobile {
    gap: 10px;
    overflow: unset;
  }
}

.applicants-selected {
  display: flex;
  overflow: hidden;
  border: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  gap: 2px;
  padding: 4px;
  justify-content: center;
}

.applicant {
  padding: 1px 8px;
  text-align: center;
  font-size: 1rem;
  background-color: var(--color-dark);
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 5px;
  text-transform: uppercase;
}

.button-item {
  flex-grow: 1;
  width: 16.6666666667%;
  &:nth-child(1) .button {
    border-bottom-left-radius: 10px;
  }
  &:nth-last-child(1) .button {
    border-bottom-right-radius: 10px;
  }
  @include mobile {
    width: 33.333333%;
  }
}

.button {
  border: none;
  box-shadow: none;
  background-color: var(--color-light-gray);
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1.6rem;
  position: relative;
  text-align: center;
  color: #000;
  font-size: 1.6rem;
  line-height: 1.4;
  overflow: hidden;
  @include mobile {
    border-radius: 10px;
  }

  small {
    display: block;
    font-size: 1.2rem;
  }
  span {
    z-index: 0;
    transition: color 0.2s ease;
    @include smaller-desktop {
      width: 40px;
    }
  }

  &:not([disabled]):hover {
    background-color: #d3d2d2;
  }

  &::before {
    background-color: var(--color-blue);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transition: 0.3s ease;
    width: 100%;
    opacity: 0;
    @include mobile {
      border-radius: 10px;
    }
  }
  &.selected {
    color: #fff;
    &::before {
      opacity: 1;
      transform: scale(1);
    }
  }
  &[disabled] {
    cursor: not-allowed;
    color: var(--color-disabled);
  }
}
</style>
