<template>
  <div class="documents-grid">
    <div
      v-for="(document, index) of documents"
      :key="index"
      class="documents-grid-item"
    >
      <button
        class="document-button"
        :class="{ downloading: downloadingUuids.includes(document.uuid) }"
        @click="$emit('fileClick', document.uuid)"
      >
        <div class="document-image-container">
          <div class="document-overlay">
            <p v-if="downloadingUuids.includes(document.uuid)">Laddar ned...</p>
            <p v-else>Öppna</p>
          </div>
          <BaseImage :url="svgs.ICONS.DOCUMENT" class="document-image" />
        </div>
        <div class="mt-10 overflow-text">
          {{ getFilename(document.fileName) }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import svgs from '@/utils/icon-constants';
import { getFilename } from '@/utils/common';
import BaseImage from '@/components/BaseImage.vue';
export default {
  name: 'DocumentsGrid',
  components: {
    BaseImage
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    downloadingUuids: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      getFilename,
      svgs
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.documents-grid {
  margin-top: 0.7rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2rem;
  margin-right: -2rem;
  width: 100%;
  .mt-10 {
    margin-top: 1rem;
    color: rgb(160, 160, 160);
  }
  .documents-grid-item {
    width: 25%;
    text-align: center;
    padding: 1rem 2rem;
    @include smaller-desktop {
      width: 33%;
    }
    @include mobile {
      width: 33%;
    }
    @include smaller-mobile {
      width: 50%;
    }
  }
}

.document-button {
  padding: 0;
  display: block;
  appearance: none;
  width: 100%;
  border: none;
  margin: 0;
  background: #fff;
  cursor: pointer;
  .document-image-container {
    border-radius: 1rem;
    width: 100%;
    display: block;
    box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.15);
    position: relative;
    > img {
      width: 100%;
      display: block;
    }
  }
  .document-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(88, 88, 88, 0.77);
    font-size: 1.4rem;
    color: #fff;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: opacity;
    transition: 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
    opacity: 0;
    visibility: hidden;
  }
  &.downloading .document-overlay,
  &:hover .document-overlay {
    opacity: 1;
    visibility: visible;
  }
}

.overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
