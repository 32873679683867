<template>
  <component :is="wrapperComponent" v-bind="$attrs" v-on="$listeners">
    <li class="menu-link-wrapper" @click="$emit('click')">
      <router-link
        :is="enabled ? 'router-link' : 'span'"
        class="menu-link"
        :class="{ disabled: !enabled }"
        :to="url"
      >
        <span><slot /></span>
        <div v-if="showStatus">
          <StatusCircle :status="status" :title="statusTitle" class="icon" />
        </div>
      </router-link>
    </li>
  </component>
</template>

<script>
import StatusCircle from '@/components/StatusCircle.vue';
import Tooltip from '@/components/Tooltip.vue';

export default {
  name: 'SideMenuLink',
  components: {
    StatusCircle,
    Tooltip
  },

  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    url: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    tooltipStep: {
      type: String,
      default: null
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    statusTitle: {
      type: String,
      default: ''
    },
    showStatus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    wrapperComponent() {
      return this.showTooltip ? 'Tooltip' : 'div';
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-link-wrapper {
  width: 100%;
}

.icon {
  display: inline-flex;
  height: 20px;
  width: 20px;
  position: relative;
}

li {
  list-style-type: none;
}

.menu-link {
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: var(--color-gray);
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include mobile {
    font-size: 1.4rem;
    padding: 1rem;
  }
}

.menu-link:hover {
  color: var(--color-blue);
}

.menu-link:hover svg path {
  fill: var(--color-blue) !important;
}

.menu-link span,
.menu-link-sublet span {
  display: inline-block;
  vertical-align: super;
}

.menu-link.router-link-active,
.menu-link.active {
  color: var(--color-blue) !important;
  background-color: #e9efff;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.menu-link.disabled {
  color: var(--color-light-gray);
  cursor: not-allowed;
}
</style>
