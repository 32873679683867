import Vue from 'vue';

function setSelectedApplicantIndex(state, index) {
  state.selectedApplicantIndex = index;
}

function setSelectedPossessionIndex(state, index) {
  state.selectedPossessionIndex = index;
}

function setApplication(state, application) {
  if (application?.id) {
    localStorage.setItem('applicationId', application.id);
  }
  state.application = application;
}

function setApplicantStatus(state, { id, status }) {
  const idx = state.application.applicants.findIndex(
    applicant => applicant.id === id
  );
  state.application.applicants[idx].status = status;
}
function setApplicationId(state, applicationId) {
  if (applicationId) {
    localStorage.setItem('applicationId', applicationId);
  } else {
    localStorage.removeItem('applicationId');
  }

  state.applicationId = applicationId;
}

function updateApplicant(state, obj) {
  const index = state.application.applicants.findIndex(
    x => x.id == obj.applicantId
  );

  if (index === -1) {
    return;
  }

  Vue.set(state.application.applicants, index, {
    ...state.application.applicants[index],
    ...obj
  });
}

function setApplicationStatus(state, status) {
  state.application.status = status;
}

function setLoadingDocument(state, isLoading) {
  state.isLoadingDocument = isLoading;
}

function setSkipRouterIndexReset(state, skip) {
  state.skipRouterIndexReset = skip;
}

function setInviteCode(state, inviteCode) {
  state.inviteCode = inviteCode;
}

export default {
  setApplication,
  updateApplicant,
  setSelectedApplicantIndex,
  setSelectedPossessionIndex,
  setApplicationId,
  setApplicationStatus,
  setApplicantStatus,
  setLoadingDocument,
  setSkipRouterIndexReset,
  setInviteCode
};
