<template>
  <div class="container">
    <div
      ref="stickyBar"
      class="sticky-bar"
      :class="{ 'is-sticky': desktopSticky }"
    >
      <TabMenu v-if="showTabMenu" :tooltip-step="`PageHeader-${title}`">
        <slot />
      </TabMenu>

      <div class="overflow-wrapper">
        <div
          id="sticky-text-container"
          class="text-container"
          :class="{ tabMenuHidden: !showTabMenu }"
        >
          <h1 class="title">
            {{ title }}
          </h1>
          <transition name="form-title" mode="out-in">
            <h2 :key="names" class="names">{{ names }}</h2>
          </transition>
        </div>
      </div>
      <div
        v-if="isMobile"
        class="text-container sticky-mobile"
        :class="{ active: mobileSticky }"
      >
        <h1 class="title">Fyller just nu i åt:</h1>
        <transition name="form-title" mode="out-in">
          <h2 :key="names" class="names">{{ names }}</h2>
        </transition>
      </div>
    </div>
    <div ref="mobileBreakpoint" style="height: 1px"></div>
    <div :class="isMobile ? 'mb-2' : 'mb-4'"></div>
  </div>
</template>

<script>
import TabMenu from '@/components/TabMenu.vue';

export default {
  components: {
    TabMenu
  },
  props: {
    names: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    showTabMenu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      desktopSticky: false,
      mobileSticky: false,
      desktopObserver: null,
      mobileObserver: null
    };
  },
  computed: {
    stickyBar() {
      return this.$refs.stickyBar;
    },
    mobileBreakpoint() {
      return this.$refs.mobileBreakpoint;
    }
  },
  watch: {
    isMobile: {
      handler(mobile) {
        if (mobile) {
          this.desktopObserver.unobserve(this.stickyBar);
          this.mobileObserver.observe(this.mobileBreakpoint);
        } else {
          this.mobileObserver.unobserve(this.mobileBreakpoint);
          this.desktopObserver.observe(this.stickyBar);
        }
      }
    }
  },
  mounted() {
    this.desktopObserver = new IntersectionObserver(
      ([e]) => (this.desktopSticky = e.intersectionRatio < 1),
      { threshold: [1] }
    );
    this.mobileObserver = new IntersectionObserver(
      entries => (this.mobileSticky = entries[0].boundingClientRect.y < 0),
      { threshold: [0.5] }
    );
    if (this.isMobile) {
      this.mobileObserver.observe(this.mobileBreakpoint);
    } else {
      this.desktopObserver.observe(this.stickyBar);
    }
  },
  beforeDestroy() {
    this.desktopObserver.unobserve(this.stickyBar);
    this.mobileObserver.unobserve(this.mobileBreakpoint);
  }
};
</script>
<style lang="scss">
.overflow-wrapper {
  overflow: hidden;
  max-width: 100%;
}
.text-container {
  padding-top: 4rem;
  transition: 0.2s ease-in-out;
  transition-property: transform;
  will-change: transform;
  transform-origin: left top;
  background-color: #f8f8fa;
  &.tabMenuHidden {
    padding-top: 0;
  }
  @include mobile {
    padding-top: 2rem;
    &.tabMenuHidden {
      padding-top: 0;
    }
  }
}

.text-container.sticky-mobile {
  transition: 0.3s ease-in-out;
  transition-property: all;
  right: 0;
  z-index: 5;
  transform: translateX(150%);
  @include mobile {
    background-color: #fff;
    box-shadow: 0px 2px 7px rgb(87 87 87 / 15%);
    border-radius: 10px;
    display: inline-block;
    padding: 1rem 2rem;
    position: fixed;
    top: calc(var(--header-height) + 0.5rem);
    right: 0.5rem;
    z-index: 5;
    max-width: 55%;
  }
  &.active {
    right: 0.5rem;
    transform: translateX(0%);
  }
}
.title {
  font-size: 3.2rem;
  color: var(--color-dark);
  .sticky-mobile & {
    color: var(--color-gray);
    font-size: 1.2rem;
    font-weight: 600;
    font-family:
      Averta Std,
      Helvetica,
      Arial,
      sans-serif;
  }
}

.names {
  font-size: 1.6rem;
  color: var(--color-dark);
  text-transform: uppercase;
  .sticky-mobile & {
    font-size: 1.4rem;
    font-family:
      Averta Std,
      Helvetica,
      Arial,
      sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.container {
  display: contents;
}

.sticky-bar {
  position: sticky;
  top: -1px;
  z-index: 5;

  @include desktop {
    &.is-sticky {
      .text-container {
        width: 150%;
        transform: scale(0.7) translateY(-3rem);
      }
    }
  }
  @include mobile {
    position: static;
  }
}
</style>
