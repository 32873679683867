<template>
  <FieldsWrapper class="wrapper">
    <div class="btn-container">
      <h4 class="object-title">{{ title }}</h4>
      <ExpandButton
        :icon-url="iconUrl"
        :is-disabled="disabled"
        type="button"
        @click="$emit('click')"
      >
        {{ btnText }}
      </ExpandButton>
    </div>
    <Fieldset class="fields">
      <OvInput
        :value="objectNumber"
        label="Objektnummer"
        placeholder="Objektnummer"
        :description="objectNumberDescription"
        @input="$emit('update:objectNumber', $event)"
      />
      <OvInput
        :value="address"
        label="Adress"
        placeholder="Adress"
        :description="addressDescription"
        @input="$emit('update:address', $event)"
      />
    </Fieldset>
  </FieldsWrapper>
</template>

<script>
import ExpandButton from '@/components/ExpandButton.vue';
import Fieldset from '@/components/Fieldset.vue';
import OvInput from '@/components/OvInput.vue';
import FieldsWrapper from '@/components/FieldsWrapper.vue';
import svgs from '@/utils/icon-constants';

export default {
  name: 'OvExtraObject',
  components: {
    ExpandButton,
    Fieldset,
    OvInput,
    FieldsWrapper
  },
  props: {
    btnText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    address: {
      type: String,
      default: ''
    },
    objectNumber: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    addressDescription: {
      type: String,
      default: ''
    },
    objectNumberDescription: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    iconUrl() {
      return this.disabled
        ? svgs.ICONS.REMOVE_CIRCLE_GREY
        : svgs.ICONS.REMOVE_CIRCLE_BLUE;
    }
  }
};
</script>

<style lang="scss" scoped>
.object-title {
  font-size: 1.6rem;
  font-family: 'Poppins';
}
.btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.8rem;
}

.wrapper {
  margin-bottom: 2rem;
}

.fields {
  > * {
    margin: 0;
  }
}
</style>
