function debounce(func, delay, immediate = false) {
  let timeout;

  function debounced(...args) {
    const context = this;
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, delay);

    if (callNow) func.apply(context, args);
  }

  // ✅ Add a `.cancel()` method to clear timeout
  debounced.cancel = () => {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
}

export default debounce;
