<template>
  <button
    v-bind="$attrs"
    :class="[
      'button',
      { 'is-fullwidth': isFullwidth },
      { 'is-rounded': isRounded },
      { 'is-small': isSmall },
      { 'is-extra-small': isExtraSmall },
      { 'is-transparent': isTransparent },
      { 'is-default': isDefault },
      { 'is-dark': isDark },
      { 'is-warning': isWarning },
      { 'is-secondary': isSecondary },
      { 'is-grayed-out': isGrayedOut },
      { hover: hoverEffect },
      { capitalize: capitalize }
    ]"
    :disabled="isDisabled"
    v-on="$listeners"
  >
    <div class="inner-wrapper">
      <div v-if="isLoading" class="spinner">
        <BaseLoadingSpinner :color="spinnerColor" :size="20" />
      </div>

      <span :class="{ hidden: isLoading }" class="slot-wrapper"><slot /></span>
    </div>
  </button>
</template>

<script>
import svgs from '@/utils/icon-constants';

import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

export default {
  name: 'BaseButton',
  components: { BaseLoadingSpinner },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isFullwidth: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isExtraSmall: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isDefault: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    isDark: {
      type: Boolean,
      default: false
    },
    hoverEffect: {
      type: Boolean,
      default: true
    },
    capitalize: {
      type: Boolean,
      default: false
    },
    isGrayedOut: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    spinnerColor() {
      if (this.isDefault) {
        return '#409fff';
      }
      return '#fff';
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  line-height: 1;
  background-color: var(--color-blue);
  -webkit-appearance: none;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  outline: none;
  padding: 1.5rem 3rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.2;
  }

  &:active {
    outline: none;
  }
}

.button:not([disabled]).hover:hover {
  opacity: 0.8;
}

.is-fullwidth {
  width: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.is-secondary {
  background-color: #34c375;
}

.is-warning {
  background-color: #ff3843;
}

.is-dark {
  background-color: var(--color-dark);
  border-color: var(--color-dark);
}

.is-default {
  background-color: white;
  border: 2px solid var(--color-gray);
  color: var(--color-gray);
}

.is-uppercase {
  text-transform: uppercase;
}

.is-square {
  border-radius: 0;
}

.is-rounded {
  border-radius: 25px;
}

.is-round {
  border-radius: 50%;
  padding: 0;

  &:hover:not(.is-grayed-out) {
    opacity: 1;
  }
}

.spinner-fade-enter-active,
.spinner-fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.spinner-fade-enter {
  opacity: 1;
}

.spinner-fade-leave-active {
  opacity: 0;
}

.right {
  display: inline-flex;
  margin-left: 15px;
}

.icon-close {
  height: 12px;
  width: 12px;
  background-position: center;
  background-size: contain;
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.inner-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.is-small {
  padding: 10px 15px;
}
.is-extra-small {
  padding: 7px 15px;
}

.is-transparent {
  background-color: transparent;
}

.hidden {
  visibility: hidden;
}

.slot-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
  @include mobile {
    gap: 8px;
  }
}

.is-grayed-out {
  opacity: 0.4;
}
</style>
