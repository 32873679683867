import applicationService from '@/services/applicationService';

const mobileMediaQuery = window.matchMedia('(max-width: 767px)');
const smallDesktopQuery = window.matchMedia(
  '(min-width: 769px) and (max-width: 1280px)'
);

export async function getAndsetScreenSize(ctx) {
  let newSize = 'DESKTOP';

  if (mobileMediaQuery.matches) {
    newSize = 'MOBILE';
  } else if (smallDesktopQuery.matches) {
    newSize = 'DESKTOP_SMALL';
  }

  if (ctx.state.setScreenSize !== newSize) {
    ctx.commit('setScreenSize', newSize);
  }
}
async function toggleSideMenuVisible(ctx, show) {
  const body = document.querySelector('body');
  if (show) {
    this.scrollPosition = window.scrollY;
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${this.scrollPosition}px`;
    body.style.width = '100%';
    ctx.commit('setSideMenuVisible', true);
  } else {
    ctx.commit('setSideMenuVisible', false);
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
    window.scrollTo(0, this.scrollPosition);
  }
}

async function getSubdomain() {
  let domain = window.location.host;
  if (domain && domain === 'formular-demo.andrahand.se') {
    domain = 'henrik.andrahand.se';
  }
  const arr = domain.split('.');
  if (arr.length < 2) {
    return null;
  }

  return arr[0];
}
async function fetchLandlord(ctx) {
  let landlord = null;

  const subdomain = await ctx.dispatch('getSubdomain');

  if (subdomain && subdomain !== 'formular-demo') {
    landlord = await applicationService.getLandLord(subdomain);
  }

  ctx.commit('setLandlord', landlord);
  return landlord;
}

export default {
  getAndsetScreenSize,
  toggleSideMenuVisible,
  fetchLandlord,
  getSubdomain
};
