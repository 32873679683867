<template>
  <div class="summary-field-wrapper">
    <p class="label">
      {{ label }}
    </p>
    <p
      v-if="status === 'INVALID'"
      class="missing-info"
      title="Fältet måste fyllas i korrekt"
    >
      <slot />
    </p>
    <p v-else-if="status === 'EMPTY'" class="missing-info">
      {{ infoHtml }}
    </p>
    <p v-else-if="hasEmptyInfoFields" class="missing-info">{{ infoHtml }}</p>
    <p v-else><slot /></p>
  </div>
</template>

<script>
export default {
  name: 'SummaryField',

  props: {
    label: {
      type: String,
      default: ''
    },
    hasEmptyInfoFields: {
      type: Boolean,
      default: false
    },
    infoHtml: {
      type: String,
      default: 'Information saknas'
    },
    // Status is new handler with more options, e.g EMPTY/INVALID
    status: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-field-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 1.6rem;
  @include mobile {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  p {
    width: 43%;
    @include mobile {
      width: 100%;
    }
  }
  .label {
    @include mobile {
      font-weight: 600;
    }
  }

  .missing-info,
  .has-error {
    font-style: italic;
    color: var(--color-red);
  }
}
</style>
