import Vue from 'vue';
import Router from 'vue-router';
import ApplicantManager from '@/components/ApplicantManager.vue';
import CurrentTenants from '@/views/sublet/CurrentTenants.vue';
import Apartment from '@/views/sublet/Apartment.vue';
import Reason from '@/views/sublet/Reason.vue';
import SubletTenantInformation from '@/views/sublet/SubletTenantInformation.vue';
import AhDataSummary from '@/views/sublet/AhDataSummary.vue';
import Documents from '@/views/sublet/Documents.vue';
import Status from '@/views/sublet/Status.vue';
import Login from '@/views/sublet/Login.vue';

Vue.use(Router);

export default function createSubletRouter() {
  const routes = [
    {
      path: '/uppgifter',
      component: ApplicantManager,
      children: [
        {
          path: '',
          component: CurrentTenants,
          name: 'currentTenants'
        },
        {
          path: '/lagenhet',
          component: Apartment,
          name: 'apartment'
        },
        {
          path: '/skal',
          component: Reason,
          name: 'reason'
        },
        {
          path: '/andrahandshyresgast',
          component: SubletTenantInformation,
          name: 'secondaryTenants'
        },
        {
          path: '/granska',
          component: AhDataSummary
        }
      ]
    },
    {
      path: '/dokument',
      component: Documents
    },
    {
      path: '/status',
      component: Status
    },
    {
      path: '/logga-in',
      component: Login
    },
    {
      path: '/',
      redirect: '/uppgifter'
    },
    { path: '*', redirect: '/' }
  ];

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
    routes,
    scrollBehavior() {
      return { x: 0, y: 0, behavior: 'smooth' };
    }
  });

  // https://github.com/vuejs/vue-router/issues
  const originalPush = Router.prototype.push;

  Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => {
      if (Router.isNavigationFailure(err)) {
        // resolve err
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  return router;
}
