import { setCookie, removeCookie } from '@/utils/cookie';

export default {
  addDoc(state, newDocument) {
    state.application.documents = [
      ...(state.application.documents || []),
      newDocument
    ];
  },
  removeDoc(state, uuid) {
    state.application.documents = state.application.documents.filter(
      x => x.uuid !== uuid
    );
  },
  setApplicationId(state, id) {
    state.applicationId = id;
  },
  setApplicationStatus(state, status) {
    state.application.status = status;
  },
  setInviteeStatus(state, status) {
    state.application.inviteeStatus = status;
  },
  setApplication(state, application) {
    state.application = application;
  },
  setApplicationType(state, type) {
    state.applicationType = type;
  },
  setOvApplications(state, ovApplications) {
    state.ovApplications = ovApplications || [];
  },
  addOvApplication(state, ovApplication) {
    state.ovApplications = [...state.ovApplications, ovApplication];
  },
  setRenewalModalVisible(state, visible) {
    state.renewalModalVisible = visible;
  },
  setRouterBase(state, base) {
    state.routerBase = base;
  },
  saveInviteCode(state, inviteCode) {
    setCookie('ovInviteCode', inviteCode, 10);
    state.inviteCode = inviteCode;
  },
  removeInviteCode(state) {
    removeCookie('ovInviteCode');
    state.inviteCode = '';
  }
};
