import Vue from 'vue';
import Vuex from 'vuex';
import createAppModule from '../shared/app';
import createTooltipsModule from '../shared/tooltips';
import subletApplication from './subletApplication';
import ahValidation from './ahValidation';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    app: createAppModule(),
    tooltips: createTooltipsModule(),
    subletApplication,
    ahValidation
  }
});
