import { getPrivateFile } from '@/services/legacyMedia';

async function baFileOpener(applicantId, identifier, mobile = false) {
  if (!mobile) {
    const newTab = window.open('', '_blank');

    const res = await getPrivateFile(applicantId, identifier);

    if (res?.url) {
      newTab.location.href = res.url;
    } else {
      newTab.close();
    }
  } else {
    const res = await getPrivateFile(applicantId, identifier);
    if (res?.url) {
      const newTab = window.open(res.url, '_blank', 'noopener,noreferrer');
      if (!newTab) {
        window.location.href = res.url;
      }
    }
  }
}

export default baFileOpener;
