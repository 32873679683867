<template>
  <SubletPage
    v-if="secondaryTenants"
    :categories="secondaryTenants"
    title="Uppgifter om övertagaren"
  >
    <OvForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      :form-type="formType"
      v-on="$listeners"
    >
      <FieldsWrapper v-if="!isPrivateFlow">
        <OvOrganizationSearch
          v-model="secondaryTenants[0].organizationNumber"
        />
        <CompanyDetails :company-details="activeCompanyDetails" />
      </FieldsWrapper>
      <FieldsWrapper>
        <template v-if="isPrivateFlow">
          <OvInput
            v-model="secondaryTenants[0].name"
            label="Namn"
            placeholder="Namn"
            description="Ange ditt för- och efternamn"
            autocomplete="given-name" />
          <Fieldset>
            <OvInput
              v-model="secondaryTenants[0].email"
              label="E-post"
              placeholder="E-post"
              description="Ange din e-postadress"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              autocomplete="email"
            />
            <OvInput
              v-model="secondaryTenants[0].phone"
              label="Telefonnummer"
              placeholder="Telefonnummer"
              description="Ange ditt telefonnummer"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="tel"
            />
          </Fieldset>
          <OvInput
            v-model="secondaryTenants[0].ssn"
            label="Personnummer"
            placeholder="Personnummer"
            description="Ange personnummer"
            :validation="validateSsn"
            :error-text="ssnErrorText" />
          <OvInput
            v-model="secondaryTenants[0].address"
            label="Adress"
            placeholder="Adress"
            description="Ange nuvarande folkbokföringsadress" />
          <Fieldset>
            <OvPostalCodeSearchBar
              v-model="secondaryTenants[0].postalCode"
              :class="{ autocomplete: postalCodeCompleted }"
              label="Postnummer"
              placeholder="Postnummer"
              description="Ange ditt postnummer"
              :validation="validatePostalCode"
              error-text="Postnummer måste anges med endast 5 siffror"
              @input="postalCodeCompleted = false"
              @dropdownMatch="postalCodeDropdownMatch($event, 0)"
            />
            <OvInput
              v-model="secondaryTenants[0].city"
              :class="{ autocomplete: postalCodeCompleted }"
              label="Ort"
              placeholder="Ort"
              description="Ange nuvarande ort"
              @input="postalCodeCompleted = false"
            /> </Fieldset></template
        ><template v-else
          ><OvInput
            v-model="secondaryTenants[0].name"
            label="Behörig firmatecknare"
            placeholder="Behörig firmatecknare"
            description="Ange behörig firmatecknare"
            autocomplete="given-name"
          />
          <Fieldset>
            <OvInput
              v-model="secondaryTenants[0].email"
              label="Firmatecknarens e-post"
              placeholder="E-post"
              description="Ange firmatecknarens e-postadress"
              type="email"
              :validation="validEmail"
              error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
              autocomplete="email"
            />
            <OvInput
              v-model="secondaryTenants[0].phone"
              label="Firmatecknarens telefonnummer"
              placeholder="Telefonnummer"
              description="Ange firmatecknarens telefonnummer"
              inputmode="numeric"
              pattern="[0-9]*"
              autocomplete="tel"
            />
          </Fieldset>
          <OvInput
            v-if="isPrivateFlow"
            v-model="secondaryTenants[0].ssn"
            label="Personnummer"
            placeholder="Personnummer"
            description="Ange personnummer"
            :validation="validateSsn"
            :error-text="ssnErrorText"
          />
          <OvInput
            v-model="secondaryTenants[0].address"
            label="Firmatecknarens adress"
            placeholder="Firmatecknarens adress"
            description="Ange firmatecknarens adress"
          />
          <Fieldset>
            <OvPostalCodeSearchBar
              v-model="secondaryTenants[0].postalCode"
              :class="{ autocomplete: postalCodeCompleted }"
              label="Firmatecknarens postnummer"
              placeholder="Firmatecknarens postnummer"
              description="Ange firmatecknarens postnummer"
              :validation="validatePostalCode"
              error-text="Postnummer måste anges med endast 5 siffror"
              @input="postalCodeCompleted = false"
              @dropdownMatch="postalCodeDropdownMatch($event, 0)"
            />
            <OvInput
              v-model="secondaryTenants[0].city"
              :class="{ autocomplete: postalCodeCompleted }"
              label="Firmatecknarens ort"
              placeholder="Firmatecknarens ort"
              description="Ange firmatecknarens ort"
              @input="postalCodeCompleted = false"
            />
          </Fieldset>
        </template>
      </FieldsWrapper>
      <FieldsWrapper>
        <ExpandableForm
          no-margin
          :disabled="!applicationIsEditable"
          :btn-text="
            formExpanded
              ? `Ta bort ${isPrivateFlow ? 'den andra hyresgästen' : 'firmatecknare 2'}`
              : `Lägg till ${isPrivateFlow ? 'en andra hyresgästen' : 'firmatecknare 2'}`
          "
          :form-expanded="formExpanded"
          @click="handleButtonClick"
        >
          <template v-if="secondaryTenants && secondaryTenants[1]">
            <h1 ref="expandableForm">
              {{ isPrivateFlow ? 'Övertagare 2' : 'Firmatecknare 2' }}
            </h1>
            <template v-if="isPrivateFlow">
              <OvInput
                v-model="secondaryTenants[1].name"
                label="Namn"
                placeholder="Namn"
                description="Ange ditt för- och efternamn"
                autocomplete="given-name" />
              <Fieldset>
                <OvInput
                  v-model="secondaryTenants[1].email"
                  label="E-post"
                  placeholder="E-post"
                  description="Ange din e-postadress"
                  type="email"
                  :validation="validEmail"
                  error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
                  autocomplete="email"
                />
                <OvInput
                  v-model="secondaryTenants[1].phone"
                  label="Telefonnummer"
                  placeholder="Telefonnummer"
                  description="Ange ditt telefonnummer"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="tel"
                />
              </Fieldset>
              <OvInput
                v-model="secondaryTenants[1].ssn"
                label="Personnummer"
                placeholder="Personnummer"
                description="Ange personnummer"
                :validation="validateSsn"
                :error-text="ssnErrorText" />
              <OvInput
                v-model="secondaryTenants[1].address"
                label="Adress"
                placeholder="Adress"
                description="Ange nuvarande folkbokföringsadress" />
              <Fieldset>
                <OvPostalCodeSearchBar
                  v-model="secondaryTenants[1].postalCode"
                  :class="{ autocomplete: secondPostalCodeCompleted }"
                  label="Postnummer"
                  placeholder="Postnummer"
                  description="Ange ditt postnummer"
                  :validation="validatePostalCode"
                  error-text="Postnummer måste anges med endast 5 siffror"
                  @input="secondPostalCodeCompleted = false"
                  @dropdownMatch="postalCodeDropdownMatch($event, 1)"
                />
                <OvInput
                  v-model="secondaryTenants[1].city"
                  :class="{ autocomplete: secondPostalCodeCompleted }"
                  label="Ort"
                  placeholder="Ort"
                  description="Ange nuvarande ort"
                  @input="secondPostalCodeCompleted = false"
                /> </Fieldset></template
            ><template v-else
              ><OvInput
                v-model="secondaryTenants[1].name"
                label="Behörig firmatecknare"
                placeholder="Behörig firmatecknare"
                description="Ange behörig firmatecknare"
                autocomplete="given-name"
              />
              <Fieldset>
                <OvInput
                  v-model="secondaryTenants[1].email"
                  label="Firmatecknarens e-post"
                  placeholder="E-post"
                  description="Ange firmatecknarens e-postadress"
                  type="email"
                  :validation="validEmail"
                  error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
                  autocomplete="email"
                />
                <OvInput
                  v-model="secondaryTenants[1].phone"
                  label="Firmatecknarens telefonnummer"
                  placeholder="Telefonnummer"
                  description="Ange firmatecknarens telefonnummer"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="tel"
                />
              </Fieldset>
              <OvInput
                v-if="isPrivateFlow"
                v-model="secondaryTenants[1].ssn"
                label="Personnummer"
                placeholder="Personnummer"
                description="Ange personnummer"
                :validation="validateSsn"
                :error-text="ssnErrorText"
              />
              <OvInput
                v-model="secondaryTenants[1].address"
                label="Firmatecknarens adress"
                placeholder="Firmatecknarens adress"
                description="Ange firmatecknarens adress"
              />
              <Fieldset>
                <OvPostalCodeSearchBar
                  v-model="secondaryTenants[1].postalCode"
                  :class="{ autocomplete: secondPostalCodeCompleted }"
                  label="Firmatecknarens postnummer"
                  placeholder="Firmatecknarens postnummer"
                  description="Ange firmatecknarens postnummer"
                  :validation="validatePostalCode"
                  error-text="Postnummer måste anges med endast 5 siffror"
                  @input="secondPostalCodeCompleted = false"
                  @dropdownMatch="postalCodeDropdownMatch($event, 1)"
                />
                <OvInput
                  v-model="secondaryTenants[1].city"
                  :class="{ autocomplete: secondPostalCodeCompleted }"
                  label="Firmatecknarens ort"
                  placeholder="Firmatecknarens ort"
                  description="Ange firmatecknarens ort"
                  @input="secondPostalCodeCompleted = false"
                />
              </Fieldset>
            </template>
            <InfoModal
              v-if="confirmModalVisible"
              title="Är du säker?"
              :mobile-full-width="isMobile"
              :show-close-btn="false"
              confirm-label="Ta bort"
              :confirm-icon="false"
              abort-label="Avbryt"
              @click="confirmModalClick"
            >
              <p>Vill du ta bort den andra hyresgästen?</p></InfoModal
            >
          </template>
        </ExpandableForm>
      </FieldsWrapper>
    </OvForm>
  </SubletPage>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePersonalNumber,
  validatePostalCode,
  validateOrganizationNumber
} from '@/utils/input-validations';
import { scrollExpandableForm } from '@/utils/scroll';
import { cloneTenant } from '@/utils/sublet';

import SubletPage from '@/components/SubletPage.vue';
import ExpandableForm from '@/components/ExpandableForm.vue';
import OvInput from '@/components/OvInput.vue';
import OvForm from '@/components/OvForm.vue';
import InfoModal from '@/components/InfoModal.vue';
import Fieldset from '@/components/Fieldset.vue';
import FieldsWrapper from '@/components/FieldsWrapper.vue';
import CompanyDetails from '@/components/CompanyDetails.vue';
import OvOrganizationSearch from '@/components/OvOrganizationSearch.vue';
import OvPostalCodeSearchBar from '@/components/OvPostalCodeSearchBar.vue';

export default {
  name: 'SubletTenantInformation',
  components: {
    SubletPage,
    ExpandableForm,
    OvInput,
    OvForm,
    InfoModal,
    Fieldset,
    FieldsWrapper,
    CompanyDetails,
    OvOrganizationSearch,
    OvPostalCodeSearchBar
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formExpanded: false,
      validEmail,
      validateNumber,
      validatePostalCode,
      validateOrganizationNumber,
      ssnErrorText:
        'Personnummer måste anges med 10 siffror, t.ex. YYMMDD-XXXX',
      confirmModalVisible: false,
      formType: 'secondaryTenants',
      postalCodeCompleted: false,
      secondPostalCodeCompleted: false
    };
  },
  computed: {
    ...mapState({
      companies: state => state.companies.companies
    }),
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'ovApplication/applicationIsEditable',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),
    activeCompanyDetails() {
      if (
        !this.validateOrganizationNumber(
          this.secondaryTenants[0]?.organizationNumber
        )
      ) {
        return {};
      }
      return this.companies[
        this.secondaryTenants[0].organizationNumber.split('-').join('')
      ];
    },
    secondaryTenants: {
      get() {
        return this.application?.secondaryTenants;
      },
      set() {
        this.$emit();
      }
    }
  },
  mounted() {
    if (this.secondaryTenants[1]) {
      this.formExpanded = true;
    }
  },
  methods: {
    ...mapActions({
      fetchCompany: 'companies/fetchCompany'
    }),
    postalCodeDropdownMatch(match, index) {
      this.$emit('isEditing', this.formType);
      this.secondaryTenants[index].postalCode = match.name;
      this.secondaryTenants[index].city = match.description;
      if (index === 0) {
        this.postalCodeCompleted = true;
      } else {
        this.secondPostalCodeCompleted = true;
      }
    },
    async confirmModalClick(eventType) {
      if (eventType === 'CONFIRM') {
        this.$delete(this.secondaryTenants, 1);
        this.emitEditing();
        this.formExpanded = false;
      }
      this.confirmModalVisible = false;
    },
    handleButtonClick() {
      const expand = !this.formExpanded;

      if (!expand && this.secondaryTenants[1]) {
        this.confirmModalVisible = true;
      } else {
        // Create second tenant if not exist
        this.$set(this.secondaryTenants, 1, cloneTenant(this.secondaryTenants));
        this.emitEditing();
        this.formExpanded = expand;
      }

      this.$nextTick(() => {
        this.scroll();
      });
    },
    scroll() {
      const formInput = this.$refs.expandableForm;
      const targetId = 'sticky-text-container';

      scrollExpandableForm(formInput, targetId);
    },
    validateSsn(value) {
      if (!validatePersonalNumber(value)) {
        this.ssnErrorText = 'Det personnummer du angivit är inte giltigt';
        return false;
      }
      return validatePersonalNumber(value);
    },
    emitEditing() {
      this.$emit('isEditing', this.formType);
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete :deep(input) {
  background-color: #e8f0fe;
}
h1 {
  margin-bottom: 2.8rem;
}
</style>
