<template>
  <InfoModal title="Bjud in" mobile-full-width @click="$emit('close')">
    <div class="invite-wrapper">
      <div class="input-description-wrapper">
        <div class="label-wrapper">
          <strong>E-post</strong>
          <span class="description-text">
            / Här kan du bjuda in personen du vill överlåta till genom att ange
            dennes e-post. Personen kommer att få ett mail med
            <strong>en inbjudanslänk</strong>
            som man behöver acceptera. Sedan kan de ange
            <strong>sina egna uppgifter och ladda upp dokument</strong>.
          </span>
        </div>
      </div>
      <form class="input-btn-wrapper" @submit.prevent="sendInvitation">
        <Input
          v-model="email"
          class="email-input"
          :disabled="loading || inviteAccepted"
          label=""
          description=""
          :validation="validEmail"
          error-text="E-postadress måste anges på detta vis: adress@mailhost.com"
          @input="hasBeenInvited = false"
        />
        <BaseButton
          :is-loading="loading"
          :is-secondary="true"
          :is-rounded="true"
          :is-fullwidth="true"
          type="submit"
          :class="[
            'send-invite',
            { 'invite-sent': hasBeenInvited },
            { 'invite-accepted': inviteAccepted }
          ]"
          :disabled="
            loading || hasBeenInvited || inviteAccepted || !validEmail(email)
          "
        >
          <div v-if="loading" class="invite-spinner">
            <BaseLoadingSpinner color="#fff" :size="30" />
          </div>
          <BaseIconText v-if="inviteAccepted" :icon-url="svgs.ICONS.EMAIL">
            Inbjudan accepterad
          </BaseIconText>
          <BaseIconText v-else-if="hasBeenInvited" :icon-url="svgs.ICONS.EMAIL">
            Inbjudan skickad
          </BaseIconText>
          <BaseIconText v-else :icon-url="svgs.ICONS.EMAIL_ARROW">
            Bjud in
          </BaseIconText>
        </BaseButton>
      </form>
    </div>
  </InfoModal>
</template>

<script>
import applicationService from '@/services/applicationService';
import { validEmail } from '@/utils/input-validations';
import svgs from '@/utils/icon-constants';

import InfoModal from '@/components/InfoModal.vue';
import BaseButton from './BaseButton.vue';
import BaseIconText from './BaseIconText.vue';
import Input from './Input.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

export default {
  name: 'InviteModal',
  components: {
    InfoModal,
    BaseButton,
    BaseIconText,
    Input,
    BaseLoadingSpinner
  },
  props: {
    applicationId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      email: '',
      loading: false,
      hasBeenInvited: false,
      inviteAccepted: false,
      activeInvite: null,
      validEmail,
      svgs
    };
  },
  beforeMount() {
    this.getInvitations();
  },
  methods: {
    async getInvitations() {
      try {
        this.loading = true;
        this.activeInvite = await applicationService.getOverlatelseInvite(
          this.applicationId
        );
        if (this.activeInvite?.email) {
          this.email = this.activeInvite.email;
          if (this.activeInvite.acceptedAt) {
            this.inviteAccepted = true;
          } else {
            this.hasBeenInvited = true;
          }
        }
      } catch {
        this.activeInvite = null;
      } finally {
        this.loading = false;
      }
    },
    async sendInvitation() {
      try {
        this.loading = true;
        await applicationService.sendOverlatelseInvite(
          this.applicationId,
          this.email
        );
        await this.getInvitations();
      } catch (error) {
        console.error('Invite failed', error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.send-invite {
  white-space: nowrap;
  position: relative;

  &.invite-sent {
    background-color: var(--color-teal);
    color: #000;
  }

  &.invite-accepted {
    background-color: var(--color-yellow);
    color: #000;
  }

  @include mobile {
    width: 100%;
    margin-left: 0;
  }

  .invite-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.email-input {
  margin: 0 0 1rem;
  :deep(.input-error) {
    position: static;
  }
}
</style>
