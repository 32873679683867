import applicationService from '@/services/applicationService';

function mergeNewArray(oldArr, newArr) {
  return newArr.map((currentTenant, index) => ({
    ...oldArr[index],
    ...currentTenant
  }));
}

function _setApplicationAndStorage(application, commit) {
  if (application) {
    commit('setApplication', application);
    commit('setApplicationId', `${application.id}`);
    localStorage.setItem('applicationId', application.id);
  } else {
    localStorage.removeItem('applicationId');
  }
}

export default {
  async setApplicationAndStorage(ctx, application) {
    _setApplicationAndStorage(application, ctx.commit);
  },
  async fetchSubletApplication(ctx, landlordId) {
    const application =
      await applicationService.getOrCreateSubletApplication(landlordId);

    _setApplicationAndStorage(application, ctx.commit);
  },
  async editCurrentTenants(ctx, { applicationId, currentTenants }) {
    await applicationService.updateCurrentTenants(
      applicationId,
      currentTenants
    );

    ctx.commit('setApplication', {
      ...ctx.state.application,
      currentTenants: mergeNewArray(
        ctx.state.application.currentTenants,
        currentTenants
      )
    });
  },
  async editSecondaryTenants(ctx, { applicationId, secondaryTenants }) {
    await applicationService.updateSecondaryTenants(
      applicationId,
      secondaryTenants
    );

    ctx.commit('setApplication', {
      ...ctx.state.application,
      secondaryTenants: mergeNewArray(
        ctx.state.application.secondaryTenants,
        secondaryTenants
      )
    });
  },
  async editApartment(ctx, { applicationId, apartment }) {
    await applicationService.updateApartment(applicationId, apartment);

    ctx.commit('setApplication', {
      ...ctx.state.application,
      apartment: {
        ...ctx.state.application.apartment,
        ...apartment
      }
    });
  },
  async editReason(ctx, { applicationId, reason }) {
    const { data } = await applicationService.updateReason(
      applicationId,
      reason
    );

    ctx.commit('setApplication', {
      ...ctx.state.application,
      computedDocumentsGroups: data.computedDocumentsGroups,
      reason: {
        ...ctx.state.application.reason,
        ...reason
      }
    });
  },
  async updateHasReadInfoCheck(ctx, applicationId) {
    await applicationService.updateHasReadInfo(applicationId);

    ctx.commit('setApplication', {
      ...ctx.state.application,
      hasReadInfo: true
    });
  },
  async submitApplication(ctx, applicationId) {
    await applicationService.updateApplication(applicationId);
    ctx.commit('setApplicationStatus', 'TREATING');
  },
  async addDocument(ctx, { applicationId, file, category }) {
    const doc = await applicationService.postFile(
      applicationId,
      file,
      category
    );

    ctx.commit('addDoc', doc);
  },
  async removeDocument(ctx, { applicationId, uuid }) {
    await applicationService.deleteFile(applicationId, uuid);
    ctx.commit('removeDoc', uuid);
  },
  async extendAhApplication(ctx) {
    const landlordId = ctx.state.application.landlordId;
    const application =
      await applicationService.extendSubletApplication(landlordId);
    _setApplicationAndStorage(application, ctx.commit);
  },
  async createNewAhApplication(ctx) {
    const landlordId = ctx.state.application.landlordId;
    const application =
      await applicationService.createNewSubletApplication(landlordId);
    _setApplicationAndStorage(application, ctx.commit);
  },
  async goToNextCategory({ dispatch }, router) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const getNextAllowedPath = await dispatch('getNextAllowedPath', router);

    await router.push({
      path: getNextAllowedPath
    });
  },
  getNextAllowedPath({ getters }, router) {
    const sideMenu = getters.sideMenu;
    const currentIndex = sideMenu.findIndex(
      item => item.url === router.currentRoute.path
    );

    // Find next with enabled = true
    for (let i = currentIndex + 1; i < sideMenu.length; i++) {
      if (sideMenu[i].enabled) {
        return sideMenu[i].url;
      }
    }

    const firstEnabled = sideMenu.find(item => item.enabled);
    if (firstEnabled) {
      return firstEnabled.url;
    }

    return null;
  }
};
