<template>
  <div>
    <form ref="formEl" class="form" novalidate @submit.prevent="submit">
      <slot />
      <BaseButton
        v-if="formHasChanged"
        :is-loading="false"
        :is-rounded="true"
        :is-disabled="isDisabled"
        :is-fullwidth="isMobile"
        :is-small="isMobile"
        type="submit"
        ><BaseIconText
          v-if="!isDisabled"
          :icon-url="svgs.ICONS.ARROW_RIGHT"
          :align-right="true"
        >
          {{ submitButtonText }}
        </BaseIconText>
        <span v-else>{{ submitButtonText }}</span>
      </BaseButton>

      <BaseButton
        v-else
        type="button"
        :is-rounded="true"
        :is-fullwidth="isMobile"
        :is-disabled="!applicationIsEditable && !isMobile"
        @click="handleGoToNext"
        >Gå vidare
        <BaseIconText
          class="info-modal-arrow-right"
          :icon-url="icons.ICONS.ARROW_RIGHT"
        ></BaseIconText
      ></BaseButton>
    </form>
    <InfoModal
      v-if="documentsInfoTextVisible && applicationIsEditable"
      class="documents-info-modal"
      title="Den här funktionen är låst"
      @click="documentsInfoTextVisible = false"
    >
      <p>
        För att gå vidare till ladda upp filer behöver du ange ett
        <b>skäl</b> för andrahandsuthyrning.
      </p>
    </InfoModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import icons from '@/utils/icon-constants';
import svgs from '@/utils/icon-constants';
import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';
import InfoModal from '@/components/InfoModal.vue';

export default {
  components: {
    BaseButton,
    BaseIconText,
    InfoModal
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    },
    blockDocumentsView: {
      type: Boolean,
      default: false
    },
    formType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      documentsInfoTextVisible: false,
      svgs,
      icons
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      applicationIsEditable: 'subletApplication/applicationIsEditable'
    }),
    submitButtonText() {
      if (this.isLoading) {
        return 'Sparar...';
      }

      if (this.formHasChanged) {
        return 'Spara och fortsätt';
      }

      return 'Spara och fortsätt';
    },
    isDisabled() {
      if (!this.formHasChanged || this.isLoading) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$refs.formEl.addEventListener('input', this.emitEditing);
  },
  beforeDestroy() {
    this.$refs.formEl.removeEventListener('input', this.emitEditing);
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'subletApplication/goToNextCategory'
    }),
    submit() {
      if (this.isDisabled) {
        return;
      }
      this.$emit('subletFormSubmit');
    },
    emitEditing() {
      this.$emit('isEditing', this.formType);
    },
    handleGoToNext() {
      if (this.isMobile && !this.applicationIsEditable) {
        this.goToNextCategory(this.$router);
      } else {
        this.goToNextCategoryOrShowModal();
      }
    },
    goToNextCategoryOrShowModal() {
      if (this.blockDocumentsView) {
        this.documentsInfoTextVisible = true;
      } else {
        this.goToNextCategory(this.$router);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  width: 100%;
}
</style>
