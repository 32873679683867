import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default function createAppModule() {
  return {
    namespaced: true,
    state: {
      companies: {}
    },
    getters,
    mutations,
    actions
  };
}
