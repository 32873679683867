import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default function createTooltipsModule() {
  return {
    namespaced: true,
    state: {
      tooltips: []
    },
    getters,
    mutations,
    actions
  };
}
