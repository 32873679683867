<template>
  <InfoModal
    :title="title"
    class="process-modal"
    mobile-full-width
    @click="$emit('click')"
  >
    <div class="wrapper">
      <div v-if="isLoading" class="spinner-wrapper">
        <BaseLoadingSpinner v-if="isLoading" />
      </div>
      <div class="content" :class="{ loading: isLoading }">
        <ul class="process-list">
          <li
            v-for="(item, i) in timeline"
            :key="i"
            class="process-list-item"
            :class="mainIndex === i ? 'active' : ''"
          >
            <div class="participant-container">
              <div
                v-for="(participant, x) in participantsInIdx(i)"
                :key="x"
                class="participant"
              >
                {{ participant.name }}
              </div>
            </div>
            <div class="main-container">
              <i class="circle"></i><i class="line"></i>
              <p v-html="item"></p>
            </div>
          </li>
        </ul>
        <div class="button-container">
          <BaseButton
            :is-fullwidth="isMobile"
            :is-rounded="true"
            :is-default="true"
            class="close-button"
            @click="$emit('click')"
            >Stäng</BaseButton
          >
        </div>
      </div>
    </div>
  </InfoModal>
</template>
<script>
import { mapGetters } from 'vuex';
import InfoModal from '@/components/InfoModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';

export default {
  name: 'ProcessModal',
  components: {
    InfoModal,
    BaseButton,
    BaseLoadingSpinner
  },
  props: {
    title: {
      type: String,
      required: true
    },
    timeline: {
      type: Array,
      required: true
    },
    mainIndex: {
      type: Number,
      required: false,
      default: 0
    },
    participants: {
      type: Array,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile'
    })
  },
  methods: {
    participantsInIdx(index) {
      const arr = this.participants.filter(
        participant => participant.index === index && !!participant.name
      );
      return arr.length > 0 ? arr : null;
    }
  }
};
</script>
<style lang="scss" scoped>
.process-list {
  --circle-height: 2.4rem;
  --circle-width: 2.4rem;
  --circle-border: 0.2rem;
  --left-margin: calc(var(--circle-width) + 4.3rem);
  --left-margin-mobile: calc(var(--circle-width) + 2.3rem);
  --bottom-margin: 3.5rem;
  list-style: none;
  padding: 0;
  margin: 3rem 0 0;
  @include mobile {
    --circle-height: 2rem;
    --circle-width: 2rem;
  }

  .process-list-item {
    line-height: 1.5;
    max-width: 42rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--color-dark);
    display: flex;
    gap: 10px;
    @include mobile {
      font-size: 1.4rem;
      gap: 6px;
    }
    p {
      margin: 0;
      font-size: inherit;
      opacity: 1;
    }

    strong {
      font-weight: 800;
    }

    .circle {
      width: var(--circle-width);
      height: var(--circle-height);
      background-color: var(--color-light-gray);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      border: var(--circle-border) solid transparent;
      border-color: var(--color-blue);
      background-color: #fff;
      &::before {
        content: '';
        width: calc(var(--circle-width) / 2);
        height: calc(var(--circle-height) / 2);
        background-color: var(--color-blue);
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }
    }

    .line {
      width: 0.2rem;
      height: 100%;
      background-color: var(--color-blue);
      position: absolute;
      left: calc((var(--circle-width) / 2) - 0.1rem);
      top: 0;
      z-index: 1;
    }

    &.active {
      color: var(--color-sky-blue);

      .line {
        background-color: var(--color-light-gray);
      }

      .circle {
        border-color: var(--color-blue);
        background-color: #fff;
        &::before {
          display: block;
        }
      }

      ~ li {
        p {
          opacity: 0.5;
        }

        .circle {
          background-color: var(--color-light-gray);
          border-color: transparent;
        }

        .line {
          background-color: var(--color-light-gray);
        }
      }
    }

    &:nth-last-child(1) {
      padding-bottom: 5.3rem;

      .line {
        display: none;
      }
    }
  }
  @include mobile {
    margin: 0;
  }
}
.button-container {
  text-align: center;
}

.close-button {
  font-size: 1.6rem;
}

.wrapper {
  position: relative;
  margin: 0 -1rem;
}

.content.loading {
  filter: blur(2px);
  opacity: 0.4;
}

.spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-container {
  padding-bottom: var(--bottom-margin);
  padding-left: var(--left-margin);
  position: relative;
  @include mobile {
    padding-left: 4rem;
  }
}

.participant-container {
  width: 42px;
  flex-shrink: 0;
  @include mobile {
    width: 28px;
  }
}

.participant {
  background-color: var(--color-dark);
  padding: 6px 10px 4px 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 0px #0000001a;
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  margin-bottom: 4px;
  text-align: center;
  max-width: 100%;
  &:nth-last-child(1) {
    margin-bottom: 15px;
  }
  @include mobile {
    font-size: 1rem;
    padding: 4px 6px 2px 6px;
  }
}
</style>
