import Personnummer from 'personnummer';
import { getActiveMemberSwapAddresses } from '@/utils/common';

export function notEmpty(str) {
  return !!str.length;
}

export function validEmail(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(value);
}

export function validatePersonalNumber(value) {
  return Personnummer.valid(value);
}

export function validatePostalCode(value) {
  let valid = true;

  const re = /[0-9]/g;

  if (!re.test(value) || value.length !== 5) {
    valid = false;
  }

  return valid;
}

export function validateNumber(value) {
  return /^\d+$/.test(value);
}

export function validateContractStartDate(value) {
  const chosenDate = new Date(value);
  const now = new Date();
  const msBetweenDates = Math.abs(chosenDate.getTime() - now.getTime());
  const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

  return daysBetweenDates > 365;
}

export function fieldIsEmpty(val) {
  // Also handles fields with numeric value
  // eg 0 is still valid
  if (val === undefined || val === null || val === '') {
    return true;
  }
  return false;
}

export function haveSameSwapAddressSelected(
  applicants,
  selectedApplicantIndex,
  selectedPossessionIndex
) {
  const activeMemberSwapAddresses = getActiveMemberSwapAddresses(
    applicants,
    selectedApplicantIndex
  );

  const activeApplicant = applicants[selectedApplicantIndex];
  const nextApplicantDetails =
    activeApplicant.swapDetails[selectedPossessionIndex + 1];
  const previousApplicantDetails =
    activeApplicant.swapDetails[selectedPossessionIndex - 1];

  if (activeMemberSwapAddresses.length === 1) {
    return false;
  }

  if (nextApplicantDetails) {
    return (
      activeApplicant.swapDetails[selectedPossessionIndex].apartmentIndex ===
      nextApplicantDetails.apartmentIndex
    );
  }

  if (previousApplicantDetails) {
    return (
      activeApplicant.swapDetails[selectedPossessionIndex].apartmentIndex ===
      previousApplicantDetails.apartmentIndex
    );
  }

  return false;
}

export function validateFullName(value) {
  const parts = value.split(' ');
  if (parts.length < 2) {
    return false;
  }

  return true;
}

export function validateOrganizationNumber(orgNr) {
  const orgNrRegex = /^[0-9]{6}-[0-9]{4}$/;
  if (!orgNrRegex.test(orgNr)) {
    return false;
  }

  const numStr = orgNr.replace('-', '');

  const luhnSum = numStr
    .split('')
    .map(Number)
    .reverse()
    .map((num, idx) => (idx % 2 === 1 ? num * 2 : num))
    .map(num => (num > 9 ? num - 9 : num))
    .reduce((sum, num) => sum + num, 0);

  return luhnSum % 10 === 0;
}

export default {
  notEmpty,
  validEmail,
  validatePersonalNumber,
  validatePostalCode,
  validateNumber,
  validateContractStartDate,
  validateFullName,
  validateOrganizationNumber
};
