<template>
  <Summary
    v-if="show"
    :title="readableName"
    :category="categoryId"
    :is-approved="isApproved"
    :has-empty-info-fields="categoryHasErrors || !!rejectionMessage"
    :reject-reason="rejectionMessage"
    @click="$emit('goToCategory', $event)"
  >
    <SummaryBody :sub-heading="name">
      <DocumentsGrid
        v-if="documents?.length"
        :documents="documents"
        :downloading-uuids="downloadingUuids"
        @fileClick="handleOpenFile"
      />
      <i v-else :class="{ red: !isApproved }">Inga uppladdade filer</i>
    </SummaryBody>
  </Summary>
</template>

<script>
import { mapGetters } from 'vuex';
import baFileOpener from '@/utils/baFileOpener';

import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import DocumentsGrid from '@/components/DocumentsGrid.vue';

export default {
  components: {
    Summary,
    SummaryBody,
    DocumentsGrid
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    categoryId: {
      type: String,
      required: true
    },
    categoryHasErrors: {
      type: Boolean,
      required: true
    },
    rejectionMessage: {
      type: String,
      default: ''
    },
    applicantId: {
      type: String,
      required: true
    },
    documents: {
      type: Array,
      required: true
    },
    isApproved: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      downloadingUuids: []
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile'
    }),
    show() {
      if (this.categoryId !== 'OTHER') {
        return true;
      }
      return !!this.documents.length;
    },
    readableName() {
      return (
        {
          CREDIT_INFORMATION: 'Min UC',
          FAMILY_CERTIFICATE: 'Familjebevis',
          INCOME_BASIS: 'Inkomstunderlag',
          LEASE_AGREEMENT: 'Hyresavtal',
          RENTAL_INVOICE: 'Aktuell hyra',
          OTHER: 'Övriga dokument'
        }[this.categoryId] || this.categoryId
      );
    }
  },
  methods: {
    async handleOpenFile(uuid) {
      const applicantId = this.applicantId;
      const isMobile = this.isMobile;
      this.downloadingUuids.push(uuid);
      await baFileOpener(applicantId, uuid, isMobile);
      this.downloadingUuids = this.downloadingUuids.filter(
        downloadId => downloadId !== uuid
      );
    }
  }
};
</script>

<style scoped lang="scss">
.red {
  color: var(--color-red);
}

.summary-splitter {
  display: contents;
  &.active {
    display: flex;
    gap: 1rem;
    .summary-wrapper {
      width: 50%;
    }
    :deep(.summary-field-wrapper) {
      justify-content: space-between;
      display: block;
      :deep(p) {
        width: 100%;
      }
    }
    @include mobile {
      display: contents;
      .summary-wrapper {
        width: 100%;
      }
    }
  }
}
</style>
