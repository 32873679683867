import { getGenitive } from '@/utils/genitive';
import { getFirstWord } from '@/utils/common';

function linkEnabled({
  statusName,
  isOwner,
  reason = {},
  hasInvitee,
  isPrivateFlow
}) {
  let enabled = true;
  let disabledReason = '';
  if (hasInvitee) {
    if (isOwner) {
      if (['SUBTENANT_INFORMATION', 'INVITEE_DOCUMENTS'].includes(statusName)) {
        enabled = false;
        disabledReason = 'NOT_ALLOWED';
      }
    } else {
      if (
        [
          'PERSONAL_INFORMATION',
          'APARTMENT',
          'REASON',
          'OWNER_DOCUMENTS'
        ].includes(statusName)
      ) {
        enabled = false;
        disabledReason = 'NOT_ALLOWED';
      }
    }
  }
  if (
    ['OWNER_DOCUMENTS', 'INVITEE_DOCUMENTS'].includes(statusName) &&
    (isPrivateFlow ? !reason.type : !reason.text)
  ) {
    enabled = false;
  }
  return { enabled, disabledReason };
}

function getFirstNameGenitive(fullName) {
  return getGenitive(getFirstWord(fullName));
}

export default {
  hasInvitee(state) {
    return !!state.application?.invitedUserId;
  },
  isPrivateFlow(state) {
    return !state.routerBase.includes('foretag');
  },
  filteredMenu(state, getters) {
    const blackList = ['DATA_SUMMARY', 'STATUS'];

    return getters.menu.filter(item => !blackList.includes(item.statusName));
  },
  menu(state, getters) {
    const applicationIsEditable = getters.applicationIsEditable;
    const reason = state.application?.reason || {};
    const currentTenant = state.application?.currentTenants[0] || {};
    const secondaryTenant = state.application?.secondaryTenants[0] || {};
    const currentTenantName = currentTenant?.name;
    const secondaryTenantName = secondaryTenant?.name;
    const isPrivateFlow = getters.isPrivateFlow;

    const menu = [
      {
        label: currentTenantName
          ? `${getFirstNameGenitive(currentTenantName)} uppgifter`
          : 'Överlåtarens uppgifter',
        url: '/uppgifter',
        key: 'currentTenants',
        statusName: 'PERSONAL_INFORMATION'
      },
      {
        label: currentTenantName
          ? `${getFirstNameGenitive(currentTenantName)} ${isPrivateFlow ? 'lägenhet' : 'lokal'}`
          : `Överlåtarens ${isPrivateFlow ? 'lägenhet' : 'lokal'}`,
        url: '/lagenhet',
        key: 'apartment',
        statusName: 'APARTMENT'
      },
      {
        label: 'Skäl',
        url: '/skal',
        key: 'reason',
        statusName: 'REASON'
      },
      {
        label: currentTenantName
          ? `${getFirstNameGenitive(currentTenantName)} filer`
          : 'Överlåtarens filer',
        url: '/overlatarens-dokument',
        key: 'ownerDocuments',
        statusName: 'OWNER_DOCUMENTS'
      },
      {
        label: secondaryTenantName
          ? `${getFirstNameGenitive(secondaryTenantName)} uppgifter`
          : 'Övertagarens uppgifter',
        url: '/andrahandshyresgast',
        key: 'secondaryTenants',
        statusName: 'SUBTENANT_INFORMATION'
      },
      {
        label: secondaryTenantName
          ? `${getFirstNameGenitive(secondaryTenantName)} filer`
          : 'Övertagarens filer',
        url: '/forvarvarens-dokument',
        key: 'inviteeDocuments',
        statusName: 'INVITEE_DOCUMENTS'
      },
      {
        label: 'Förhandsgranska',
        url: '/granska',
        key: '',
        statusName: 'DATA_SUMMARY'
      },
      {
        label: 'Vad händer nu?',
        url: '/status',
        key: '',
        statusName: 'STATUS'
      }
    ];

    return menu
      .filter(item => {
        if (item.statusName === 'STATUS' && applicationIsEditable) {
          return false;
        }
        return true;
      })
      .map(item => {
        const { enabled, disabledReason } = linkEnabled({
          statusName: item.statusName,
          isOwner: getters.isOwner,
          reason,
          hasInvitee: getters.hasInvitee,
          isPrivateFlow
        });
        return {
          ...item,
          enabled,
          disabledReason
        };
      });
  },
  isOwner(state) {
    if (!state.application) {
      return false;
    }
    return state.application.isOwner;
  },
  applicationIsSentAndApproved(state) {
    if (!state.application) {
      return false;
    }
    return (
      state.application.status === 'SENT' &&
      state.application.landlordStatus === 'APPROVED'
    );
  },
  applicationIsSentAndArchived(state) {
    if (!state.application) {
      return false;
    }

    const archivedStatuses = [
      'CLOSED',
      'REJECTED',
      'ABORTED',
      'REMOVED',
      'PENDING_REMOVE'
    ];

    return (
      state.application.status === 'SENT' &&
      archivedStatuses.includes(state.application.landlordStatus)
    );
  },
  applicationIsEditable(state) {
    if (!state.application) {
      return false;
    }
    return state.application.status === 'WAITING';
  },
  applicationCategoryRejected(state, getters) {
    return getters.rejectedCategories.some(category => !!category);
  },
  hasReadInfo(state) {
    return state.application?.hasReadInfo || false;
  },
  rejectedCategories(state) {
    if (!state.application) {
      return [];
    }

    const categories = [
      'currentTenants',
      'apartment',
      'reason',
      'computedDocumentsGroups',
      'inviteeComputedDocumentsGroups',
      'secondaryTenants',
      'invitee_documents',
      'owner_documents'
    ];

    return categories.filter(property => {
      const category = ['invitee_documents', 'owner_documents'].includes(
        property
      )
        ? state.application['documents']
        : state.application[property];

      if (!category) {
        return false;
      }

      if (
        property === 'computedDocumentsGroups' ||
        property === 'inviteeComputedDocumentsGroups'
      ) {
        return Object.values(category || {}).some(
          doc => doc.status === 'REJECTED'
        );
      }

      if (Array.isArray(category)) {
        if (['invitee_documents', 'owner_documents'].includes(property)) {
          if (property === 'owner_documents') {
            return category
              .filter(doc => doc.categoryType === 'transferor')
              .some(cat => cat.status === 'REJECTED');
          } else {
            return category
              .filter(doc => doc.categoryType === 'transferee')
              .some(cat => cat.status === 'REJECTED');
          }
        }
        return category.some(cat => cat.status === 'REJECTED');
      }
      return category.status === 'REJECTED';
    });
  },
  categoryRejected: (state, getters) => category => {
    if (!state.application) {
      return [];
    }

    return !!getters.rejectedCategories.find(item => item === category);
  },
  requiredDocuments(state) {
    if (!state.application?.computedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.computedDocumentsGroups)
      .filter(([, props]) => props.required === 1)
      .map(item => item[0]);
  },
  noneRequiredDocuments(state) {
    if (!state.application?.computedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.computedDocumentsGroups)
      .filter(([, props]) => props.required === 0)
      .map(item => item[0]);
  },
  requiredInviteeDocuments(state) {
    if (!state.application?.inviteeComputedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.inviteeComputedDocumentsGroups)
      .filter(([, props]) => props.required === 1)
      .map(item => item[0]);
  },
  noneInviteeRequiredDocuments(state) {
    if (!state.application?.inviteeComputedDocumentsGroups) {
      return [];
    }
    return Object.entries(state.application.inviteeComputedDocumentsGroups)
      .filter(([, props]) => props.required === 0)
      .map(item => item[0]);
  }
};
