<template>
  <SubletPage v-if="apartment" :categories="[apartment]" :title="title">
    <OvForm
      :is-loading="savingApplicationData"
      :form-has-changed="formHasChanged"
      :form-type="formType"
      v-on="$listeners"
    >
      <!-- <Fieldset class="transfer-types">
        <RadioButton
          v-for="transferType in transferTypeReasons"
          :key="transferType.value"
          v-model="apartment.transferType"
          :radio-btn-label="transferType.label"
          :radio-btn-value="transferType.value"
          :selected-value="apartment.transferType"
          :is-disabled="editingDisabled"
          @change="handleRadioBtnSelected"
        />
      </Fieldset> -->
      <FieldsWrapper>
        <OvInput
          v-model="apartment.address"
          :label="isPrivateFlow ? 'Adress' : 'Lokalens adress'"
          :placeholder="isPrivateFlow ? 'Adress' : 'Lokalens adress'"
          :description="
            isPrivateFlow
              ? 'Adressen för lägenheten du ska överlåta'
              : 'Ange lokalens adress'
          "
        />
        <Fieldset>
          <OvPostalCodeSearchBar
            v-model="apartment.postalCode"
            :class="{ autocomplete: postalCodeCompleted }"
            label="Postnummer"
            placeholder="Postnummer"
            :description="
              isPrivateFlow
                ? 'Postnumret för lägenheten du ska överlåta'
                : 'Postnumret för lokalen'
            "
            :validation="validatePostalCode"
            error-text="Postnummer måste anges med endast 5 siffror"
            @input="postalCodeCompleted = false"
            @dropdownMatch="postalCodeDropdownMatch"
          />
          <OvInput
            v-model="apartment.city"
            :class="{ autocomplete: postalCodeCompleted }"
            label="Ort"
            placeholder="Ort"
            :description="
              isPrivateFlow
                ? 'Orten för lägenheten du ska överlåta'
                : 'Orten för lokalen'
            "
            @input="postalCodeCompleted = false"
          />
        </Fieldset>
        <template v-if="isPrivateFlow">
          <OvInput
            v-model="apartment.objectNumber"
            label="Objektnummer/kontraktsnummer"
            placeholder="Objektnummer/kontraktsnummer"
            description="Objektnummer/kontraktsnummer för lägenheten du ska överlåta"
          >
            <InfoButtonModal title="Objektnummer/kontraktsnummer">
              <p>
                Objektsnumret är det nummer som din lägenhet har i hyresvärdens
                system. Det består ofta av åtta siffror xxxx-xxxx, där de första
                fyra siffrorna är relaterade till den fastighet du bor i och de
                fyra sista visar vilken lägenhet det är.
              </p>

              Objektsnumret hittar du oftast på:
              <ul>
                <li>Utsidan av din lägenhetsdörr</li>
                <li>Namntavlan i ditt trapphus</li>
                <li>Hyreskontraktet eller på hyresräkningen</li>
              </ul>
            </InfoButtonModal>
          </OvInput>
          <Fieldset>
            <OvInput
              v-model="apartment.apartmentNumber"
              label="Lägenhetsnummer"
              placeholder="Lägenhetsnummer"
              description="Lägenhetsnumret för lägenheten du ska överlåta"
              error-text="Fältet får endast innehålla siffror"
              :validation="validateNumber"
              inputmode="numeric"
              pattern="[0-9]*"
            />
            <OvInput
              v-model="apartment.rent"
              label="Nuvarande hyra"
              placeholder="Nuvarande hyra"
              description="Ange hyran som är fastställd av hyresvärden"
              error-text="Fältet får endast innehålla siffror"
              :validation="validateNumber"
              inputmode="numeric"
              pattern="[0-9]*"
            />
          </Fieldset>
        </template>
        <template v-else>
          <OvInput
            v-model="apartment.contractNumber"
            label="Hyreskontraktnr."
            placeholder="Hyreskontraktnr."
            description="Hyreskontraktnr. för lokalen"
        /></template>
        <OvInput
          v-model="contractStartDate"
          :disabled="editingDisabled"
          label="Innehaft sedan"
          placeholder="Innehaft sedan"
          :description="
            isPrivateFlow
              ? 'Ange datumet du tog över lägenheten'
              : 'Ange datumet lokalen togs över'
          "
          type="date"
          :max="getCurrentDate"
        />
      </FieldsWrapper>

      <template v-if="isPrivateFlow">
        <template v-for="ovObject in ovObjects">
          <div
            :key="`container-${ovObject.property}`"
            class="extra-object-container"
          >
            <transition-group name="pop-in" tag="div" mode="out-in">
              <OvExtraObject
                v-for="(item, index) in apartment[ovObject.property]"
                :key="`${item.id}-${index}`"
                :btn-text="ovObject.texts.remove"
                :title="`${ovObject.texts.title} ${index + 1}`"
                :disabled="editingDisabled"
                :address-description="ovObject.texts.adressDescription"
                :object-number-description="
                  ovObject.texts.objectNumberDescription
                "
                :address="item.address"
                :object-number="item.objectNumber"
                @click="handleObject(ovObject.property, index)"
                @update:address="
                  apartment[ovObject.property][index].address = $event
                "
                @update:objectNumber="
                  apartment[ovObject.property][index].objectNumber = $event
                "
              />
            </transition-group>
          </div>
          <LargeExpandButton
            :key="`button-${ovObject.property}`"
            :is-disabled="editingDisabled"
            @click="handleObject(ovObject.property)"
            >{{
              apartment[ovObject.property].length > 0
                ? `${ovObject.texts.add} ${apartment[ovObject.property].length + 1}`
                : ovObject.texts.add
            }}</LargeExpandButton
          >
        </template>
      </template>
    </OvForm>
  </SubletPage>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  validEmail,
  validateNumber,
  validatePostalCode
} from '@/utils/input-validations';
import { getCurrentDate } from '@/utils/common';

import Fieldset from '@/components/Fieldset.vue';
import OvInput from '@/components/OvInput.vue';
import OvForm from '@/components/OvForm.vue';
import SubletPage from '@/components/SubletPage.vue';
import InfoButtonModal from '@/components/InfoButtonModal.vue';
import OvExtraObject from '@/components/OvExtraObject.vue';
import LargeExpandButton from '@/components/LargeExpandButton.vue';
import FieldsWrapper from '@/components/FieldsWrapper.vue';
import OvPostalCodeSearchBar from '@/components/OvPostalCodeSearchBar.vue';

export default {
  name: 'Apartment',
  components: {
    Fieldset,
    OvInput,
    OvForm,
    SubletPage,
    InfoButtonModal,
    OvExtraObject,
    LargeExpandButton,
    FieldsWrapper,
    OvPostalCodeSearchBar
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    savingApplicationData: {
      type: Boolean,
      required: true
    },
    formHasChanged: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      validEmail,
      validateNumber,
      validatePostalCode,
      autoCompletePostalCodeResult: [],
      isSearchingPostalCode: false,
      postalCodeCompleted: false,
      formType: 'apartment',
      getCurrentDate,
      ovObjects: [
        {
          property: 'parkings',
          texts: {
            title: 'Parkering',
            add: 'Lägg till parkering',
            remove: 'Ta bort parkering',
            adressDescription: 'Adressen för parkeringen',
            objectNumberDescription: 'Objektnumret för parkeringen'
          }
        },
        {
          property: 'garages',
          texts: {
            title: 'Garage',
            add: 'Lägg till garageplats',
            remove: 'Ta bort garageplats',
            adressDescription: 'Adressen för garageplatsen',
            objectNumberDescription: 'Objektnumret för garageplatsen'
          }
        },
        {
          property: 'storages',
          texts: {
            title: 'Förråd',
            add: 'Lägg till förråd',
            remove: 'Ta bort förråd',
            adressDescription: 'Adressen för förrådet',
            objectNumberDescription: 'Objektnumret för förrådet'
          }
        }
      ]

      // transferTypeReasons: [
      //   {
      //     label: 'Bostadslägenhet',
      //     value: 'apartment'
      //   },
      //   {
      //     label: 'Parkering',
      //     value: 'parking'
      //   },
      //   {
      //     label: 'Garage',
      //     value: 'garage'
      //   },
      //   {
      //     label: 'Förråd',
      //     value: 'storage'
      //   }
      // ],
      // objectTypes: {
      //   apartment: 'bostadslägenheten',
      //   parking: 'parkeringen',
      //   garage: 'garaget',
      //   storage: 'förrådet'
      // }
    };
  },

  computed: {
    ...mapGetters({
      applicationIsEditable: 'ovApplication/applicationIsEditable',
      rejectedCategories: 'ovApplication/rejectedCategories',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),
    contractStartDate: {
      get() {
        if (!this.apartment?.contractStartDate) {
          return '';
        }
        return new Date(this.apartment.contractStartDate).toLocaleDateString(
          'sv-SE'
        );
      },
      set(value) {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (value.match(regEx)) {
          this.apartment.contractStartDate = new Date(value)
            .toISOString()
            .split('T')[0];
        }
      }
    },
    title() {
      // if (this.apartment.transferType) {
      //   return `Beskriv ${this.objectTypes[this.apartment.transferType]} som ska överlåtas`;
      // }
      if (!this.isPrivateFlow) {
        return 'Beskriv lokalen som ska överlåtas';
      }
      return 'Beskriv lägenheten som ska överlåtas';
    },
    apartmentCategoryRejected() {
      return this.rejectedCategories.includes('apartment');
    },
    editingDisabled() {
      return !this.applicationIsEditable && !this.apartmentCategoryRejected;
    },
    apartment: {
      get() {
        return this.application?.apartment;
      },
      set() {
        this.$emit();
      }
    }
  },
  methods: {
    // handleRadioBtnSelected(value) {
    //   this.apartment.transferType = value;
    //   this.emitEditing();
    // },
    postalCodeDropdownMatch(match) {
      this.$emit('isEditing', this.formType);
      this.apartment.postalCode = match.name;
      this.apartment.city = match.description;
      this.postalCodeCompleted = true;
    },
    handleObject(name, index) {
      if (index !== null && index !== undefined) {
        this.apartment[name].splice(index, 1);
      } else {
        this.apartment[name].push({
          createdAt: Date.now(),
          address: '',
          objectNumber: ''
        });
      }

      this.emitEditing();
    },
    emitEditing() {
      this.$emit('isEditing', this.formType);
    }
  }
};
</script>

<style lang="scss" scoped>
.autocomplete :deep(input) {
  background-color: #e8f0fe;
}
.furnished-checkbox {
  margin-bottom: 0;
  @include mobile {
    margin-bottom: 4.8rem;
  }
}

.transfer-types {
  gap: 1rem;
  margin-bottom: 1rem;
}

.extra-object-container {
  > *:nth-last-child(1) {
    margin-bottom: 1rem;
  }
}
</style>
