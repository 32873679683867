<template>
  <div>
    <InfoModal
      v-if="confirmModalVisible"
      title="Spara ändringar?"
      confirm-label="Spara och fortsätt"
      abort-label="Spara inte"
      :is-disabled="isSaving"
      @click="confirmModalClick"
    >
      <p>
        Du har gjort ändringar på sidan. Vill du spara din ändringar innan du
        fortsätter?
      </p>
    </InfoModal>
    <InfoModal
      v-if="errorModalVisible"
      title="Något gick fel"
      abort-label="Stäng"
      @click="errorModalVisible = false"
    >
      <p>Det gick inte att spara dina ändringar, försök igen senare.</p>
    </InfoModal>
    <router-view
      v-if="clonedApplication"
      :application="clonedApplication"
      :saving-application-data="isSaving"
      :form-has-changed="formHasChanged"
      @subletFormSubmit="handleFormSubmit"
      @isEditing="handleEditingForm"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InfoModal from '@/components/InfoModal.vue';

export default {
  name: 'SubletApplicationManager',
  components: {
    InfoModal
  },
  beforeRouteUpdate(to, from, next) {
    //Leaving to new route without unmounting
    this.handleRouteLeave(next);
  },
  beforeRouteLeave(to, from, next) {
    //Leaving to new route and unmounting
    this.handleRouteLeave(next);
  },
  data() {
    return {
      formHasChanged: '',
      clonedApplication: null,
      isSaving: false,
      confirmModalVisible: false,
      errorModalVisible: false,
      goNextRoute: () => false
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    })
  },
  async created() {
    this.syncData();
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNativeLeave);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNativeLeave);
  },
  methods: {
    ...mapActions({
      editCurrentTenants: 'subletApplication/editCurrentTenants',
      editApartment: 'subletApplication/editApartment',
      editSecondaryTenants: 'subletApplication/editSecondaryTenants',
      editReason: 'subletApplication/editReason',
      goToNextCategory: 'subletApplication/goToNextCategory'
    }),
    filterReadOnlyProps(category) {
      //  filter readOnly props, keep the rest
      //  eslint-disable-next-line
      const { rejectReason, status, ...rest } = category;
      return rest;
    },
    syncData() {
      this.clonedApplication = JSON.parse(JSON.stringify(this.application));
    },
    async confirmModalClick(eventType) {
      switch (eventType) {
        case 'CONFIRM':
          await this.handleFormSubmit();
          this.closeModalAndGoNext();
          break;
        case 'REJECT':
          this.syncData();
          this.formHasChanged = '';
          this.closeModalAndGoNext();
          break;
        default:
          this.confirmModalVisible = false;
      }
    },
    closeModalAndGoNext() {
      this.confirmModalVisible = false;
      this.goNextRoute();
    },
    handleRouteLeave(next = () => false) {
      if (this.formHasChanged) {
        this.confirmModalVisible = true;
        this.goNextRoute = next;
      } else {
        next();
      }
    },
    handleEditingForm(isEditing) {
      this.formHasChanged = isEditing;
    },
    async handleFormSubmit() {
      this.isSaving = true;
      try {
        switch (this.$route.name) {
          case 'currentTenants':
            await this.editCurrentTenants({
              applicationId: this.application.id,
              currentTenants: this.clonedApplication.currentTenants.map(
                currentTenant => this.filterReadOnlyProps(currentTenant)
              )
            });
            break;
          case 'apartment':
            await this.editApartment({
              applicationId: this.application.id,
              apartment: this.filterReadOnlyProps(
                this.clonedApplication.apartment
              )
            });
            break;
          case 'secondaryTenants':
            await this.editSecondaryTenants({
              applicationId: this.application.id,
              secondaryTenants: this.clonedApplication.secondaryTenants.map(
                secondaryTenant => this.filterReadOnlyProps(secondaryTenant)
              )
            });
            break;
          case 'reason':
            await this.editReason({
              applicationId: this.application.id,
              reason: this.filterReadOnlyProps(this.clonedApplication.reason)
            });
            break;
          default:
            console.log('No match for', this.$route.name);
        }
        this.syncData();
        this.formHasChanged = '';
        await this.goToNextCategory(this.$router);
      } catch (err) {
        console.error(err);
        this.errorModalVisible = true;
      }
      this.isSaving = false;
    },
    preventNativeLeave(event) {
      if (!this.formHasChanged) {
        return;
      }
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }
  }
};
</script>

<style></style>
