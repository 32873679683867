<template>
  <div class="landing-page">
    <div class="content">
      <h1>{{ title }}</h1>

      <p class="ingress">
        Slipp allt krångel med blanketter och jobbiga skrivare. Sköt din
        överlåtelse direkt i vår tjänst.
      </p>
      <p>
        Behöver du hjälp med er ansökan? Kontakta oss på
        <a
          :href="`mailto:${getSupportAddress}`"
          class="mail-to"
          target="_blank"
          >{{ getSupportAddress }}</a
        >.
      </p>
      <BankidLogin @initiate="$emit('initiate')">
        <template slot="header">Starta ny ansökan / Logga in</template>
      </BankidLogin>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BankidLogin from '@/components/BankidLogin.vue';
import svgs from '@/utils/icon-constants';

export default {
  components: {
    BankidLogin
  },

  data() {
    return {
      svgs
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'app/isMobile',
      getSupportAddress: 'app/getSupportAddress',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),
    title() {
      if (!this.isPrivateFlow) {
        return 'Välkommen till Överlåtelser för företagsärenden.';
      }
      return 'Välkommen till Överlåtelser för privata ärenden.';
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2.5rem;
  line-height: 1.6;
  h1 {
    font-size: 3.2rem;
    line-height: 1.2;
    @include mobile {
      font-size: 2.4rem;
    }
  }
  p {
    font-size: 1.6rem;
    margin: 2rem 0;
    line-height: 1.5;
    @include mobile {
      font-size: 1.5rem;
      margin: 1rem 0;
    }
  }
  .ingress {
    font-weight: 600;
  }
  .mail-to {
    color: var(--color-blue);
  }
}

.login-view-body {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  width: 500px;
  max-width: 95%;
  padding: 35px;
  position: relative;
}

.inline-login {
  max-width: 500px;

  background: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1.4rem 2rem 2rem;
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.ov-choice {
  width: 100%;
}

.ov-choice-label {
  display: block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 2px solid var(--color-dark);
  color: var(--color-dark);
  background-color: #fff;
  &:hover {
    background-color: #e6f0ff;
    color: var(--color-dark);
  }
}

.ov-choice-input {
  display: none;
  &:checked + .ov-choice-label {
    background-color: var(--color-dark);
    color: #fff;
  }
}

.ov-choices {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.login-button {
  font-size: 3.2rem;
  border-radius: 1rem;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid var(--color-blue);
  color: var(--color-blue);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  outline: none;
  padding: 2rem 3rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.login-button:hover {
  background-color: var(--color-blue);
  color: #fff;
}
</style>
