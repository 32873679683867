export default {
  setTooltips(state, tooltips) {
    state.tooltips = tooltips;
  },
  clearSyncedTooltips(state, reference) {
    const index = state.tooltips
      .map(tooltip => tooltip.reference)
      .indexOf(reference);

    if (index >= 0) {
      state.tooltips.splice(index, 1);
    }
  },
  markTooltipAsSeen(state, reference) {
    const index = state.tooltips
      .map(tooltip => tooltip.reference)
      .indexOf(reference);

    if (index >= 0) {
      state.tooltips[index].seen = true;
    }
  }
};
