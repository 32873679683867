import { render, staticRenderFns } from "./SupportButton.vue?vue&type=template&id=096d023e&scoped=true"
import script from "./SupportButton.vue?vue&type=script&lang=js"
export * from "./SupportButton.vue?vue&type=script&lang=js"
import style0 from "./SupportButton.vue?vue&type=style&index=0&id=096d023e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096d023e",
  null
  
)

export default component.exports