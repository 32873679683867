<template>
  <div>
    <InfoButton
      :active="modalVisible"
      :disabled="disabled"
      @click="showModal(true)"
    />
    <InfoModal v-if="modalVisible" :title="title" @click="showModal(false)">
      <slot />
    </InfoModal>
  </div>
</template>

<script>
import InfoButton from '@/components/InfoButton.vue';
import InfoModal from '@/components/InfoModal.vue';

export default {
  name: 'InfoButtonModal',
  components: { InfoButton, InfoModal },
  props: {
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      modalVisible: false
    };
  },
  methods: {
    showModal(show) {
      this.modalVisible = show;
    }
  }
};
</script>

<style scoped lang="scss">
div {
  display: inline-block;
}
</style>
