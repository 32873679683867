import Vue from 'vue';
import Router from 'vue-router';
import OvManager from '@/components/OvManager.vue';
import CurrentTenants from '@/views/overlatelser/CurrentTenants.vue';
import Apartment from '@/views/overlatelser/Apartment.vue';
import Reason from '@/views/overlatelser/Reason.vue';
import SecondaryTenants from '@/views/overlatelser/SecondaryTenants.vue';
import DataSummary from '@/views/overlatelser/DataSummary/DataSummary.vue';
import OwnerDocuments from '@/views/overlatelser/OwnerDocuments.vue';
import InviteeDocuments from '@/views/overlatelser/InviteeDocuments.vue';
import Status from '@/views/overlatelser/Status.vue';
import Login from '@/views/overlatelser/Login.vue';

Vue.use(Router);

export default function createOvRouter(store) {
  const path = window.location.pathname;
  let base = '/privat';

  if (path.startsWith('/foretag')) {
    base = '/foretag';
  }

  store.commit('ovApplication/setRouterBase', base);

  const router = new Router({
    mode: 'history',
    base,
    routes: [
      {
        path: '/uppgifter',
        component: OvManager,
        children: [
          {
            path: '',
            component: CurrentTenants,
            name: 'currentTenants',
            meta: {
              owner: 'transferor'
            }
          },
          {
            path: '/lagenhet',
            component: Apartment,
            name: 'apartment',
            meta: {
              owner: 'transferor'
            }
          },
          {
            path: '/skal',
            component: Reason,
            name: 'reason',
            meta: {
              owner: 'transferor'
            }
          },
          {
            path: '/andrahandshyresgast',
            component: SecondaryTenants,
            name: 'secondaryTenants',
            meta: {
              owner: 'transferee'
            }
          }
        ]
      },
      {
        path: '/granska',
        component: DataSummary
      },
      {
        path: '/overlatarens-dokument',
        component: OwnerDocuments,
        meta: {
          owner: 'transferor'
        }
      },
      {
        path: '/forvarvarens-dokument',
        component: InviteeDocuments,
        meta: {
          owner: 'transferee'
        }
      },
      {
        path: '/status',
        component: Status
      },
      {
        path: '/logga-in',
        component: Login
      },
      {
        path: '/',
        redirect: '/uppgifter'
      },
      { path: '*', redirect: '/' }
    ],
    scrollBehavior() {
      return { x: 0, y: 0, behavior: 'smooth' };
    }
  });

  router.beforeEach((to, from, next) => {
    const store = router.app.$store;
    const pathWithoutBase = to.path.replace(`/${base.replace('/', '')}`, '');
    if (
      !to.path.startsWith(`/${base.replace('/', '')}`) &&
      pathWithoutBase !== to.path
    ) {
      return next(`/${base.replace('/', '')}${to.path}`);
    }

    const isOwner = store?.state?.ovApplication?.application?.isOwner;
    const hasInvitee =
      !!store?.state?.ovApplication?.application?.invitedUserId;
    if (hasInvitee) {
      if (to.meta.owner === 'transferor' && !isOwner) {
        return next('/andrahandshyresgast');
      } else if (to.meta.owner === 'transferee' && isOwner) {
        return next('/uppgifter');
      }
    }

    next();
  });

  // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
  const originalPush = Router.prototype.push;

  Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => {
      if (Router.isNavigationFailure(err)) {
        // resolve err
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  return router;
}
