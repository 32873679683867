import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  application: null,
  applicationId: null,
  ovApplications: [],
  applicationType: '',
  renewalModalVisible: false
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
