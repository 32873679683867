import applicationService from '@/services/applicationService';
import cloneDeep from 'lodash.clonedeep';

async function fetchApplicationIdFromInvite(ctx, inviteCode) {
  const application =
    await applicationService.getApplicationFromInvite(inviteCode);

  if (application?.applicationId) {
    ctx.commit('setApplicationId', application.applicationId);
  }
}

async function configureSupportAddress(ctx) {
  const subdomain = await ctx.dispatch('app/getSubdomain', null, {
    root: true
  });
  if (subdomain && subdomain === 'formular') {
    ctx.commit('app/setSupportAddress', 'bytesansokan@lagenhetsbyte.se', {
      root: true
    });
  }
}

async function fetchSelectedApplication(ctx) {
  const applicationId = ctx.state.applicationId;
  if (!applicationId) {
    return false;
  }

  const application = await applicationService.getApplication(applicationId);
  if (!application) {
    localStorage.removeItem('applicationId');
    return false;
  }
  ctx.commit('setApplication', application);
  return application;
}

async function syncApplicationStatuses(ctx) {
  const applicantId = ctx.getters.applicant?.id;
  const application = ctx.state.application;

  if (!applicantId || !application) {
    return;
  }

  const statuses = await applicationService.getApplicationStatuses(applicantId);

  if (!statuses) {
    return;
  }

  for (const id in statuses.applicants) {
    const status = statuses.applicants[id];
    ctx.commit('setApplicantStatus', { id, status });
  }

  ctx.commit('setApplicationStatus', statuses.application);
}

function getApplicantById(ctx, applicantId) {
  return ctx.state.application.applicants.find(
    applicant => applicant.id === applicantId
  );
}

async function addDocument(
  ctx,
  { fileName, uuid, createdAt, category, applicantId, memberIndex }
) {
  // Do not use applicant getter since that could have changed (eg. tab click while async)
  // and have a different applicantId compared to the applicantId param
  const applicantCopy = cloneDeep(getApplicantById(ctx, applicantId));

  const newDocument = {
    memberId: applicantCopy.members[0].id,
    fileName,
    uuid: uuid,
    type: category,
    createdAt: createdAt,
    memberIndex
  };

  applicantCopy.newRejections.documentGroups[memberIndex][category] = null;
  applicantCopy.documentGroups[memberIndex][category].documents.push(
    newDocument
  );
  ctx.commit('updateApplicant', {
    applicantId: applicantId,
    newRejections: applicantCopy.newRejections,
    documentGroups: applicantCopy.documentGroups
  });
}

async function removeDocument(ctx, { type, uuid, applicantId }) {
  // Do not use applicant getter since that could have changed (eg. tab click while async)
  // and have a different applicantId compared to the applicantId param
  const applicant = getApplicantById(ctx, applicantId);
  const newDocumentGroups = applicant.documentGroups.map(documentGroup => {
    if (documentGroup[type].documents.find(doc => doc.uuid === uuid)) {
      return {
        ...documentGroup,
        [type]: {
          ...documentGroup[type],
          documents: documentGroup[type].documents.filter(x => x.uuid !== uuid)
        }
      };
    }
    return documentGroup;
  });

  ctx.commit('updateApplicant', {
    applicantId: applicantId,
    documentGroups: newDocumentGroups
  });
}

async function startApplicant(ctx, applicantId) {
  await applicationService.startApplicant(applicantId);
  if (ctx.getters.isOwnerOfAllApplicants) {
    ctx.state.application.applicants.forEach(applicant => {
      ctx.commit('updateApplicant', {
        applicantId: applicant.id,
        status: 'ACTIVE',
        started: true
      });
    });
  } else {
    ctx.commit('updateApplicant', {
      applicantId: applicantId,
      status: 'ACTIVE',
      started: true
    });
  }
}

async function submitApplicant(ctx, applicantId) {
  await applicationService.submitApplicant(applicantId);
  ctx.commit('updateApplicant', {
    applicantId: applicantId,
    status: 'TREATING'
  });
  if (
    ctx.state.application.applicants.every(
      applicant => applicant.status === 'TREATING'
    )
  ) {
    ctx.commit('setApplicationStatus', 'TREATING');
  }
}

async function goToNextSection(ctx, { router, listCheck = null }) {
  const nextValidApplicantIndex =
    ctx.rootGetters['application/nextValidApplicantIndex'];
  const selectedPossessionIndex =
    ctx.rootState.application.selectedPossessionIndex;

  if (selectedPossessionIndex < listCheck) {
    ctx.commit('setSelectedPossessionIndex', selectedPossessionIndex + 1);
  } else if (nextValidApplicantIndex > 0) {
    ctx.commit('setSelectedPossessionIndex', 0);
    ctx.commit('setSelectedApplicantIndex', nextValidApplicantIndex);
  } else {
    const nextPath = await ctx.dispatch('getNextPath', router);
    await router.push({
      path: nextPath
    });
  }
}

function getNextPath(ctx, router) {
  const currentIndex = ctx.getters.menuFlat.findIndex(
    item => item.url === router.currentRoute.path
  );

  const next = ctx.getters.menuFlat[currentIndex + 1];
  if (next) {
    return next.url;
  }

  return ctx.getters.menuFlat[0].url;
}

export default {
  removeDocument,
  fetchApplicationIdFromInvite,
  fetchSelectedApplication,
  addDocument,
  syncApplicationStatuses,
  startApplicant,
  submitApplicant,
  configureSupportAddress,
  goToNextSection,
  getNextPath
};
