<template>
  <section class="container">
    <nav
      :class="{
        active: visible
      }"
    >
      <StickyContainer>
        <div ref="mobileScrollContainer" class="links-wrapper">
          <slot />
        </div>
      </StickyContainer>
      <div class="nav-bg" @touchstart="$emit('close')"></div>
    </nav>
  </section>
</template>

<script>
import StickyContainer from '@/components/StickyContainer.vue';

export default {
  components: {
    StickyContainer
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.$refs.mobileScrollContainer.scroll(0, 0);
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
}

nav {
  font-family:
    Averta Std,
    Helvetica,
    Arial,
    sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: var(--side-menu-width);
  min-height: 100%;

  @include mobile {
    min-height: auto;
    height: calc(100vh - var(--header-height));
    position: fixed;
    top: var(--header-height);
    left: -100%;
    z-index: 9;
    background-color: #fff;
    width: 300px;
    visibility: hidden;
    box-shadow: 8px 12px 9px -2px rgb(0 0 0 / 30%);
    transition: 0.2s ease-in-out;
    will-change: left, visibility;
    .nav-bg {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      width: 50000%;
      background-color: #000;
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.2s;
    }
    &.active {
      left: 0;
      visibility: visible;
      .nav-bg {
        opacity: 0.4;
      }
    }
  }
}

.links-wrapper {
  padding: 3.2rem 10px 3.2rem 30px;
  font-size: 1.4rem;
  min-height: 100%;
  @include mobile {
    overflow: auto;
    padding: 0 10px 8rem 30px;
    max-height: calc(100vh - var(--header-height));
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
