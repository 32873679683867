import applicationService from '@/services/applicationService';

export default {
  async fetchCompany(ctx, orgnr) {
    const parsedOrgnr = orgnr.split('-').join('');
    if (ctx.state.companies[parsedOrgnr]) {
      // We already have this cached
      return;
    }
    const company = await applicationService.getCompanyInfo(orgnr);
    ctx.commit('addCompany', company);
  }
};
