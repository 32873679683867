var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SwapDefaultPage',_vm._g({attrs:{"names":_vm.swapDetailsActiveMemberTitle,"possessions":_vm.swapDetailsMembersList},on:{"possessionClick":_vm.setSelectedPossessionIndex}},_vm.$listeners),[_c('RejectionMessageCard',{attrs:{"category":"SWAPDETAILS"}}),_c('CollapseCard',{staticClass:"mb-4",attrs:{"is-primary":"","initially-closed":""}},[_c('h3',{attrs:{"slot":"title-text"},slot:"title-text"},[_vm._v("Tänk på att")]),_c('template',{slot:"body-text"},[_c('p',[_vm._v(" Då byten i regel sker vid månadsskiften så är det endast möjligt att välja den första varje månad som önskat bytesdatum. Ett byte tar i snitt 6-8 veckor så det är därför inte möjligt att välja ett månadsskifte som inte ligger inom ramen för detta. ")]),_c('br'),_c('p',[_vm._v(" Exempel: Anmälning till Bytesansökan sker 5/1 och önskemålet är att flytta så snart som möjligt. Önskat bytesdatum på ansökningarna blir då 1/3. ")]),_c('br'),_c('p',[_c('b',[_vm._v("OBS! Detta är ett önskemål på inflyttningsdatum.")])])])],2),_c('transition',{attrs:{"name":"form","mode":"out-in"}},[_c('SwapForm',_vm._g({key:_vm.swapDetailsActiveMemberTitle,attrs:{"is-loading":_vm.savingApplicationData,"form-type":_vm.formType,"form-has-changed":_vm.formHasChanged}},_vm.$listeners),[(_vm.activeMemberSwapAddresses)?_c('Select',{attrs:{"selected-option":_vm.activeSelectedSwapAddress,"title":"Adress","description":`Ange den adress som ${_vm.swapDetailsActiveMemberTitle} kommer flytta till i detta byte`,"options":_vm.activeMemberSwapAddresses,"is-disabled":_vm.applicationIsSent || _vm.activeMemberSwapAddresses.length === 1,"info-tooltip":_vm.applicationIsSent
            ? 'Under granskning, det går inte att göra ändringar för tillfället'
            : '',"error-text":`${_vm.swapDetailsMembersList
          .map(member => member.title)
          .join(' & ')} kan inte flytta in i samma lägenhet`,"has-error":_vm.haveSameSwapAddressSelected},model:{value:(_vm.activeSelectedSwapAddress),callback:function ($$v) {_vm.activeSelectedSwapAddress=$$v},expression:"activeSelectedSwapAddress"}}):_vm._e(),_c('Fieldset',[_c('SwapInput',{attrs:{"disabled":_vm.savingApplicationData,"label":"Antal vuxna","placeholder":"Antal vuxna","description":"Ange antal vuxna som kommer flytta in","error-text":"Fältet får endast innehålla siffror","validation":_vm.validateNumber,"inputmode":"numeric","pattern":"[0-9]*"},model:{value:(_vm.activeSwapDetails.adultCount),callback:function ($$v) {_vm.$set(_vm.activeSwapDetails, "adultCount", $$v)},expression:"activeSwapDetails.adultCount"}}),_c('SwapInput',{attrs:{"disabled":_vm.savingApplicationData,"label":"Antal barn","placeholder":"Antal barn","description":"Ange antal barn som kommer flytta in","error-text":"Fältet får endast innehålla siffror","validation":_vm.validateNumber,"inputmode":"numeric","pattern":"[0-9]*"},model:{value:(_vm.activeSwapDetails.childCount),callback:function ($$v) {_vm.$set(_vm.activeSwapDetails, "childCount", $$v)},expression:"activeSwapDetails.childCount"}})],1),_c('transition',{attrs:{"name":"form","mode":"out-in"}},[_c('DatePicker',{key:_vm.activeApplicant.id,attrs:{"title":"Önskat bytesdatum","description":`
            Prata ihop dig med de andra parterna om vilket bytesdatum ni föredrar och välj det här. 
            Tänk på att du som huvudregel behöver ha bott i lägenheten i minst 1 år för att få byta enligt den nya hyreslagen.
            <br/>
            <b>OBS: Detta är endast önskat inflyttningsdatum. Det är upp till hyresvärden när det faktiska datumet för inflytt blir.</b>
          `,"selected-option":_vm.selectedDate || '',"options":_vm.getDates,"applicants-selected-dates":_vm.applicantsSelectedDates},on:{"click":_vm.setSelectedDate}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }