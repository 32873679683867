import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

let baseUrl = 'https://api.bytesansokan.se/portal/cognito';

if (location.host.includes('localhost')) {
  baseUrl = 'http://localhost:3001/portal/cognito';
} else if (
  location.host.includes('test') ||
  location.host.includes('formular-demo')
) {
  baseUrl = 'https://dev-api.bytesansokan.se/portal/cognito';
}

const apiEndpoint = baseUrl;

const apiInstance = axios.create({
  baseURL: apiEndpoint,
  withCredentials: true,
  'content-Type': 'application/json'
});

async function getAccessTokens(userId, refreshToken, maxRetries = 3) {
  let attempts = 0;
  let lastError = null;
  // Fix for Safari issue where first call seems to genereate "Network error"
  // This does some retries unless it is a explicit error like 401 or 500
  while (attempts < maxRetries) {
    try {
      attempts++;

      const res = await apiInstance.post('/refresh', { userId, refreshToken });

      return res.data;
    } catch (error) {
      lastError = error;

      if (error.response && error.response.status < 500) {
        // 4xx-fel -> no point in doing retry
        break;
      }

      await new Promise(r => setTimeout(r, 1000 * attempts));
    }
  }
  console.log('lasts error', lastError);
  throw lastError;
}

async function getRefreshToken(username, password) {
  const { data } = await apiInstance.post('/login', { username, password });
  return data;
}

async function updateUserEmail(userUuid, email, inviteCode) {
  const { data } = await apiInstance.post('/user/update-email', {
    uuid: userUuid,
    email,
    inviteCode
  });
  return data;
}

async function verifyEmail(userUuid, otp) {
  if (!userUuid) {
    userUuid = localStorage.getItem('userId');
  }
  const { data } = await apiInstance.post(`/user/verify-email/`, {
    userUuid,
    otp
  });
  return data;
}

async function resendVerification(userUuid) {
  if (!userUuid) {
    userUuid = localStorage.getItem('userId');
  }
  const { data } = await apiInstance.post('/user/resend-email-verification/', {
    userUuid
  });
  return data;
}

async function login(tokenDataOrUsername, password, bankidLogin) {
  try {
    let refreshToken;
    let userId;
    let emailVerified;

    if (bankidLogin) {
      refreshToken = tokenDataOrUsername.refreshToken;
      userId = tokenDataOrUsername.userId;
      emailVerified = tokenDataOrUsername.emailVerified;
    } else {
      const responseData = await getRefreshToken(tokenDataOrUsername, password);
      refreshToken = responseData.refreshToken;
      userId = responseData.userId;
      emailVerified = responseData.verifyEmail;
    }

    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('userId', userId);

    const { idToken, accessToken } = await getAccessTokens(
      userId,
      refreshToken
    );
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('idToken', idToken);

    return { success: true, emailVerified: emailVerified };
  } catch (error) {
    return { success: false, emailVerified: verifyEmail };
  }
}

function getExistingRefreshToken() {
  const params = new URL(document.location).searchParams;
  const token = params.get('auth');
  if (token) {
    localStorage.setItem('refreshToken', token);
    return token;
  }
  return localStorage.getItem('refreshToken');
}

function getExistingUserId() {
  const params = new URL(document.location).searchParams;
  const user = params.get('user');
  if (user) {
    localStorage.setItem('userId', user);
    return user;
  }
  return localStorage.getItem('userId');
}

function hasAuthParams() {
  const params = new URL(document.location).searchParams;
  const user = params.get('user');
  const token = params.get('auth');
  if (user && token) {
    return true;
  }
  return false;
}

async function refreshTokens() {
  try {
    const refreshToken = getExistingRefreshToken();
    const userId = getExistingUserId();

    if (!refreshToken || !userId) {
      return false;
    }

    const { idToken, accessToken } = await getAccessTokens(
      userId,
      refreshToken
    );
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('idToken', idToken);
  } catch (error) {
    return false;
  }

  return true;
}

async function validateSession() {
  try {
    const idToken = localStorage.getItem('idToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');

    await apiInstance.post(
      '/validate',
      {
        idToken,
        refreshToken,
        accessToken
      },
      { headers: { authorization: idToken } }
    );

    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

async function validateUser() {
  const token = await getToken();
  if (!token) {
    return false;
  }

  return await validateSession(token);
}

async function getToken() {
  if (isTokenValid() && !hasAuthParams()) {
    return localStorage.getItem('idToken');
  }

  const success = await refreshTokens();

  if (!success) {
    return null;
  }
  return localStorage.getItem('idToken');
}

function isTokenValid() {
  try {
    const token = localStorage.getItem('idToken');
    const payload = jwtDecode(token);
    const expDate = new Date(payload.exp * 1000);

    return expDate < new Date();
  } catch (error) {
    return false;
  }
}

export default {
  login,
  validateUser,
  verifyEmail,
  resendVerification,
  getToken,
  refreshTokens,
  updateUserEmail
};
