var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({class:[
    'button',
    { 'is-fullwidth': _vm.isFullwidth },
    { 'is-rounded': _vm.isRounded },
    { 'is-small': _vm.isSmall },
    { 'is-extra-small': _vm.isExtraSmall },
    { 'is-transparent': _vm.isTransparent },
    { 'is-default': _vm.isDefault },
    { 'is-dark': _vm.isDark },
    { 'is-warning': _vm.isWarning },
    { 'is-secondary': _vm.isSecondary },
    { 'is-grayed-out': _vm.isGrayedOut },
    { hover: _vm.hoverEffect },
    { capitalize: _vm.capitalize }
  ],attrs:{"disabled":_vm.isDisabled}},'button',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"inner-wrapper"},[(_vm.isLoading)?_c('div',{staticClass:"spinner"},[_c('BaseLoadingSpinner',{attrs:{"color":_vm.spinnerColor,"size":20}})],1):_vm._e(),_c('span',{staticClass:"slot-wrapper",class:{ hidden: _vm.isLoading }},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }