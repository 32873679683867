<template>
  <div>
    <div class="summary-menu">
      <TabMenu v-if="isOwnerOfAllApplicants">
        <TabMenuButton
          v-for="(applicant, index) in application.applicants"
          :key="applicant.index"
          :active="index === selectedApplicantIndex"
          @tabMenuButtonClick="handleTabClick(index)"
        >
          {{ getName(applicant) }}
        </TabMenuButton>
      </TabMenu>
    </div>
    <transition name="form" mode="out-in">
      <div
        v-if="!!activeValidationApplicant"
        :key="selectedApplicantIndex"
        class="data-summary-wrapper"
      >
        <div class="summary-splitter">
          <!-- :class="{ active: activeApplicant.apartments.length > 1 }" -->
          <ApartmentSummary
            v-for="(
              apartment, apartmentIndex
            ) in activeValidationApplicant.APARTMENTS"
            :key="`apartment-${apartmentIndex}`"
            :apartment="apartment"
            :title="
              activeValidationApplicant.APARTMENTS.length > 1
                ? `Lägenhet ${alphabet[selectedApplicantIndex]}${apartmentIndex + 1}`
                : 'Lägenhet'
            "
            :is-approved="categoryIsApproved('APARTMENTS', apartmentIndex)"
            :category-has-errors="
              categoryHasErrors('APARTMENTS', apartmentIndex)
            "
            :rejection-message="
              newPropertyRejections('APARTMENTS', apartmentIndex)
                ? getRejectionMessage('apartments', apartmentIndex)
                : ''
            "
            @goToCategory="goToCategory($event, apartmentIndex)"
          />
        </div>
        <div class="summary-splitter">
          <!-- :class="{ active: activeApplicant.members.length > 1 }" -->
          <MemberSummary
            v-for="(member, memberIndex) in activeValidationApplicant.MEMBERS"
            :key="`member-${memberIndex}`"
            :member="member"
            :category-has-errors="categoryHasErrors('MEMBERS', memberIndex)"
            :is-approved="categoryIsApproved('MEMBERS', memberIndex)"
            :rejection-message="
              newPropertyRejections('MEMBERS', memberIndex)
                ? getRejectionMessage('members', memberIndex)
                : ''
            "
            @goToCategory="goToCategory($event, memberIndex)"
          />
        </div>
        <SwapReasonSummary
          v-for="(
            swapReason, reasonIndex
          ) in activeValidationApplicant.SWAPREASON"
          :key="`reason-${reasonIndex}`"
          :swap-reason="swapReason"
          :category-has-errors="categoryHasErrors('SWAPREASON', reasonIndex)"
          :is-approved="categoryIsApproved('SWAPREASON', reasonIndex)"
          :rejection-message="
            newPropertyRejections('SWAPREASON')
              ? getRejectionMessage('swapReason')
              : ''
          "
          @goToCategory="goToCategory($event)"
        />
        <SwapDetailsSummary
          v-for="(
            swapDetail, detailIndex
          ) in activeValidationApplicant.SWAPDETAILS"
          :key="`detail-${detailIndex}`"
          :title="`Bytesdetaljer (${
            activeValidationApplicant.SWAPDETAILS[detailIndex].name ||
            `Bytespart ${detailIndex + 1}`
          })`"
          :swap-detail="swapDetail"
          :category-has-errors="categoryHasErrors('SWAPDETAILS', detailIndex)"
          :is-approved="categoryIsApproved('SWAPDETAILS', detailIndex)"
          :rejection-message="
            newPropertyRejections('SWAPDETAILS', detailIndex)
              ? getRejectionMessage('swapDetails', detailIndex)
              : ''
          "
          @goToCategory="goToCategory($event, detailIndex)"
        />
        <template
          v-for="category in [
            'CREDIT_INFORMATION',
            'FAMILY_CERTIFICATE',
            'INCOME_BASIS',
            'LEASE_AGREEMENT',
            'RENTAL_INVOICE',
            'OTHER'
          ]"
        >
          <DocumentCategorySummary
            v-for="(documents, index) in activeValidationApplicant[category]"
            :key="`${category}-${index}`"
            :name="activeValidationApplicant.MEMBERS[index].name.value"
            :applicant-id="activeApplicantId"
            :documents="documents"
            :category-id="category"
            :is-approved="categoryIsApproved(category, index)"
            :category-has-errors="categoryHasErrors(category, index)"
            :rejection-message="
              newPropertyRejections(category, index)
                ? getRejectionMessage(category, index)
                : ''
            "
            @goToCategory="goToCategory($event, index)"
          />
        </template>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import {
  alphabet,
  hasNewPropertyRejections,
  translateCategory
} from '@/utils/common';

import TabMenuButton from '@/components/TabMenuButton.vue';
import TabMenu from '@/components/TabMenu.vue';
import DocumentCategorySummary from './DocumentCategorySummary.vue';
import ApartmentSummary from './ApartmentSummary.vue';
import MemberSummary from './MemberSummary.vue';
import SwapReasonSummary from './SwapReasonSummary.vue';
import SwapDetailsSummary from './SwapDetailsSummary.vue';

export default {
  components: {
    TabMenu,
    TabMenuButton,
    DocumentCategorySummary,
    ApartmentSummary,
    MemberSummary,
    SwapReasonSummary,
    SwapDetailsSummary
  },
  props: {
    application: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alphabet
    };
  },
  computed: {
    ...mapState({
      selectedApplicantIndex: state => state.application.selectedApplicantIndex,
      selectedPossessionIndex: state =>
        state.application.selectedPossessionIndex
    }),
    ...mapGetters({
      allAppValidation: 'appValidation/all',
      applicantCategoryAllStatus: 'appValidation/applicantCategoryAll',
      menuFlat: 'application/menuFlat',
      isOwnerOfAllApplicants: 'application/isOwnerOfAllApplicants',
      applicant: 'application/applicant'
    }),
    activeValidationApplicant() {
      return this.allAppValidation[this.selectedApplicantIndex];
    },
    activeApplicantId() {
      return this.applicant.id;
    }
  },
  methods: {
    ...mapMutations({
      setSkipRouterIndexReset: 'application/setSkipRouterIndexReset',
      setSelectedApplicantIndex: 'application/setSelectedApplicantIndex',
      setSelectedPossessionIndex: 'application/setSelectedPossessionIndex'
    }),
    categoryIsApproved(category, possessionIndex) {
      switch (category) {
        case 'APARTMENTS':
        case 'MEMBERS':
        case 'SWAPDETAILS':
          return (
            this.applicant[translateCategory(category)][possessionIndex]?.review
              ?.approved || false
          );
        case 'SWAPREASON':
          return this.applicant.swapReason?.review?.approved || false;
        default:
          return (
            this.applicant.documentGroups[possessionIndex][category]
              ?.approved || false
          );
      }
    },
    categoryHasErrors(category, apartmentIndex) {
      const statuses = this.applicantCategoryAllStatus(
        this.selectedApplicantIndex,
        category
      );
      return ['EMPTY', 'INCOMPLETE', 'INVALID', 'COMMENT'].includes(
        statuses[apartmentIndex]
      );
    },
    getName(applicant) {
      return applicant.members
        .map(member => {
          return member.name.split(' ')[0] || 'Medsökande';
        })
        .join(' och ');
    },
    newPropertyRejections(category, possessionIndex = 0) {
      return hasNewPropertyRejections(
        this.application.applicants,
        this.selectedApplicantIndex,
        possessionIndex,
        category
      );
    },
    getRejectionMessage(property, possessionIndex = 0) {
      switch (property) {
        case 'apartments':
        case 'members':
        case 'swapDetails':
          return this.applicant[property][possessionIndex].review?.message;
        case 'swapReason':
          return this.applicant.swapReason.review?.message;
        default:
          return this.getDocumentCategoryMsg(property, possessionIndex);
      }
    },
    getDocumentCategoryMsg(category, possessionIndex) {
      const categoryComment =
        this.applicant.documentGroups[possessionIndex][category].comment;
      const documentComments = this.applicant.documentGroups[possessionIndex][
        category
      ].documents.filter(doc => doc.review?.message);

      if (categoryComment && !documentComments.length) {
        return categoryComment;
      } else if (documentComments.length === 1 && !categoryComment) {
        return documentComments[0].review.message;
      } else if (categoryComment || documentComments.length > 0) {
        return 'Det finns flera kommentarer för den här kategorin, klicka på "Ändra" för att se mer';
      }
      return '';
    },
    async goToCategory(category, possessionIndex = 0) {
      const menuLink = this.menuFlat.find(link => link.statusName === category);
      if (!menuLink) {
        return;
      }
      this.setSkipRouterIndexReset(true);
      this.setSelectedApplicantIndex(this.selectedApplicantIndex);
      this.setSelectedPossessionIndex(possessionIndex);
      this.$router.push(menuLink.url);
    },
    categoryDone(status) {
      if (status === 'COMPLETE' || status === 'APPROVED ') {
        return true;
      }
      return false;
    },
    handleTabClick(index) {
      this.setSelectedApplicantIndex(index);
    }
  }
};
</script>
<style scoped lang="scss">
.summary-menu {
  margin-bottom: 2rem;
}
.data-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  margin-bottom: 3.2rem;
  .more-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }
  }
}
.summary-splitter {
  display: contents;
  &.active {
    display: flex;
    gap: 1rem;
    .summary-wrapper {
      width: 50%;
    }
    :deep(.summary-field-wrapper) {
      justify-content: space-between;
      display: block;
      :deep(p) {
        width: 100%;
      }
    }
    @include mobile {
      display: contents;
      .summary-wrapper {
        width: 100%;
      }
    }
  }
}
</style>
