<template>
  <div class="org-search-container">
    <OvInput
      :value="value"
      :validation="validateOrganizationNumber"
      error-text="Organisationsnummer anges i detta format XXXXXX-YYYY"
      label="Organisationsnummer"
      placeholder="Organisationsnummer"
      description="Ange organisationsnummer"
      :is-loading="isSearchingOrgnr"
      :disabled="isSearchingOrgnr"
      v-on="$listeners"
    />
    <transition name="shuffle" appear>
      <p v-if="showLoadingInfo" class="info">
        <i
          >Det kan ta en stund hämta informationen, du kan fortsätta fylla i
          resten av formuläret</i
        >
      </p>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { validateOrganizationNumber } from '@/utils/input-validations';
import debounce from '@/utils/debounce';
import OvInput from '@/components/OvInput.vue';

export default {
  name: 'OvOrganizationSearch',
  components: {
    OvInput
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    }
  },
  data() {
    return {
      validateOrganizationNumber,
      isSearchingOrgnr: false,
      showLoadingInfo: false
    };
  },
  watch: {
    value(val = '') {
      const trimmedVal = val.trim();
      this.searchOrgnrDebounced(trimmedVal);
    }
  },
  created() {
    this.searchOrgnrDebounced = debounce(this.searchOrgnr, 300);

    if (this.validateOrganizationNumber(this.value)) {
      this.searchOrgnr(this.value);
    }
  },
  beforeDestroy() {
    this.searchOrgnrDebounced.cancel();
  },
  methods: {
    ...mapActions({
      fetchCompany: 'companies/fetchCompany'
    }),
    async searchOrgnr(orgnr) {
      if (!validateOrganizationNumber(orgnr)) {
        return;
      }
      const timeout = setTimeout(() => (this.showLoadingInfo = true), 2500);
      this.isSearchingOrgnr = true;
      try {
        await this.fetchCompany(orgnr);
      } finally {
        clearTimeout(timeout);
        this.showLoadingInfo = false;
        this.isSearchingOrgnr = false;
      }
    }
  }
};
</script>

<style scoped>
.org-search-container {
  position: relative;
}
.info {
  color: var(--color-dark-blue);
  font-weight: 400;
  line-height: 1.3;
  font-size: 1.4rem;
  transform: translateY(100%);
  position: absolute;
  bottom: -3px;
}
</style>
