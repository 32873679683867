import Vue from 'vue';
import Vuex from 'vuex';
import createAppModule from '../shared/app';
import createTooltipsModule from '../shared/tooltips';
import application from './application';
import appValidation from './appValidation';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    app: createAppModule(),
    tooltips: createTooltipsModule(),
    application,
    appValidation
  }
});
