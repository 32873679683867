<template>
  <div v-if="devEnvironment" class="env-info">{{ devText }}</div>
</template>
<script>
export default {
  computed: {
    devEnvironment() {
      if (process.env.NODE_ENV === 'production') {
        return false;
      }
      return true;
    },
    devText() {
      const application =
        this.$store.state.subletApplication?.application ||
        this.$store.state.ovApplication?.application;
      let applicationType = application?.applicationType || '';
      return applicationType ? `DEV: ${applicationType}` : 'DEV';
    }
  }
};
</script>

<style scoped lang="scss">
.env-info {
  left: 0;
  background: red;
  font-size: 20px;
  z-index: 9999999999;
  position: fixed;
  bottom: 0;
  text-align: center;
  color: #fff;
  padding: 7px 17px 0px 5px;
  font-weight: 600;
  text-decoration: none;
  border-top-right-radius: 25px;

  @include mobile {
    font-size: 12px;
    padding: 6px 12px 0px 3px;
  }
}
</style>
