<template>
  <FieldsWrapper class="wrapper">
    <button
      class="large-expand-btn"
      :disabled="isDisabled"
      type="button"
      @click="$emit('click')"
    >
      <span
        ><BaseIconText :icon-url="icon"><slot /></BaseIconText
      ></span></button
  ></FieldsWrapper>
</template>
<script>
import svgs from '@/utils/icon-constants';
import BaseIconText from './BaseIconText.vue';
import FieldsWrapper from '@/components/FieldsWrapper.vue';
export default {
  components: {
    BaseIconText,
    FieldsWrapper
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    iconUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svgs
    };
  },
  computed: {
    defaultIcon() {
      return this.isDisabled
        ? svgs.ICONS.ADD_CIRCLE_GREY
        : svgs.ICONS.ADD_CIRCLE_BLUE;
    },
    icon() {
      return this.iconUrl || this.defaultIcon;
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  padding: 0;
}
.large-expand-btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--color-blue);
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  :deep(img.icon) {
    margin-right: 5px;
  }
  &[disabled] {
    color: var(--color-disabled);
    cursor: not-allowed;
  }
}
</style>
